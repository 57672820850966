import React, { useContext, useEffect, useState } from "react";
import CaContainer from "../../../components/CaContainer";
import { useChat } from "../../../hooks/useChat";
import { UserContext } from "../../../context/UserContext";
import { Col, Image, ListGroup, Row } from "react-bootstrap";
import { USER_PLACEHOLDER } from "../../../utils/const";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import CaAvatar from "../../../components/CaAvatar";

export default function PageUserConversations() {
  const { getConversationList } = useChat();
  const [loading, setLoading] = useState(false);
  const [conversationList, setConversationList] = useState([]);
  const { t } = useTranslation();
  const history = useHistory();

  const { user } = useContext(UserContext);

  useEffect(() => {
    if (user) {
      setLoading(true);
      getConversationList(user._id)
        .then((res) => {
          console.log("response conversation", res);
          setConversationList(res.data);
        })
        .finally(() => setLoading(false));
    }
  }, [user]);

  const handleGoChat = (practitionerID) => {
    history.push("/chat/" + practitionerID);
  };
  return (
    <CaContainer loading={loading} title={t("general_your_conversations")}>
      <Row>
        <Col>
          <ListGroup>
            {conversationList.map((conv) => (
              <ListGroup.Item
                action
                className="d-flex align-items-center justify-content-between"
                onClick={() => handleGoChat(conv?.practitioner?._id)}
              >
                <div>
                  <CaAvatar image={conv?.practitioner?.image}></CaAvatar>

                  <b className="ms-3">
                    {conv?.practitioner?.name} {conv?.practitioner?.surname}
                  </b>
                </div>

                <i class="fa fa-chevron-right"></i>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Col>
      </Row>
    </CaContainer>
  );
}
