import { Avatar, IconButton } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import NotificationsIcon from "@material-ui/icons/Notifications";
import StarIcon from "@material-ui/icons/Star";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import {
  Badge,
  Col,
  Container,
  ListGroup,
  Navbar,
  OverlayTrigger,
  Popover,
  Row,
} from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useProSidebar } from "react-pro-sidebar";
import { CompanyContext } from "../context/CompanyContext";
import { UserContext } from "../context/UserContext";
import { useAuthentication } from "../hooks/useAuthentication";
import { useNotification } from "../hooks/useNotification";
import CaModalSubscription from "../pages/business/partner/components/CaModalSubscription";
import CaButton from "./CaButton";
import CreditsBadge from "./CreditsBadge";
import LanguageSelector from "./global-components/LanguageSelector";

export default function CaNavBar() {
  const [loadingReadAll, setLoadingReadAll] = useState(false);
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);

  const { company } = useContext(CompanyContext);
  const {
    user,
    setUser,
    setLoggedIn,
    setToken,
    setUserType,
    notifications,
    setNotifications,
  } = useContext(UserContext);
  const { toggleSidebar, collapseSidebar } = useProSidebar();

  const auth = useAuthentication(setUser, setLoggedIn, setToken, setUserType);
  const { getNotifications, readAllNotifications } = useNotification();

  useEffect(() => {
    if (user?.id) {
      getNotifications(user?.id)
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            setNotifications(response.data.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [user]);
  const handleReadAll = () => {
    setLoadingReadAll(true);
    readAllNotifications(user.id)
      .then((response) => {
        setLoadingReadAll(false);
        console.log(response);
        if (response.status === 200) {
          setNotifications([]);
        }
      })
      .catch((error) => {
        setLoadingReadAll(false);
        console.log(error);
      });
  };

  return (
    <Navbar expand="lg" variant="light" bg="light">
      <Container className="w-100 " fluid>
        <Row>
          {!isMobile && (
            <Col>
              <IconButton
                onClick={() => (isMobile ? toggleSidebar() : collapseSidebar())}
              >
                <MenuIcon></MenuIcon>
              </IconButton>
            </Col>
          )}

          <Col className="p-3">
            <img
              alt="logo"
              src={company?.configuration?.logo}
              style={{
                maxWidth: 100,
                maxHeight: 60,
                cursor: "pointer",
                objectFit: "contain",
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col className="center noMobile ">
            <CreditsBadge></CreditsBadge>
          </Col>
          {/* <Col className="center">

        </Col> */}
          <Col className="center px-0">
            {!user?.partner && user?.type === "business" && (
              <IconButton
                style={{ color: "orange" }}
                onClick={() => setShowModal(true)}
              >
                <StarIcon></StarIcon>
              </IconButton>
            )}
            <OverlayTrigger
              rootClose
              placement="bottom"
              trigger="click"
              overlay={
                <Popover id="popover-positioned-bottom" className="p-0">
                  <Popover.Body className="p-0">
                    <div
                      style={{
                        maxHeight: 350,
                        minWidth: 200,
                        overflowY: "auto",
                      }}
                    >
                      <ListGroup>
                        {notifications.reverse().map((not) => (
                          <ListGroup.Item>
                            <b>{not.title} </b>
                            <small>
                              {not?.timestamp &&
                                moment(not?.timestamp).format(
                                  "DD/MM/YYYY  HH:mm"
                                )}
                            </small>
                            <p>{not.body}</p>
                          </ListGroup.Item>
                        ))}
                      </ListGroup>
                    </div>
                    <CaButton
                      variant="link"
                      className="btn btn-link"
                      text={t("general_mark_all_as_read")}
                      onClick={() => handleReadAll()}
                      isLoading={loadingReadAll}
                    ></CaButton>
                  </Popover.Body>
                </Popover>
              }
            >
              <IconButton style={{ color: "primary" }}>
                <Badge
                  badgeContent={
                    notifications.length < 10 ? notifications.length : "9+"
                  }
                  color="error"
                >
                  <NotificationsIcon></NotificationsIcon>
                </Badge>
              </IconButton>
            </OverlayTrigger>
          </Col>
          <Col className="center">
            <OverlayTrigger
              trigger="click"
              rootClose
              placement="bottom"
              overlay={
                <Popover id="popover-positioned-bottom">
                  <Popover.Body className="p-0">
                    <ListGroup>
                      <ListGroup.Item className="noPc">
                        <CreditsBadge></CreditsBadge>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <b>{t("general_username")}</b>:{" "}
                        <small>
                          {user?.name} {user?.surname}
                        </small>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <b>{t("general_email")}</b>:{" "}
                        <small>{user?.email}</small>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <b>{t("general_language")}</b>{" "}
                        <LanguageSelector></LanguageSelector>
                      </ListGroup.Item>

                      <ListGroup.Item>
                        <CaButton
                          text={t("general_documentation")}
                          variant="secondary"
                          className="btn btn-secondary"
                          onClick={() =>
                            (window.location.href = "https://docs.caducea.it/")
                          }
                        ></CaButton>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <CaButton
                          text="Logout"
                          variant="primary"
                          onClick={() => {
                            auth.logout();
                          }}
                        ></CaButton>
                      </ListGroup.Item>
                    </ListGroup>
                  </Popover.Body>
                </Popover>
              }
            >
              <Avatar
                style={{ cursor: "pointer" }}
                src={user?.image}
                className="ms-2"
              ></Avatar>
            </OverlayTrigger>
          </Col>
        </Row>
      </Container>

      <CaModalSubscription
        setShowModal={setShowModal}
        showModal={showModal}
      ></CaModalSubscription>
    </Navbar>
  );
}
