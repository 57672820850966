import mixpanel from "mixpanel-browser";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";

import CaButton from "../CaButton";
import CaModalSubscription from "../../pages/business/partner/components/CaModalSubscription";
import { DoctorRequestContext } from "../../context/DoctorRequestContext";
import { useTranslation } from "react-i18next";

export default function ButtonRequestUser(props) {
  const history = useHistory();
  const { doctorRequest, setDoctorRequest } = useContext(DoctorRequestContext);
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation();

  const handleRequest = () => {
    mixpanel.track("Request Visit", {
      type: "visita normale",
    });

    let newState = Object.assign({}, doctorRequest);
    newState.requestType = "normal";
    setDoctorRequest(newState);
    history.push("/new-request");
  };

  return (
    <div>
      <CaButton
        onClick={handleRequest}
        className="btn btn-radius btn-red c-white"
        style={{ lineHeight: "none" }}
        text={t("general_new_request")}
        icon="fas fa-user-md me-2"
      ></CaButton>

      <CaModalSubscription
        showModal={showModal}
        setShowModal={setShowModal}
      ></CaModalSubscription>
    </div>
  );
}
