import { InputAdornment, TextField } from "@material-ui/core";
import BusinessIcon from "@material-ui/icons/Business";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { toast } from "react-toastify";
import CaPaymentForm from "../../../components/CaPaymentForm";
import { CompanyContext } from "../../../context/CompanyContext";
import { UserContext } from "../../../context/UserContext";
import { countries } from "../../../data/contries";
import { useProposal } from "../../../hooks/useProposal";
import { validCodiceFiscale } from "../../../utils/Utils";
import CaInput from "../../../components/CaInput";
import { Form } from "react-bootstrap";
import CaButton from "../../../components/CaButton";
import { useUser } from "../../../hooks/useUser";

export default function CheckOutFormPayment({ proposal, onPaymentSuccess }) {
  const { user } = useContext(UserContext);
  const { company } = useContext(CompanyContext);
  const [country, setCountry] = useState(null);
  const [loading, setLoading] = useState(null);
  const [selectedOption, setSelectedOption] = useState("online");
  const [codiceFiscale, setCodiceFiscale] = useState(null);
  const { t } = useTranslation();
  const { acceptProfessionistProposal } = useProposal();
  const { updateUserById } = useUser();

  function stripeTokenHandler(paymentMethod, token) {
    // loading a richiesta corrente
    setLoading(true);
    // se c' è il codice fiscale lo aggiorno
    if (codiceFiscale) {
      updateUserById(user?._id, { codiceFiscale: codiceFiscale })
        .then((res) => {
          console.log("updated codice fiscale", res);
        })
        .catch((e) => {
          console.log(e);
        });
    }
    acceptProfessionistProposal(proposal._id, token?.id, paymentMethod?.id)
      .then((res) => {
        console.log("acceptProfessionistProposal res:", res);
        if (res.status == 200) {
          onPaymentSuccess();
          toast.success(t("proposal_accepted_correctly"));
        }
      })
      .catch((e) => {
        toast.error("general_error_accept_proposal");
      })
      .finally(() => setLoading(false));
  }
  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <div className="col-md-6 col-sm-12 col-md-offset-3  ">
      <h5>{t("general_choose_payment")}</h5>
      <form className="mt-4  ">
        <div className="container-fluid p-0 m-0">
          {!user?.piva && !user?.codiceFiscale && (
            <div className="row">
              <div className="col-12">
                <Select
                  className="mb-4"
                  placeholder={t("general_select_country")}
                  options={countries}
                  onChange={(e) => setCountry(e.label)}
                />
              </div>
              {country === "Italy" && (
                <div className="col-12 mb-4">
                  <CaInput
                    className="single-input"
                    error={
                      codiceFiscale !== "" && !validCodiceFiscale(codiceFiscale)
                    }
                    variant="outlined"
                    value={codiceFiscale}
                    onChange={(e) => setCodiceFiscale(e)}
                    label={t("general_fiscal_code")}
                    type="text"
                  ></CaInput>
                </div>
              )}
            </div>
          )}
          <div className="col mb-3">
            <Form className="mb-4">
              <Form.Group controlId="formBasicRadio">
                <Form.Check
                  type="radio"
                  label={t("general_pay_online")}
                  name="formHorizontalRadios"
                  id="formHorizontalRadios1"
                  value={"online"}
                  checked={selectedOption === "online"}
                  onChange={handleRadioChange}
                />
                <Form.Check
                  type="radio"
                  label={t("general_pay_on_site")}
                  name="formHorizontalRadios"
                  value={"onsite"}
                  id="formHorizontalRadios2"
                  checked={selectedOption === "onsite"}
                  onChange={handleRadioChange}
                />
              </Form.Group>
            </Form>
            {selectedOption === "online" && (
              <>
                <CaPaymentForm
                  handleSubmit={stripeTokenHandler}
                  buttonText={t("general_book_now")}
                  loading={loading}
                ></CaPaymentForm>
                <small className="mt-4">
                  {t("general_pay_now_disclaimer")}
                </small>
              </>
            )}
            {selectedOption === "onsite" && (
              <CaButton
                onClick={(e) => {
                  e.preventDefault();
                  stripeTokenHandler();
                }}
                text={t("general_book_now")}
                isLoading={loading}
              ></CaButton>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}
