import React from "react";
import { Card, Form, InputGroup } from "react-bootstrap";
import { ChatFeed } from "react-chat-ui";
import { useTranslation } from "react-i18next";
import { useChatLogic } from "../hooks/useChatLogic";
import CaButton from "./CaButton";
import CaInput from "./CaInput";
import CaAvatar from "./CaAvatar";

export default function CaChatFeed({ userID1, userID2, role }) {
  const { t } = useTranslation();

  const {
    inputValue,
    setInputValue,
    messages,
    loadingMessage,
    handleSubmit,
    handleCreateConversation,
    patient,
    practitioner,
  } = useChatLogic(userID1, userID2, role);

  return (
    <Card>
      <Card.Header>
        {role == "professionist" && (
          <>
            <CaAvatar image={patient?.image} className={"me-2"}></CaAvatar>
            {patient?.name} {patient?.surname}
          </>
        )}
        {role == "person" && (
          <>
            <CaAvatar image={practitioner?.image} className={"me-2"}></CaAvatar>
            {practitioner?.name} {practitioner?.surname}
          </>
        )}
      </Card.Header>
      <ChatFeed
        messages={messages} // Array: list of message objects
        hasInputField={false} // Boolean: use our input, or use your own
        showSenderName // show the name of the user who sent the message
        bubblesCentered={false} //Boolean should the bubbles be centered in the feed?
        // JSON: Custom bubble styles
        bubbleStyles={{
          text: {
            fontSize: 14,
          },
          chatbubble: {
            borderRadius: 50,
            padding: 10,
          },
        }}
      />

      <Card.Body>
        <Form onSubmit={handleSubmit}>
          <InputGroup>
            <CaInput
              variant="outlined"
              /* label={t("general_write_message")} */
              value={inputValue}
              onChange={(value) => setInputValue(value)}
              onFocus={handleCreateConversation}
            ></CaInput>
            <CaButton
              type="submit"
              variant="primary"
              isLoading={loadingMessage}
              disabled={!inputValue}
              text={t("general_submit")}
            ></CaButton>
          </InputGroup>
        </Form>
      </Card.Body>
    </Card>
  );
}
