import { Avatar } from "@material-ui/core";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-number-input";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import CaButton from "../../../components/CaButton";
import CaContainer from "../../../components/CaContainer";
import CaInput from "../../../components/CaInput";
import CaSelect from "../../../components/CaSelect";
import CaGoogleAutocomplete from "../../../components/general/CaGoogleAutocomplete";
import ProfileDetail from "../../../components/profiloComponent/ProfileDetail";
import { CompanyContext } from "../../../context/CompanyContext";
import { UserContext } from "../../../context/UserContext";
import { useUser } from "../../../hooks/useUser";
import {
  formatDate,
  isValidUrl,
  validCodiceFiscale,
  validPartitaIva,
} from "../../../utils/Utils";
import moment from "moment";
import axios from "axios";

export default function PageEditUserProfile() {
  const [name, setname] = useState("");
  const [surname, setSurname] = useState("");
  const [professione, setprofessione] = useState("");

  const [paese, setpaese] = useState("");
  const [indirizzo, setindirizzo] = useState("");
  const [telefono, settelefono] = useState("");
  const [sito, setsito] = useState("");
  const [linkedin, setlinkedin] = useState("");
  const [image, setimage] = useState("");
  const [facebook, setfacebook] = useState("");
  const [twitter, settwitter] = useState("");
  const [instagram, setinstagram] = useState("");
  const [piva, setPiva] = useState("");
  const [codiceFiscale, setCodiceFiscale] = useState("");
  const [birthdate, setBirthdate] = useState("");
  const [selectedPlace, setSelectedPlace] = useState(false);
  const { user, apiCallUserID, loadingAuth } = useContext(UserContext);

  const { company } = useContext(CompanyContext);
  const { updateUserById } = useUser();
  const history = useHistory();
  const { t } = useTranslation();

  /*  useEffect(() => {
    if (user?.id) {
      apiCallUserID(user?.id);
    }
  }, [user?.id]); */

  useEffect(() => {
    if (typeof user !== "undefined") {
      //FIXME aggiungi tutti i dati iniziali sul utente
      console.log(moment(user.birthdate).format("yyyy-mm-dd"));
      console.log(formatDate(user.birthdate));

      console.log("user: ", user);
      setname(user?.name);
      setSurname(user?.surname);
      setprofessione(user.professione);

      setpaese(user?.user_address?.country);
      setindirizzo(user?.user_address?.city);
      settelefono(user.telefono);
      setsito(user.sito);
      setlinkedin(user.linkedin);
      setfacebook(user.facebook);
      settwitter(user.twitter);
      setinstagram(user.instagram);
      setCodiceFiscale(user.codiceFiscale);
      setPiva(user.piva);
      setimage(user.image);
      setBirthdate(formatDate(user.birthdate));
    }
  }, [user]);
  const handleSave = (e) => {
    e.preventDefault();

    if (codiceFiscale && !validCodiceFiscale(codiceFiscale))
      return toast.error(t("error_fiscal_code_not_valid"));
    if (piva && !validPartitaIva(piva))
      return toast.error(t("error_piva_not_valid"));
    if (sito && !isValidUrl(sito))
      return toast.error(t("error_website_not_valid"));

    let modifiedUser = {
      name: name,
      surname: surname,
      occupazione: professione,
      telefono: telefono,
      codiceFiscale: codiceFiscale,
      //statusID: "659563c1c28c7bbbf3ec3f5e",
      companyID: company?._id,
      piva: piva,

      website: sito,
      linkedin: linkedin,
      twitter: twitter,
      facebook: facebook,
      instagram: instagram,
      fcmToken: null,
      expoToken: null,
      birthdate: birthdate,

      //FIXME - riempire con i dati utente
      indirizzo: indirizzo,
      citta: selectedPlace?.city,
      cittaShort: selectedPlace?.cityShort,
      codicePostale: selectedPlace?.postal_code,
      paese: paese,
      paeseShort: selectedPlace?.countryShort,
      latitude: selectedPlace?.lat,
      longitude: selectedPlace?.lng,
    };

    updateUserById(user?._id, modifiedUser)
      .then((response) => {
        //  setUser({ ...response.data });
        toast.success(t("general_user_updated_successfully"));
      })
      .catch((e) => {
        console.log("errore updateUserById", e);
        toast.error(t("error_while_updating_user"));
      });
    //.finally(() => apiCallUserID(user?.id));
  };
  const fileInputRef = useRef();

  const handleClick = (event) => {
    event.preventDefault();
    fileInputRef.current.click();
  };

  const handleFileChange = async (event) => {
    event.preventDefault();
    const file = event.target.files[0];

    // Create a FormData object and append the file to it
    const formData = new FormData();
    formData.append("image", file);

    try {
      // Send the FormData object to the server using fetch
      const response = await axios.post(
        process.env.REACT_APP_BASE_URL + "/api/upload",
        formData
      );

      if (response.status !== 200) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      // Get the response data as JSON

      let modifiedUser = {
        image_url: response.data.fileUrl,
      };

      updateUserById(user?._id, modifiedUser)
        .then((response) => {
          // setUser({ ...response.data });
          toast.success(t("general_user_updated_successfully"));
        })
        .catch((e) => {
          console.log("errore updateUserById", e);
          toast.error(t("error_while_updating_user"));
        });

      // Do something with the response data, e.g. display the uploaded image
    } catch (error) {
      console.error("errore update user", error);
      toast.error(t("error_while_updating_user"));
    }
  };

  return (
    <CaContainer backButton={"/account"} loading={loadingAuth}>
      <Row className="mt-4">
        <Col>
          <Form>
            <Row>
              <Form.Group className="mb-3 col-sm-12 col-md-12">
                <Form.Label>{t("general_image")}</Form.Label>
                <Avatar className="mb-2" src={image}></Avatar>

                <CaButton
                  variant="primary"
                  onClick={handleClick}
                  text={t("general_upload_image")}
                ></CaButton>
                <input
                  type="file"
                  accept="image/*"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group className="mb-3 col-sm-12 col-md-4">
                <Form.Label>{t("general_name")}</Form.Label>
                <CaInput
                  value={name}
                  onChange={(val) => setname(val)}
                ></CaInput>
              </Form.Group>
              <Form.Group className="mb-3 col-sm-12 col-md-4">
                <Form.Label>{t("general_surname")}</Form.Label>
                <CaInput
                  value={surname}
                  onChange={(val) => setSurname(val)}
                ></CaInput>
              </Form.Group>
              <Form.Group className="mb-3 col-sm-12 col-md-4">
                <Form.Label>{t("general_birthdate")}</Form.Label>
                <CaInput
                  value={birthdate}
                  type="date"
                  onChange={(val) => {
                    console.log("Date", val);
                    setBirthdate(val);
                  }}
                ></CaInput>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group className="mb-3 col-sm-12 col-md-4">
                <Form.Label>{t("general_profession")}</Form.Label>
                <CaInput
                  value={professione}
                  onChange={(val) => setprofessione(val)}
                ></CaInput>
              </Form.Group>
              <Form.Group className="mb-3 col-sm-12 col-md-6">
                <Form.Label>{t("general_address")}</Form.Label>
                <CaGoogleAutocomplete
                  placeholder={indirizzo}
                  enabled
                  value={indirizzo}
                  onSelectedPlace={(address) => setSelectedPlace(address)}
                ></CaGoogleAutocomplete>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group className="mb-3 col-sm-12 col-md-6">
                <Form.Label>{t("general_fiscal_code")}</Form.Label>
                <CaInput
                  value={codiceFiscale}
                  onChange={(val) => setCodiceFiscale(val)}
                  error={!validCodiceFiscale(codiceFiscale)}
                ></CaInput>
              </Form.Group>
              <Form.Group className="mb-3 col-sm-12 col-md-6">
                <Form.Label>Partita IVA</Form.Label>
                <CaInput
                  value={piva}
                  onChange={(val) => setPiva(val)}
                  type={"number"}
                  error={!validPartitaIva(piva)}
                ></CaInput>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group className="mb-3 col-sm-12 col-md-6">
                <Form.Label>{t("general_nation")}</Form.Label>
                <CaSelect
                  options={countries}
                  selected={countries.filter(
                    (option) => option.label === paese
                  )}
                  onChange={(val) => {
                    if (val) {
                      setpaese(val?.label);
                    } else {
                      setpaese(null);
                    }
                  }}
                ></CaSelect>
              </Form.Group>

              <Form.Group className="mb-3 col-sm-12 col-md-6">
                <Form.Label>{t("general_phone")}</Form.Label>
                <PhoneInput
                  value={telefono}
                  onChange={(val) => settelefono(val)}
                  rules={{ required: true }}
                  style={{
                    width: "100%",
                    fontSize: "1rem",
                    fontWeight: 400,
                    lineHeight: 1.5,
                    padding: 10,
                    border: "1px solid lightgray",
                    borderRadius: 5,
                  }}
                ></PhoneInput>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group className="mb-3 col-sm-12 col-md-6">
                <Form.Label>{t("general_website")}</Form.Label>
                <CaInput
                  value={sito}
                  onChange={(val) => setsito(val)}
                  type="text"
                ></CaInput>
              </Form.Group>
              <Form.Group className="mb-3 col-sm-12 col-md-6">
                <Form.Label>{t("general_email")}</Form.Label>
                <CaInput value={user?.email} disabled type="text"></CaInput>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group className="mb-3 col-sm-12 col-md-6">
                <Form.Label>Linkedin </Form.Label>
                <CaInput
                  value={linkedin}
                  onChange={(val) => setlinkedin(val)}
                  type="text"
                ></CaInput>
              </Form.Group>
              <Form.Group className="mb-3 col-sm-12 col-md-6">
                <Form.Label>Twitter </Form.Label>
                <CaInput
                  value={twitter}
                  onChange={(val) => settwitter(val)}
                  type="text"
                ></CaInput>
              </Form.Group>
              <Form.Group className="mb-3 col-sm-12 col-md-6">
                <Form.Label>Facebook </Form.Label>
                <CaInput
                  value={facebook}
                  onChange={(val) => setfacebook(val)}
                  type="text"
                ></CaInput>
              </Form.Group>
              <Form.Group className="mb-3 col-sm-12 col-md-6">
                <Form.Label>Instagram </Form.Label>
                <CaInput
                  value={instagram}
                  onChange={(val) => setinstagram(val)}
                  type="text"
                ></CaInput>
              </Form.Group>
            </Row>
          </Form>
        </Col>
      </Row>
      <CaButton
        text={t("general_save")}
        onClick={handleSave}
        className="btn btn-primary mt-4"
      ></CaButton>

      <ProfileDetail></ProfileDetail>
    </CaContainer>
  );
}

const countries = [
  { value: "US", label: "United States" },
  { value: "CA", label: "Canada" },
  { value: "MX", label: "Mexico" },
  { value: "GB", label: "United Kingdom" },
  { value: "FR", label: "France" },
  { value: "DE", label: "Germany" },
  { value: "JP", label: "Japan" },
  { value: "KR", label: "South Korea" },
  { value: "IT", label: "Italy" },
];
