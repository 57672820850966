import React from "react";
import PropTypes from "prop-types";
import { Card, ListGroup } from "react-bootstrap";
import CaButton from "./CaButton";
import CaFeatureList from "./CaFeatureList";
import CaMapV2 from "./CaMapV2";
import CaRequestStatusBadge from "./general/CaRequestStatusBadge";

const CaCardDetails = ({ title, list, actions, map, status_id }) => {
  if (!list) return <></>;
  return (
    <Card style={{ position: "relative" }}>
      <Card.Header>
        <Card.Title>{title}</Card.Title>
        {status_id && (
          <CaRequestStatusBadge
            status_id={status_id}
            style={{ position: "absolute", top: 5, right: 5 }}
          ></CaRequestStatusBadge>
        )}
      </Card.Header>
      <Card.Body>
        <CaFeatureList list={list}></CaFeatureList>
      </Card.Body>

      {map && (
        <CaMapV2
          center={[map.latitude, map.longitude]}
          marker={[
            {
              latitude: map.latitude,
              longitude: map.longitude,
            },
          ]}
        />
      )}

      {actions &&
        actions.map((button) => (
          <Card.Footer>
            <CaButton {...button}></CaButton>
          </Card.Footer>
        ))}
    </Card>
  );
};

export default CaCardDetails;
