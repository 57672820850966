import React, { createContext, useState, useEffect } from "react";
import { useAuthentication } from "./../hooks/useAuthentication";

export const MessageContext = createContext();

export const MesssageProvider = (props) => {
  const [messaggi, setMessaggi] = useState([]);

  const [update, setUpdate] = useState([]);
  const [inCaricamento, setInCaricamento] = useState(true);
  const auth = useAuthentication();

  // prendo tutti i messaggi che hanno come doctorId il currentUser
  const apiCallMessage = () => {};

  return (
    <MessageContext.Provider value={[messaggi, setMessaggi, apiCallMessage]}>
      {props.children}
    </MessageContext.Provider>
  );
};
