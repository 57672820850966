import React, { useState } from "react";
import { Form } from "@quillforms/renderer-core";
import "@quillforms/renderer-core/build-style/style.css";
import { registerCoreBlocks } from "@quillforms/react-renderer-utils";
import { useTranslation } from "react-i18next";

registerCoreBlocks();
export default function QuillFormFreeRequest({ onSubmit }) {
  const { t } = useTranslation();
  return (
    <div style={{ width: "100%", height: "100vh" }}>
      <Form
        formId="1"
        formObj={{
          blocks: [
            {
              id: "1dsdf12e",
              name: "group",
              attributes: {
                label: t("insert_information_about_patient"),
              },
              innerBlocks: [
                {
                  id: "nomeCliente",
                  name: "short-text",
                  attributes: {
                    label: t("general_patient_name"),
                    required: true,
                    placeholder: null,
                  },
                },
                {
                  id: "cognomeCliente",
                  name: "short-text",
                  attributes: {
                    label: t("general_patient_surname"),
                    required: true,
                    placeholder: null,
                  },
                },
              ],
            },
            {
              id: "anniCliente",
              name: "number",
              attributes: {
                label: t("general_patient_age"),
                setMin: 18, // Default: false
                min: 0, // Default: 0
                setMax: 100, // Default: false
                max: 100,
              },
            },
            {
              id: "emailCliente",
              name: "email",
              attributes: {
                label: t("general_patient_email"),
                required: true,
                placeholder: null,
              },
            },

            /* {
              id: "cellulareCliente",
              name: "short-text",
              attributes: {
                label: "Numero di telefono",
                required: true,
                placeholder: "+39 ...",
              },
            }, */

            {
              name: "dropdown",
              id: "servizioCliente",
              attributes: {
                required: true,
                multiple: false,
                verticalAlign: false,
                label: t("general_select_service_type"),
                choices: [
                  {
                    label: t("general_massaggio_svedese_30_min"),
                    value: "massage",
                  },
                  {
                    label: t("general_fisioterapista_1h"),
                    value: "physioterapist",
                  },
                  {
                    label: t("general_personal_trainer_1h"),
                    value: "personal-trainer",
                  },
                  {
                    label: t("general_tennis_trainer_1h"),
                    value: "tennis",
                  },
                  {
                    label: t("general_yoga_instructor_1h"),
                    value: "yoga",
                  },
                ],
              },
            },
            {
              name: "dropdown",
              id: "linguaCliente",
              attributes: {
                required: true,
                multiple: false,
                verticalAlign: false,
                label: t("general_pick_language"),
                choices: [
                  {
                    label: t("general_italian"),
                    value: "italiano",
                  },
                  {
                    label: t("general_english"),
                    value: "inglese",
                  },
                  {
                    label: t("general_german"),
                    value: "tedesco",
                  },
                  {
                    label: t("general_spanish"),
                    value: "spagnolo",
                  },
                  {
                    label: t("general_french"),
                    value: "francese",
                  },
                  {
                    label: t("general_other"),
                    value: "altro",
                  },
                ],
              },
            },
            {
              id: "dataRichiesta",
              name: "date",
              attributes: {
                label: t("general_request_hour"),
                format: "MMDDYYYY" | "DDMMYYYY" | "YYYYMMDD",
                separator: "/",
              },
            },
            {
              id: "oraRichiesta",
              name: "dropdown",
              attributes: {
                label: t("general_select_request_date"),
                required: true,
                choices: [
                  {
                    value: "8:00",
                    label: "8:00",
                  },
                  {
                    value: "8:30",
                    label: "8:30",
                  },
                  {
                    value: "9:00",
                    label: "9:00",
                  },
                  {
                    value: "9:30",
                    label: "9:30",
                  },
                  {
                    value: "10:00",
                    label: "10:00",
                  },
                  {
                    value: "10:30",
                    label: "10:30",
                  },
                  {
                    value: "11:00",
                    label: "11:00",
                  },
                  {
                    value: "11:30",
                    label: "11:30",
                  },
                  {
                    value: "12:00",
                    label: "12:00",
                  },
                  {
                    value: "12:30",
                    label: "12:30",
                  },
                  {
                    value: "13:00",
                    label: "13:00",
                  },
                  {
                    value: "13:30",
                    label: "13:30",
                  },
                  {
                    value: "14:00",
                    label: "14:00",
                  },
                  {
                    value: "14:30",
                    label: "14:30",
                  },
                  {
                    value: "15:00",
                    label: "15:00",
                  },
                  {
                    value: "15:30",
                    label: "15:30",
                  },
                  {
                    value: "16:00",
                    label: "16:00",
                  },
                  {
                    value: "16:30",
                    label: "16:30",
                  },
                  {
                    value: "17:00",
                    label: "17:00",
                  },
                  {
                    value: "17:30",
                    label: "17:30",
                  },
                  {
                    value: "18:00",
                    label: "18:00",
                  },
                  {
                    value: "18:30",
                    label: "18:30",
                  },
                  {
                    value: "19:00",
                    label: "19:00",
                  },
                ], // Default:  [ { "value": "123e45z7o89b",	"label": "Choice 1" }]
                verticalAlign: true, // Default : false
                multiple: false, // Default : false,
                max: false, // Default: false,
                min: false, // Default: false
              },
            },
          ],
        }}
        onSubmit={(
          data,
          { completeForm, setIsSubmitting, goToBlock, setSubmissionErr }
        ) => {
          onSubmit(data?.answers);
          setTimeout(() => {
            setIsSubmitting(false);
            completeForm();
          }, 500);
        }}
      />
    </div>
  );
}
