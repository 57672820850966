import React from "react";
import { Row } from "react-bootstrap";
import TabellaCheckOut from "../../../../components/shared/TabellaCheckOut";
import CheckOutFormPayment from "../../../shared/checkout/CheckOutFormPayment";
import { useHistory } from "react-router-dom";

const CheckOutUser = ({ proposal }) => {
  const history = useHistory();
  console.log(proposal);

  const handlePaymnetSuccess = () => {
    history.push("/appointment");
  };
  return (
    <>
      <Row className="mt-4">
        <CheckOutFormPayment
          proposal={proposal}
          onPaymentSuccess={handlePaymnetSuccess}
        ></CheckOutFormPayment>
        <TabellaCheckOut proposal={proposal}></TabellaCheckOut>
      </Row>
    </>
  );
};
export default CheckOutUser;
