import axios from "axios";
import mixpanel from "mixpanel-browser";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { DoctorRequestContext } from "../../../context/DoctorRequestContext";
import { UserContext } from "../../../context/UserContext";
import CaButton from "../../../components/CaButton";
import { useTranslation } from "react-i18next";

export default function CheckOutFormPacchetto({ doctor, url, requestData }) {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const { user } = useContext(UserContext);
  const { doctorRequest, setDoctorRequest } = useContext(DoctorRequestContext);
  const [loading, setLoading] = useState(null);
  const { t } = useTranslation();
  const history = useHistory();

  const handleScalaRichiesta = (event) => {
    //TODO chiamata per scalare una richiesta-
    event.preventDefault();
    let newState = Object.assign({}, doctorRequest);
    newState.spinner = true;
    setDoctorRequest(newState);

    const paymentData = {
      customerId: user.stripeId,
      email: user.email,
      price: doctor.doctorPrice,
      doctorExpoToken: doctor.doctorExpoToken,
      userType: user.type,
      userSubscription: user.abbonamento,
      user: user,
      doctor: doctor,
      requestData: requestData,
    };
    setLoading(true);

    axios
      .post(baseURL + url, paymentData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        setLoading(false);
        // chiudi il loading
        let newState = Object.assign({}, doctorRequest);
        newState.spinner = false;
        setDoctorRequest(newState);

        console.log("response:", response);
        toast.success(t("general_request_confirmed"));
        mixpanel.track("Proposal Accepted by User");
        mixpanel.people.track_charge(0);
        // cambio lo stato della richiesta in accettata

        // riporto l' utente sulla dashboard
        history.push("/");
      })
      .catch((e) => {
        setLoading(false);
        let newState = Object.assign({}, doctorRequest);
        newState.spinner = false;
        setDoctorRequest(newState);
        toast.error(t("error_while_confirming_request"));
      });
  };
  return (
    <div className="col-md-6 col-sm-12 col-md-offset-3 ">
      <CaButton
        text={t("general_accept_proposal")}
        onClick={handleScalaRichiesta}
        isLoading={loading}
        loadingText={"Loading"}
      ></CaButton>
    </div>
  );
}
