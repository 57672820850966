import moment from "moment";
import React, { useState } from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import CaButton from "../CaButton";
import CaRequestStatusBadge from "../general/CaRequestStatusBadge";
import CaFeatureList from "../CaFeatureList";

export default function CardAppointment({ appointment }) {
  const history = useHistory();
  const { t } = useTranslation();
  // Data passata
  const dataPassata = moment(appointment?.created);

  const handleGoMenage = () => {
    history.push("/manage-appointment/" + appointment?._id);
  };

  console.log("appointment", appointment);
  return (
    <>
      <Card className="mt-3" bg={"success"}>
        <Card.Header style={{ position: "relative" }}>
          <Card.Title>{appointment?.service_id?.service_name}</Card.Title>
          <Card.Subtitle>
            {appointment?.service_id?.service_description}
          </Card.Subtitle>
          <CaRequestStatusBadge
            status_id={appointment?.status_id?.status_id}
            style={{ position: "absolute", top: 0, right: 10 }}
          ></CaRequestStatusBadge>
        </Card.Header>
        <Card.Body className="p-0">
          <CaFeatureList
            list={[
              {
                label: t("general_patient_name"),
                value:
                  appointment?.patient_name +
                  " " +
                  appointment?.patient_surname,
                color: "success",
                show: appointment?.patient_name,
              },
              {
                label: t("general_address"),
                value: appointment?.address,
                color: "success",
                show: appointment?.address,
              },
              {
                label: t("general_practitioner_name"),
                value:
                  appointment?.practitioner_id?.name +
                  " " +
                  appointment?.practitioner_id?.surname,
                color: "success",
                show: appointment?.practitioner_id?.name,
              },
              {
                label: t("general_request_type"),
                value: appointment?.request_type_id.request_type,
                color: "success",
                show: appointment?.request_type_id.request_type,
              },
              {
                label: t("general_appointment_date"),
                value: moment(
                  appointment?.accepted_proposal?.proposed_visit_date
                ).format("DD/MM/YYYY"),
                color: "success",
                show: appointment?.accepted_proposal?.proposed_visit_date,
              },
              {
                label: t("general_appointment_time"),
                value: appointment?.accepted_proposal?.proposed_visit_time,
                color: "success",
                show: appointment?.accepted_proposal?.proposed_visit_time,
              },
            ]}
          ></CaFeatureList>
        </Card.Body>

        <Card.Footer>
          <CaButton
            size={"sm"}
            text={t("general_manage_appointment")}
            onClick={handleGoMenage}
          ></CaButton>
        </Card.Footer>
      </Card>
    </>
  );
}
