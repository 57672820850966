import React from "react";
import CaContainer from "../../../components/CaContainer";
import Chat from "./Chat";
import { useParams } from "react-router-dom";
import { Col, Row } from "react-bootstrap";

export default function PageBusinessChat() {
  const { practitionerID } = useParams();
  return (
    <CaContainer backButton={"/conversations"}>
      <Row>
        <Col lg={6} md={12}>
          <Chat practitionerID={practitionerID}></Chat>
        </Col>
      </Row>
    </CaContainer>
  );
}
