import axios from "axios";
// una lista di tutti i commenti

export default function useServices() {
  const baseURL = process.env.REACT_APP_BASE_URL;

  const getCompanyServices = (data) => {
    let config = {
      method: "get",
      url: baseURL + "/api/companies_services",
    };
    return axios(config);
  };

  return {
    getCompanyServices,
  };
}
