import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import CaContainer from "../../../components/CaContainer";
import ButtonReferral from "../../../components/profiloComponent/ButtonReferral";
import CardRequest from "../../../components/profiloComponent/CardRequest";
import ButtonRequestUser from "../../../components/user/ButtonRequestUser";
import { RequestContext } from "../../../context/RequestContext";
import { UserContext } from "../../../context/UserContext";
import { useUser } from "../../../hooks/useUser";

import ObserveNotification from "../../../utils/ObserveNotification";
import CaMissingRequest from "../../../components/shared/CaMissingRequest";

export default function PageUserDashboard(props) {
  const { richieste, loadingRequest, apiCallRequestContext } =
    useContext(RequestContext);
  const { user, setUser } = useContext(UserContext);
  const [isTokenFound, setTokenFound] = useState(true);
  const { updateUserById } = useUser();
  const { t } = useTranslation();
  console.log("PageUserDashboard user", user);

  useEffect(() => {
    if (user) {
      ObserveNotification.subscribe(apiCallRequestContext);
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      apiCallRequestContext();
    }
  }, [user]);

  useEffect(() => {
    // se c' è un utente e l' utente non ha il token
    if (user && !user?.fcmToken) {
      askForPermissioToReceiveNotifications();
    }
  }, [user]);

  const askForPermissioToReceiveNotifications = async () => {
    if (Notification.permission === "granted") return;
    if (Notification.permission === "denied") return;
    // altrimenti l' utente non ha ancora risposto
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        if ("serviceWorker" in navigator) {
          navigator.serviceWorker
            .register("./firebase-messaging-sw.js", {
              scope: "./firebase-messaging-sw.js",
            })
            .then((registration) => {
              handleSaveFcmToken();
            })
            .catch((err) => console.log("Service Worker Error", err));
        } else {
          toast.error(t("push_notification_not_supported"));
        }
      }
    });
  };

  const handleSaveFcmToken = (token) => {
    // se c' è un codice fiscale lo salva
    let modifiedUser = { fcmToken: token };
    updateUserById(user?._id, modifiedUser)
      .then((response) => {
        setUser({ ...response.data });
        /* toast.success("Utente modificato con successo!"); */
      })
      .catch((e) => toast.error(t("error_while_updating_user")));
  };

  return (
    <CaContainer loading={loadingRequest}>
      <Row>
        <div className="col-12  d-flex">
          <ButtonRequestUser></ButtonRequestUser>
        </div>
      </Row>
      <Row>
        <Col sm="12">
          {/*  {isTokenFound && <p> Le notifiche sono abilitate 👍🏻 </p>} */}
          {!isTokenFound && <p> {t("general_notification_disabled")} ❗️ </p>}
          {!isTokenFound && (
            <button className="btn btn-primary" onClick={handleSaveFcmToken}>
              {t("general_enable_notification")}
            </button>
          )}
          <div className="mt-2">
            <ButtonReferral
              text={t("general_invite_business")}
              type="business"
            ></ButtonReferral>
          </div>
        </Col>
      </Row>
      <Row>
        {richieste.map((req) => (
          <Col sm={12} lg={4}>
            <CardRequest request={req}></CardRequest>
          </Col>
        ))}
        {richieste && richieste.length === 0 && (
          <CaMissingRequest></CaMissingRequest>
        )}
      </Row>
    </CaContainer>
  );
}
