import React from "react";
import CaBadge from "../CaBadge";
import { useTranslation } from "react-i18next";

export default function CaRequestStatusBadge({
  status_id,
  style,
  numberOfProposal,
}) {
  const { t } = useTranslation();
  return (
    <>
      {status_id === 1 && numberOfProposal > 0 && (
        <CaBadge style={style} variant="warning">
          {t("general_request_status_" + 2)}
        </CaBadge>
      )}

      {status_id === 1 && numberOfProposal === 0 && (
        <CaBadge style={style} variant="success">
          {t("general_request_status_" + 1)}
        </CaBadge>
      )}
      {status_id === 2 && (
        <CaBadge style={style} variant="warning">
          {t("general_request_status_" + 2)}
        </CaBadge>
      )}
      {status_id === 3 && (
        <CaBadge style={style} variant="info">
          {t("general_request_status_" + 3)}
        </CaBadge>
      )}
      {status_id === 4 && (
        <CaBadge style={style} variant="danger">
          {t("general_request_status_" + 4)}
        </CaBadge>
      )}
      {status_id === 5 && (
        <CaBadge style={style} variant="danger">
          {t("general_request_status_" + 5)}
        </CaBadge>
      )}
    </>
  );
}
