import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import useProfessions from "../../../hooks/useProfession";
import CaSelect from "../../CaSelect";

const ProfessionPicker = (props) => {
  const {
    id,
    attributes,
    setIsValid,
    setIsAnswered,
    setValidationErr,
    isActive,
    val,
    setVal,
    next,
  } = props;
  const { required } = attributes;
  const [professions, setProfessions] = useState([]);
  const { getProfessions, areWeActiveInSelectedZone } = useProfessions();
  const [loading, setLoading] = useState(null);
  useEffect(() => {
    if (required) {
      if (!val) {
        setIsValid(false);
        setIsAnswered(false);
      }
      if (val) {
        setIsValid(true);
        setIsAnswered(true);
      }
    }
    if (!required) {
      setIsValid(true);
      setIsAnswered(true);
    }
  }, [val]);
  useEffect(() => {
    setLoading(true);
    getProfessions()
      .then((response) => {
        setLoading(false);
        setProfessions(response.data);
      })
      .catch((e) => {
        setLoading(false);
      });
  }, []);

  return (
    <CaSelect
      options={professions}
      getOptionLabel={(o) => o.name}
      getOptionValue={(o) => o.id}
      onChange={setVal}
      containerWidth={"100%"}
      placeholder={"Select profession"}
      error={false}
      noOptionsMessage={"No options"}
      loadingMessage={"Loading..."}
      isLoading={loading}
      isMulti={false}
      isDisabled={false}
      isClearable={true}
      autoFocus={false}
      selected={val}
      filterOption={() => null}
      onInputChange={() => null}
      icon={null}
      formatOptionLabel={null}
    ></CaSelect>
  );
};
export default ProfessionPicker;
