import React, { useContext, useState } from "react";

import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import CaButton from "../../../components/CaButton";
import CaInput from "../../../components/CaInput";
import { useAuthentication } from "../../../hooks/useAuthentication";
import { useTranslation } from "react-i18next";
import { Container, Row, Col, Card, Form } from "react-bootstrap";
import { CompanyContext } from "../../../context/CompanyContext";

const PageResetPwdForm = (props) => {
  let publicUrl = process.env.PUBLIC_URL + "/";
  const [pwd, setPwd] = useState(null);
  const [newPwd, setNewPwd] = useState(null);
  const [loading, setLoading] = useState(false);
  const { resetPasswordForm } = useAuthentication();
  const [success, setSuccess] = useState(false);
  const { token } = useParams();
  const { t } = useTranslation();
  const { company } = useContext(CompanyContext);
  let history = useHistory();

  const handleResetPassword = () => {
    if (pwd.length < 6 || newPwd.length < 6) {
      return toast.error(t("password_must_be_6_characters"));
    }
    if (pwd !== newPwd) {
      return toast.error(t("password_must_be_same"));
    }
    setLoading(true);
    resetPasswordForm(token, pwd)
      .then((res) => {
        setLoading(false);
        if (res.status === 200) {
          setSuccess(true);
        }
        if (res.status !== 200) {
          toast.error(t("error_while_password_update"));
        }
        console.log(res);
      })
      .catch((err) => {
        toast.error(t("error_while_password_update"));

        setLoading(false);
        console.log(err);
      });
  };

  return (
    <Container style={{ height: "100vh" }} fluid>
      <Row className="center h-100">
        <Col md={6} lg={4}>
          <Card>
            <Row className="center">
              <Col md={6} sm={6} className="center">
                <Card.Img
                  src={company?.configuration?.logo}
                  style={{ maxWidth: 200 }}
                ></Card.Img>
              </Col>
            </Row>

            <Card.Body>
              {!success && (
                <Form>
                  <Form.Group>
                    <CaInput
                      label={t("general_new_password")}
                      type="password"
                      value={pwd}
                      onChange={(pwd) => setPwd(pwd)}
                    ></CaInput>
                  </Form.Group>
                  <Form.Group className="mt-2">
                    <CaInput
                      label={t("general_confirm_new_password")}
                      type="password"
                      value={newPwd}
                      onChange={(newPwd) => setNewPwd(newPwd)}
                      error={pwd !== newPwd}
                    ></CaInput>
                  </Form.Group>
                  <Form.Group className="mt-3">
                    <CaButton
                      text={t("general_reset_password")}
                      onClick={handleResetPassword}
                      isLoading={loading}
                      disabled={!pwd || !newPwd || pwd !== newPwd}
                    ></CaButton>
                  </Form.Group>
                </Form>
              )}
              {success && (
                <Container className="mt-3">
                  <Row>
                    <Col>{t("general_reset_password_success")} !</Col>
                    <CaButton
                      variant="link"
                      onClick={() => history.push("/login")}
                      text={t("general_back_login")}
                    ></CaButton>
                  </Row>
                </Container>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default PageResetPwdForm;
