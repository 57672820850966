import React, { useState } from "react";
import { useStripe } from "../../hooks/useStripe";
import { useTranslation } from "react-i18next";
import CaButton from "../CaButton";
import { toast } from "react-toastify";

export default function ButtonStripeAdmin({ stripe_id }) {
  const { t } = useTranslation();
  const { sendToStripePortal } = useStripe();
  const [loading, setLoading] = useState(false);

  const handlePortal = () => {
    setLoading(true);
    sendToStripePortal(stripe_id)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        window.location.assign(response.data.url);
      })
      .catch(function (error) {
        toast.error(t("error_while_creating_session"));
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className="col">
      <CaButton
        className="btn btn-primary"
        onClick={handlePortal}
        icon="fas fa-money-bill me-3"
        text={t("general_menage_payments")}
        disabled={!stripe_id}
        isLoading={loading}
      ></CaButton>
    </div>
  );
}
