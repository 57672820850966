import { TextField } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import CaLoader from "../../../../components/CaLoader";
import { DoctorRequestContext } from "../../../../context/DoctorRequestContext";
import useProfessions from "../../../../hooks/useProfession";
import { useTranslation } from "react-i18next";

export default function StepOOne(props) {
  const { doctorRequest, setDoctorRequest } = useContext(DoctorRequestContext);
  const { getProfessions } = useProfessions();
  const [loading, setLoading] = useState(false);
  const [professions, setProfessions] = useState([]);
  const { t } = useTranslation();
  useEffect(() => {
    let newState = Object.assign({}, doctorRequest);
    newState.timeStart = new Date();
    setDoctorRequest(newState);
  }, []);

  useEffect(() => {
    setLoading(true);
    getProfessions()
      .then((res) => {
        setLoading(false);
        if (res.status === 200) {
          setProfessions(res.data);
        }
        console.log("professioni", res);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(t("error_while_recovering_professionists"));
      });
  }, []);

  const messageChange = (e) => {
    let newState = Object.assign({}, doctorRequest);
    newState.message = e.target.value;
    setDoctorRequest(newState);
  };

  const docChange = (e) => {
    let newState = Object.assign({}, doctorRequest);
    newState.doctorType = e.target.value;
    setDoctorRequest(newState);
  };

  return (
    <div className="container mt-4 " style={{ marginBottom: 200 }}>
      <div className="row mt-4 ">
        <div className="col-sm-12 col-md-6">
          <h4 className="mb-4">{t("general_sintomi")}</h4>
          <small>{t("request_sintomi_message")}</small>
          <TextField
            type="text"
            error={
              doctorRequest.message == ""
                ? doctorRequest.error
                  ? true
                  : false
                : null
            }
            helperText={
              doctorRequest.message == ""
                ? doctorRequest.error
                  ? "Required"
                  : null
                : null
            }
            required
            multiline
            rows={4}
            value={doctorRequest.message}
            onChange={(e) => messageChange(e)}
            variant="outlined"
          />
        </div>
        <div className="col-sm-12 col-md-6 d-flex flex-column mt-3">
          <h4 className="mb-4">{t("request_select_professionist_title")}</h4>
          <small>{t("request_select_professionist_subtitle")}</small>

          <CaLoader loading={loading}></CaLoader>

          {!loading && (
            <Select
              labelId="demo-simple-select-filled-label"
              id="demo-simple-select-filled"
              variant="outlined"
              error={
                doctorRequest.doctorType == ""
                  ? doctorRequest.error
                    ? true
                    : false
                  : null
              }
              helperText={
                doctorRequest.doctorType == ""
                  ? doctorRequest.error
                    ? "Required"
                    : null
                  : null
              }
              required
              value={doctorRequest.doctorType}
              onChange={(e) => docChange(e)}
            >
              {professions.map((prof) => (
                <MenuItem value={prof.id}>{prof.name}</MenuItem>
              ))}
            </Select>
          )}
        </div>
      </div>
    </div>
  );
}
