import axios from "axios";
// una lista di tutti i commenti

export default function useProfessions() {
  const baseURL = process.env.REACT_APP_BASE_URL;

  const getProfessions = () => {
    return axios.get(baseURL + "/api/user/professions");
  };
  const areWeActiveInSelectedZone = () => {
    //TODO completare la corretta
    return axios.get(baseURL + "/api/user/professions");
  };

  /**
   * Crea una proposta a una richiesta di assisstenza
   * @param {*} proposalData
   * @returns
   */
  const createProposal = (proposalData) => {
    var config = {
      method: "post",
      url: baseURL + "/api/proposals/person/create",
      data: proposalData,
    };

    return axios(config);
  };

  return { getProfessions, areWeActiveInSelectedZone, createProposal };
}
