import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useProposal } from "../../hooks/useProposal";
import CaDoctorProposalCard from "../CaDoctorProposalCard";
import CaLoader from "../CaLoader";
import CaProposalCard from "../CaProposalCard";

export default function ModalProposal({ modalShow, setModalShow, requestID }) {
  const [proposte, setProposte] = useState([]);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();

  const { getProposalByRequestID } = useProposal();

  // ottenere le proposte date tutte le richieste

  useEffect(() => {
    if (modalShow && requestID) {
      setLoading(true);
      getProposalByRequestID(requestID)
        .then((res) => {
          console.log("Lista proposte:", res);

          setProposte(res.data?.response);
        })
        .catch((e) => {
          toast.error("general_error_unable_to_get_proposals");
        })
        .finally(() => setLoading(false));
    }
  }, [modalShow, requestID]);

  const handleAccept = (proposalID) => {
    history.push("/checkout/" + proposalID);

    setModalShow(false);
  };

  return (
    <>
      <Modal
        show={modalShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setModalShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t("general_modal_proposal_title")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modalBody">
          <CaLoader loading={loading}></CaLoader>

          <CaProposalCard
            proposals={proposte}
            onAcceptProposal={handleAccept}
            showButton
          ></CaProposalCard>
        </Modal.Body>
      </Modal>
    </>
  );
}
