import moment from "moment";
import React, { useContext } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../context/UserContext";
import CaButton from "../CaButton";
import CaFeatureList from "../CaFeatureList";
import CaMapV2 from "../CaMapV2";
import CaRequestStatusBadge from "../general/CaRequestStatusBadge";

const CaCardRequest = ({ request, actions, showMap }) => {
  const { user } = useContext(UserContext);
  const { t } = useTranslation();
  if (!request) return <>{t("error_request_not_valid")}</>;

  const handleOpenMaps = (latitude, longitude) => {
    const mapUrl = `https://www.google.com/maps?q=${latitude},${longitude}`;
    window.open(mapUrl, "_blank");
  };

  return (
    <div className="mt-4">
      <Card>
        <Card.Header>
          <CaRequestStatusBadge
            style={{ position: "absolute", top: 5, right: 5 }}
            status_id={request?.status_id?.status_id}
          ></CaRequestStatusBadge>
          <Card.Title>{t("general_request_detail")}</Card.Title>
        </Card.Header>
        <Card.Body>
          <Row>
            {showMap && (
              <Col md={4} className="text-center noMobile">
                <CaMapV2
                  center={[request?.latitude, request?.longitude]}
                  zoom={13}
                  marker={[
                    {
                      latitude: request?.latitude,
                      longitude: request?.longitude,
                    },
                  ]}
                  style={{ height: "100%" }}
                ></CaMapV2>
              </Col>
            )}

            <Col md={showMap && 8} sm={12}>
              <CaFeatureList
                list={[
                  {
                    label: t("general_requested_visit_date"),
                    icon: "fa fa-calendar",
                    value:
                      moment(request?.requested_visit_date).format(
                        "DD/MM/YYYY"
                      ) +
                      " at " +
                      request?.requested_visit_time,
                    show: true,
                  },
                  {
                    label: t("general_address"),
                    icon: "fa fa-map-pin",
                    value: request?.address,
                    action: () =>
                      handleOpenMaps(request?.latitude, request?.longitude),
                    show: true,
                  },

                  {
                    label: t("general_required_professionist"),
                    value: t(
                      "general_profession_" + request?.profession_id?.profession
                    ),
                    show: true,
                  },
                  {
                    label: t("general_service_required"),
                    value: request?.service_id?.service_name,
                    show: true,
                  },
                  {
                    label: t("general_patient"),
                    value:
                      request?.patient_name + " " + request?.patient_surname,
                    show: true,
                  },
                  {
                    label: t("general_message"),
                    value: request?.message,
                    show: true,
                  },
                ]}
              ></CaFeatureList>
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer>
          {actions &&
            actions.map((button) => <CaButton {...button}></CaButton>)}
        </Card.Footer>
      </Card>
    </div>
  );
};

export default CaCardRequest;
