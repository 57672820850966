import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";

import CaButton from "../../../components/CaButton";
import { useDoctor } from "../../../hooks/useDoctor";
import CaVisitCard from "../../../components/CaVisitCard";
import CaContainer from "../../../components/CaContainer";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Chat from "../chat/Chat";

export default function PageBusinessManageRequest() {
  const { getDoctorById } = useDoctor();
  let [doctor, setDoctor] = useState(null);
  const location = useLocation();
  const history = useHistory();
  let request = location.state.request;
  const { t } = useTranslation();

  //TODO ottieni le info sul dottore tramite il suo id
  useEffect(() => {
    if (request) {
      getDoctorById(request.acceptedDoctorId)
        .then((res) => {
          setDoctor(res.data);
          console.log("doctor: ", res.data);
        })
        .catch((err) => console.log("error doc", err));
    }
  }, [request]);

  return (
    <CaContainer>
      <Row>
        <Col>
          <CaButton
            text={t("general_back_to_home")}
            onClick={() => history.push("/")}
            icon="fa fa-arrow-left"
            variant="link"
            className="btn btn-link"
          ></CaButton>
        </Col>
      </Row>
      <Row>
        <Col>
          <Chat request={request}></Chat>
        </Col>
        <Col>{doctor && <CaVisitCard doctor={doctor}></CaVisitCard>}</Col>
      </Row>
    </CaContainer>
  );
}
