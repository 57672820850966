import { InputAdornment } from "@material-ui/core";
import BusinessIcon from "@material-ui/icons/Business";
import PersonIcon from "@material-ui/icons/Person";
import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import CaInput from "../../../../../components/CaInput";
import CaGoogleAutocomplete from "../../../../../components/general/CaGoogleAutocomplete";
import { BusinessRegContext } from "../../../../../context/BusinessRegContext";

export default function StepOneBusinessReg() {
  const { businessReg, setBusinessReg } = useContext(BusinessRegContext);
  const { t } = useTranslation();
  const [specificAlert, setSpecificAlert] = useState(false);
  const [selectedPlace, setSelectedPlace] = useState(false);

  useEffect(() => {
    if (selectedPlace) {
      handleSelectPlace(selectedPlace);
    }
  }, [selectedPlace]);

  const handleChange = (value, target) => {
    let newState = Object.assign({}, businessReg);
    newState[target] = value;
    setBusinessReg(newState);
  };

  const handleSelectPlace = (address) => {
    let {
      lat,
      lng,
      isSpecific,
      postal_code,
      country,
      countryShort,
      city,
      cityShort,
    } = address;

    if (!isSpecific) {
      setSpecificAlert(true);
    } else {
      setSpecificAlert(false);
    }

    let newState = Object.assign({}, businessReg);
    newState.selectedValue = "";
    newState.indirizzo = address.address;
    newState.codicePostale = postal_code;
    newState.citta = city;
    newState.cittaShort = cityShort;
    newState.paese = country;
    newState.paeseShort = countryShort;
    newState.latitude = lat;
    newState.longitude = lng;

    setBusinessReg(newState);
  };
  return (
    <Container>
      <Row>
        <Col>
          <CaInput
            className="single-input"
            error={
              businessReg.nomeSocieta == ""
                ? businessReg.error
                  ? true
                  : false
                : null
            }
            helperText={
              businessReg.nomeSocieta == ""
                ? businessReg.error
                  ? "Required"
                  : null
                : null
            }
            variant="outlined"
            value={businessReg.nomeSocieta}
            onChange={(e) => handleChange(e, "nomeSocieta")}
            label={t("general_company_name")}
            type="text"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <BusinessIcon />
                </InputAdornment>
              ),
            }}
            required
          ></CaInput>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col>
          <CaGoogleAutocomplete
            label={t("general_sede_legale")}
            placeholder={"Via Roma n° 4 Siena"}
            onSelectedPlace={(address) => setSelectedPlace(address)}
            error={specificAlert}
            enabled={true}
            value={businessReg.indirizzo}
          ></CaGoogleAutocomplete>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <CaInput
            className="single-input"
            error={
              businessReg.nomeLegaleRappresentante == ""
                ? businessReg.error
                  ? true
                  : false
                : null
            }
            helperText={
              businessReg.nomeLegaleRappresentante == ""
                ? businessReg.error
                  ? "Required"
                  : null
                : null
            }
            variant="outlined"
            value={businessReg.nomeLegaleRappresentante}
            onChange={(e) => handleChange(e, "nomeLegaleRappresentante")}
            label={t("general_nome_legale_rappresentante")}
            type="text"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PersonIcon />
                </InputAdornment>
              ),
            }}
            required
          ></CaInput>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <CaInput
            className="single-input"
            error={
              businessReg.cognomeLegaleRappresentante == ""
                ? businessReg.error
                  ? true
                  : false
                : null
            }
            helperText={
              businessReg.cognomeLegaleRappresentante == ""
                ? businessReg.error
                  ? "Required"
                  : null
                : null
            }
            variant="outlined"
            value={businessReg.cognomeLegaleRappresentante}
            onChange={(e) => handleChange(e, "cognomeLegaleRappresentante")}
            label={t("general_cognome_legale_rappresentante")}
            type="text"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PersonIcon />
                </InputAdornment>
              ),
            }}
            required
          ></CaInput>
        </Col>
      </Row>
    </Container>
  );
}
