import React, { useState, useEffect } from "react";
import Autocomplete from "react-google-autocomplete";
import PropTypes from "prop-types";

function CaGoogleAutocomplete({
  country,
  types,
  onSelectedPlace,
  enabled,
  loadingInput,
  value,
  onChange,
  placeholder,
  label,
  style,
  onBlur,
  onFocus,
  showClearButton,
  className,
}) {
  const [selected, setSelected] = useState(null);
  const [inputValue, setInputValue] = useState(value);

  //alert(process.env.REACT_GOOGLE_MAP_API_KEY);

  return (
    <div>
      {label && (
        <label for="exampleInputEmail1" className="mb-2">
          {label}
        </label>
      )}

      <Autocomplete
        apiKey={process.env.REACT_APP_API_KEY}
        placeholder={placeholder}
        defaultValue={selected || inputValue}
        onBlur={onBlur}
        onFocus={onFocus}
        options={{
          types: ["geocode"],
          componentRestrictions: { country: country.toLowerCase() },
          offset: 20,
        }}
        onPlaceSelected={(place) => {
          console.log("place", place);
          if (!place?.geometry) {
            onSelectedPlace(null);
            onChange(null);
            return;
          }
          var lat = place.geometry.location.lat();
          var lng = place.geometry.location.lng();
          var type = place.address_components[0]?.types[0];
          var address = place.formatted_address;
          var placeID = place.place_id;
          // set del valore
          onChange(address);
          setInputValue(address);

          // restituice una promise che al termine invia la nostra geometry

          let postal_code = null;
          for (var i = 0; i < place.address_components.length; i++) {
            var component = place.address_components[i];
            if (component.types[0] === "postal_code") {
              postal_code = component.long_name;
            }
          }

          const { long_name: country = "" } =
            place.address_components.find((c) => c.types.includes("country")) ||
            {};
          const { short_name: countryShort = "" } =
            place.address_components.find((c) => c.types.includes("country")) ||
            {};
          const { long_name: city = "" } =
            place.address_components.find((c) =>
              c.types.includes("administrative_area_level_2")
            ) || {};
          const { short_name: cityShort = "" } =
            place.address_components.find((c) =>
              c.types.includes("administrative_area_level_2")
            ) || {};

          // specific alert
          let isSpecific = false;
          if (place.geometry.location_type == "APPROXIMATE") {
            isSpecific = false;
          } else {
            isSpecific = true;
          }

          onSelectedPlace({
            lat,
            lng,
            type,
            address,
            placeID,
            place,
            geometry: place.geometry,
            country,
            countryShort,
            city,
            cityShort,
            postal_code,
            isSpecific,
          });
        }}
        onChange={(event) => {
          setInputValue(event.target.value);
          if (!event.target.value) {
            onSelectedPlace(null);
          }
          if (onChange) {
            onChange(event);
          }
        }}
        disabled={!enabled}
        className="form-control"
        style={style}
      />
      {inputValue && (
        <i
          className={"fa fa-x"}
          style={{
            color: "gray",
            cursor: "pointer",
            fontSize: 16,
            fontWeight: "bold",
            background: "white",
            marginLeft: 10,
            paddingRight: 10,
          }}
          onClick={() => {
            setInputValue("");
            setSelected(null);
            onSelectedPlace(null);
          }}
        ></i>
      )}
    </div>
  );
}

CaGoogleAutocomplete.propTypes = {
  country: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  types: PropTypes.array,
  onSelectedPlace: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyDown: PropTypes.func,
  onChange: PropTypes.func,
};

CaGoogleAutocomplete.defaultProps = {
  country: "it",
  label: "",
  placeholder: "Dove ?",
  types: [],
  onSelectedPlace: () => true,
  onBlur: () => true,
  onKeyDown: () => true,
  onChange: () => true,
};

export default CaGoogleAutocomplete;
