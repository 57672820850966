import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { ChatFeed, Message } from "react-chat-ui";
import { useTranslation } from "react-i18next";
import CaButton from "../../../components/CaButton";
import CaInput from "../../../components/CaInput";
import { UserContext } from "../../../context/UserContext";
import { useChat } from "../../../hooks/useChat";
import ObserveNotification from "../../../utils/ObserveNotification";
import { Card, Col, Form, InputGroup, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";

const Chat = ({ practitionerID }) => {
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const { createConversation, getConversation, sendMessage } = useChat();
  const [existingConversation, setExistingConversation] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState(false);
  const { user } = useContext(UserContext);
  const { t } = useTranslation();

  useEffect(() => {
    if (practitionerID && user) {
      apiCallChatMessages();
      ObserveNotification.subscribe(apiCallChatMessages);
    }
  }, [practitionerID, user]);

  const apiCallChatMessages = () => {
    getConversation(practitionerID, user?._id)
      .then((res) => {
        console.log("conversation", res);
        // se conversazione non trovata
        if (res?.status === 402) {
          setExistingConversation(false);
        } else {
          // conversazione esistente,
          setExistingConversation(res?.data?._id);
          //setta messaggi
          //se vuoti esci
          if (res?.data?.messages.length === 0) return;
          // se sono presenti...
          let arr = [];

          let myArr = assignSenderIds(res?.data?.messages);
          myArr.map((message) => {
            let newMessage = new Message({
              id: message.id,
              message: message.text,
              senderName: moment(message.timestamp).format("DD/MM/YYYY hh:mm"),
            });
            arr.push(newMessage);
          });

          setMessages(arr);
        }
      })
      .catch((e) => {
        // window.location.reload();
        console.log(e);
      });
  };

  function assignSenderIds(messages) {
    const senderIds = {};
    let counter = -1;
    const resultArr = [];

    messages.forEach((message) => {
      const { sender } = message;
      if (!(sender in senderIds)) {
        counter = counter + 1;
        senderIds[sender] = counter;
      }
      const newMessage = { ...message, id: senderIds[sender] };
      resultArr.push(newMessage);
    });

    return resultArr;
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoadingMessage(true);
    let newMessage = new Message({
      id: 0,
      message: inputValue,
    });
    setMessages([...messages, newMessage]);
    setInputValue("");

    if (inputValue !== "") {
      sendMessage(existingConversation, user?._id, inputValue, user?.type)
        .then((response) => {
          console.log("messaggio inviato", response);

          setLoadingMessage(false);
        })
        .catch((e) => {
          setLoadingMessage(false);
        });
    }
  };

  const handleCreateConversation = () => {
    if (existingConversation) return;

    console.log("focus input");

    createConversation(practitionerID, user?._id)
      .then((res) => {
        setExistingConversation(res?.data?._id);
        console.log("conversazione creata", res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Card>
      <ChatFeed
        messages={messages} // Array: list of message objects
        hasInputField={false} // Boolean: use our input, or use your own
        showSenderName // show the name of the user who sent the message
        bubblesCentered={false} //Boolean should the bubbles be centered in the feed?
        // JSON: Custom bubble styles
        bubbleStyles={{
          text: {
            fontSize: 14,
          },
          chatbubble: {
            borderRadius: 50,
            padding: 10,
          },
        }}
      />

      <Card.Body>
        <Form onSubmit={handleSubmit}>
          <InputGroup>
            <CaInput
              variant="outlined"
              /* label={t("general_write_message")} */
              value={inputValue}
              onChange={(value) => setInputValue(value)}
              onFocus={handleCreateConversation}
            ></CaInput>
            <CaButton
              type="submit"
              variant="primary"
              isLoading={loadingMessage}
              disabled={!inputValue}
              text={t("general_submit")}
            ></CaButton>
          </InputGroup>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default Chat;
