import React, { useContext } from "react";
import { Route, Switch } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import PageBusinessChat from "../pages/business/chat/PageBusinessChat";
import PageBusinessConversations from "../pages/business/chat/PageBusinessConversations";
import PageBusinessDashboard from "../pages/business/dashboard/PageBusinessDashboard";
import PageEditBusinessProfile from "../pages/business/editProfile/PageEditBusinessProfile";
import PageBusinessHistory from "../pages/business/history/PageBusinessHistory";
import PageBusinessManageRequest from "../pages/business/manageRequest/PageBusinessMenageRequest";
import PageBecamePartner from "../pages/business/partner/PageBecamePartner";
import PageBusinessPayment from "../pages/business/payment/PageBusinessPayment";
import PageBusinessProfile from "../pages/business/profile/PageBusinessProfile";
import PageRequestFree from "../pages/business/request/freeRequest/PageRequestFree";
import PageBusinessRequest from "../pages/business/request/normalRequest/PageBusinessRequest";

const BusinessRoutes = (props) => {
  // il problema è che in questpo compenente non si aggiorna il contesto al cambiare della lingua

  const { user } = useContext(UserContext);

  return (
    <Switch>
      <Route
        exact
        path="/"
        render={() => <PageBusinessDashboard {...user}></PageBusinessDashboard>}
      />
      <Route
        exact
        path="/account"
        render={() => <PageBusinessProfile></PageBusinessProfile>}
      />
      <Route
        exact
        path="/account-edit"
        render={() => <PageEditBusinessProfile></PageEditBusinessProfile>}
      />
      <Route
        exact
        path="/history"
        render={() => <PageBusinessHistory></PageBusinessHistory>}
      />
      <Route
        exact
        path="/partner"
        render={() => <PageBecamePartner></PageBecamePartner>}
      />
      <Route
        exact
        path="/new-request"
        render={() => <PageBusinessRequest></PageBusinessRequest>}
      />
      <Route
        exact
        path="/manage-request"
        render={() => <PageBusinessManageRequest></PageBusinessManageRequest>}
      />
      <Route
        exact
        path="/checkout"
        render={() => <PageBusinessPayment></PageBusinessPayment>}
      />
      <Route
        exact
        path="/request-free"
        render={() => {
          return <PageRequestFree />;
        }}
      />
      <Route
        exact
        path="/chat/:practitionerID"
        render={() => <PageBusinessChat></PageBusinessChat>}
      />
      <Route
        exact
        path="/conversations"
        render={() => <PageBusinessConversations></PageBusinessConversations>}
      />
    </Switch>
  );
};

export default BusinessRoutes;
