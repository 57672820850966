import React, { useContext } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import CaChatFeed from "../../../components/CaChatFeed";
import { UserContext } from "../../../context/UserContext";

const ChatUser = ({ practitionerID }) => {
  const { user } = useContext(UserContext);
  const { t } = useTranslation();

  return (
    <Row>
      <Col>
        <CaChatFeed
          userID1={user?._id}
          userID2={practitionerID}
          role="person"
        ></CaChatFeed>
      </Col>
    </Row>
  );
};

export default ChatUser;
