import axios from "axios";
// una lista di tutti i commenti

export default function useNationality() {
  const baseURL = process.env.REACT_APP_BASE_URL;

  const getCompanyNationalities = (data) => {
    let config = {
      method: "get",
      url: baseURL + "/api/nationatilies",
    };
    return axios(config);
  };

  return {
    getCompanyNationalities,
  };
}
