import React, { useEffect, useState } from "react";
import { useTheme, useMessages } from "@quillforms/renderer-core";
import "react-datepicker/dist/react-datepicker.css";
import CaInput from "../../CaInput";
import moment from "moment/moment";

const DatePickerCustom = (props) => {
  const {
    id,
    attributes,
    setIsValid,
    setIsAnswered,
    setValidationErr,
    isActive,
    val,
    setVal,
    next,
  } = props;

  const { required } = attributes;
  const messages = useMessages();
  const theme = useTheme();
  useEffect(() => {
    let defaultDate = moment().format("YYYY-MM-DD");
    setVal(defaultDate);
    setIsValid(true);
  }, []);

  useEffect(() => {
    if (required) {
      if (!val) {
        setIsValid(false);
        setIsAnswered(false);
      }
      if (val) {
        setIsValid(true);
        setIsAnswered(true);
      }
    }
    if (!required) {
      if (!val) {
        setIsValid(true);
        setIsAnswered(true);
      }
      if (val) {
        setIsValid(true);
        setIsAnswered(true);
      }
    }
  }, [val]);

  return (
    <CaInput
      type="date"
      selected={val}
      onChange={(date) => {
        if (date) {
          console.log(date);
          setVal(date);
        }
        if (!date) {
          setVal(null);
        }
      }}
      value={val}
      className="form-control"
      dateFormat="dd/MM/yyyy" // Format "GG/MM/YYYY"
      placeholder="GG/MM/YYYY"
      minDate={Date.now()}
    />
  );
};
export default DatePickerCustom;
