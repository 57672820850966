import React, { useContext } from "react";

import StepResetPwd from "./components/StepResetPwd";
import { CompanyContext } from "../../../context/CompanyContext";
import { Card, Col, Container, Image, Row } from "react-bootstrap";

const PageResetPwd = (props) => {
  const { company } = useContext(CompanyContext);
  return (
    <Container style={{ height: "100vh" }} fluid>
      <Row className="center h-100">
        <Col md={6} lg={4}>
          <Card>
            <Row className="center">
              <Col md={6} sm={6} className="center">
                <Card.Img
                  src={company?.configuration?.logo}
                  style={{ maxWidth: 200 }}
                ></Card.Img>
              </Col>
            </Row>
            <Card.Body>
              <StepResetPwd></StepResetPwd>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default PageResetPwd;
