import React, { useState } from "react";
import CaButton from "../../../../../components/CaButton";
import CaInput from "../../../../../components/CaInput";
import { useTranslation } from "react-i18next";

export default function StepSubmit({ onSubmit, loading }) {
  const [otp, setOtp] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <div className="col-sm-12 mb-3">
        <CaInput
          className="single-input"
          variant="outlined"
          onChange={(e) => setOtp(e)}
          label={t("general_otp")}
          type="number"
          required
        ></CaInput>
      </div>

      <div class="col-12 center">
        <CaButton
          variant="primary"
          isLoading={loading}
          text={t("general_submit")}
          disabled={!otp}
          onClick={(e) => {
            e.preventDefault();
            onSubmit({ otp });
          }}
        ></CaButton>
      </div>
    </>
  );
}
