import React, { useContext, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../context/UserContext";
import { useVisits } from "../../hooks/useVisits";
import CaButton from "../CaButton";
import PricePicker from "./PricePicker";
import CaInput from "../CaInput";
import { CompanyContext } from "../../context/CompanyContext";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { useStripe } from "../../hooks/useStripe";

const VisitFormPage = ({ request, proposal }) => {
  const [visitDescription, setVisitDescription] = useState("");
  const [visitPrice, setVisitPrice] = useState(
    request?.service_id?.service_price
  );
  const { user, userTypePersonID, userTypeBusinessID } =
    useContext(UserContext);
  const { company } = useContext(CompanyContext);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [visitDate, setVisitDate] = useState(null);
  const [visitTime, setVisitTime] = useState(null);
  const { createVisit } = useVisits();
  const { cancelPaymentIntent } = useStripe();
  const history = useHistory();

  const handleCreateVisit = (event) => {
    console.log("request", request);
    console.log("proposal", proposal);
    console.log("company", company);
    event.preventDefault();

    let payload = {
      visitAddress: request?.address,
      visitDate: visitDate,
      visitTime: visitTime,
      visitPrice: visitPrice,
      currencyCode: company?.configuration?.currency,
      descrizione: visitDescription,
      practitionerInvoice: "",
      requestID: request?._id,
      userID: user?._id,
      proposalID: proposal?._id,
      visitType: userTypePersonID,
    };

    setLoading(true);

    createVisit(payload)
      .then((res) => {
        console.log("res", res);
        history.push("/visits");
      })
      .catch((e) => {
        toast.error(t("error_during_visit_close"));
      })
      .finally(() => setLoading(false));
  };
  const handleCancelVisit = () => {
    if (!proposal?.payment_intent) return toast.error(t("general_error"));
    setLoading(true);
    cancelPaymentIntent({
      paymentIntentID: proposal?.payment_intent,
      proposal_id: proposal?._id,
    })
      .then((res) => {
        console.log("res", res);
        // riaggiorna
        window.location.reload();
      })
      .catch((e) => {
        toast.error(t("error_during_visit_close"));
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <h4>{t("visit_form_title")}</h4>
      <Form>
        <Form.Group className="mt-3">
          <Form.Label>{t("general_visit_date")}</Form.Label>
          <CaInput
            onChange={(date) => setVisitDate(date)}
            type="date"
            pattern="\d{2}/\d{2}/\d{4}"
            required
          ></CaInput>
        </Form.Group>
        <Form.Group className="mt-3">
          <Form.Label>{t("general_visit_time")}</Form.Label>
          <CaInput
            onChange={(date) => setVisitTime(date)}
            type="time"
          ></CaInput>
        </Form.Group>
        <Form.Group className="mt-3">
          <Form.Label>{t("general_visit_price")}</Form.Label>
          <PricePicker handleChange={(e) => setVisitPrice(e)}></PricePicker>
        </Form.Group>

        <Form.Group controlId="visitDescription" className="mt-3">
          <Form.Label>{t("general_visit_description")}</Form.Label>
          <Form.Control
            as="textarea"
            rows={2}
            value={visitDescription}
            onChange={(e) => setVisitDescription(e.target.value)}
          />
        </Form.Group>
      </Form>

      {/* <PDFDownloadLink document={<MedicalReportPDF />} fileName="somename.pdf">
        {({ blob, url, loading, error }) =>
          loading ? "Loading document..." : "Download now!"
        }
      </PDFDownloadLink> */}
      <CaButton
        onClick={handleCreateVisit}
        variant="primary"
        type="submit"
        className={"mt-4"}
        isLoading={loading}
        disabled={!visitDescription || !visitPrice || !visitDate || !visitTime}
        text={t("general_finish_visit")}
      ></CaButton>

      {proposal?.payment_intent && (
        <CaButton
          onClick={handleCancelVisit}
          variant="secondary"
          type="submit"
          className={"mt-4 ms-4"}
          isLoading={loading}
          text={t("general_rimborsa_cliente")}
        ></CaButton>
      )}
    </>
  );
};

export default VisitFormPage;
