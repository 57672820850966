import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import CaProposalCard from "../../../../components/CaProposalCard";
import CaCardRequest from "../../../../components/general/CaCardRequest";
import { useRequest } from "../../../../hooks/useRequest";

export default function ProposalPending({ richiesta, proposte }) {
  const { cancelRequest } = useRequest();
  const [loading, setLoading] = useState(null);
  const { t } = useTranslation();
  const history = useHistory();
  const handleAcceptProposal = (proposalID) => {
    history.push("/checkout/" + proposalID);
  };

  console.log("richiesta", richiesta);

  return (
    <Row>
      <Col sm={5} xs={12}>
        <CaCardRequest
          request={richiesta}
          actions={[
            {
              text: t("general_cancel_request"),
              icon: "fa fa-trash",
              disabled:
                (richiesta?.status_id?.status_id === 1 &&
                  richiesta?.proposal_num !== 0) ||
                richiesta?.status_id?.status_id === 2 ||
                richiesta?.status_id?.status_id === 4 ||
                richiesta?.status_id?.status_id === 5,
              onClick: () => {
                setLoading(true);
                cancelRequest(richiesta?._id)
                  .then((res) => {
                    if (res.status === 200) {
                      toast.success(t("general_richiesta_cancellata"));
                      window.location.reload();
                    }
                  })
                  .catch((e) =>
                    toast.error(t("general_error_richiesta_cancellata"))
                  )
                  .finally(() => setLoading(false));
              },
            },
          ]}
        ></CaCardRequest>
      </Col>
      <Col sm={7} xs={12} className="mt-3">
        <CaProposalCard
          proposals={proposte}
          showButton={true}
          title={t("general_lista_proposte")}
          listStyle={{
            maxHeight: 400,
            overflow: "auto",
          }}
          onAcceptProposal={handleAcceptProposal}
        ></CaProposalCard>
      </Col>
    </Row>
  );
}
