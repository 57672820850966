import axios from "axios";
import { mergeObjects } from "../utils/Utils";
import { toast } from "react-toastify";
// una lista di tutti i commenti

export function useUser() {
  const baseURL = process.env.REACT_APP_BASE_URL;
  // ottieni il singolo utente
  const getUserById = (id) => {
    if (!id) return toast.error("Id utente non presente");
    var config = {
      method: "get",
      url: baseURL + "/api/users/" + id + "/details",
    };
    return axios(config);
  };
  // passa l' utente e le modifiche che vuoi apportare come 2 oggetti
  const updateUserById = (userID, modifiedUser) => {
    var updateUserByIdConfig = {
      method: "put",
      url: baseURL + "/api/users/edit-user/" + userID,
      headers: {
        "Content-Type": "application/json",
      },
      data: modifiedUser,
    };

    return axios(updateUserByIdConfig);
  };
  // passa l' utente e le modifiche che vuoi apportare come 2 oggetti
  const getUserTypeList = () => {
    var config = {
      method: "get",
      url: baseURL + "/api/visit/type",
    };
    return axios(config);
  };

  const updatePassword = (userID, password) => {
    let data = JSON.stringify({
      password: password,
    });

    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: baseURL + "/api/user/" + userID + "/password",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    return axios.config(config);
  };

  return { getUserById, updateUserById, updatePassword, getUserTypeList };
}
