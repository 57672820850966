import React, { createContext, useState, useEffect } from "react";

export const DoctorRequestContext = createContext();

export const DoctorRequestProvider = (props) => {
  const [doctorRequest, setDoctorRequest] = useState({
    spinner: false,
    name: "",
    surname: "",
    age: "",
    nationality: "",
    requestType: "normal",
    testType: "",
    testNumber: "",
    email: "",
    phone: "",
    message: "",
    address: "",
    latitude: "",
    longitude: "",
    selectedValue: "",
    timeChecked: "",
    timeStart: "",
    dataPrenotazione: "",
    oraPrenotazione: "",
    selectedRadioColor: "gray",
    timeStamp: "",
    language: [],
    modalShow: false,
    doctorChatId: "",
    doctorChatName: "",
    doctorType: null,
    doctorPrice: "",
    showChat: false,
    chatMessages: [],
    hourOfVisit: "",
    dateOfVisit: "",
    key: "home",
    error: false,
    businessID: null,
  });
  return (
    <DoctorRequestContext.Provider value={{ doctorRequest, setDoctorRequest }}>
      {props.children}
    </DoctorRequestContext.Provider>
  );
};
