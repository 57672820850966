import { LinearProgress } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import Typography from "@material-ui/core/Typography";
import mixpanel from "mixpanel-browser";
import React, { useContext, useState } from "react";
import { parsePhoneNumber } from "react-phone-number-input";
import { toast } from "react-toastify";
import CaButton from "../../../../components/CaButton";
import { BusinessRegContext } from "../../../../context/BusinessRegContext";
import { useAuthentication } from "../../../../hooks/useAuthentication";
import StepOTPBusinessReg from "./steps/StepOTPBusienssReg";
import StepOneBusinessReg from "./steps/StepOneBusinessReg";
import StepThreeBusinessReg from "./steps/StepThreeBusinessReg";
import StepTwoBusinessReg from "./steps/StepTwoBusinessReg";
import { useHistory } from "react-router-dom";

function getStepContent(step) {
  switch (step) {
    case 0:
      return <StepOneBusinessReg></StepOneBusinessReg>;
    case 1:
      return <StepTwoBusinessReg></StepTwoBusinessReg>;
    case 2:
      return <StepOTPBusinessReg></StepOTPBusinessReg>;
    case 3:
      return <StepThreeBusinessReg></StepThreeBusinessReg>;

    default:
      return "Unknown step";
  }
}

export default function BusinessRegStepper(props) {
  const [progress, setProgress] = useState(0);
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [skipped, setSkipped] = useState(new Set());
  const history = useHistory();

  const { businessReg, setBusinessReg } = useContext(BusinessRegContext);
  function getSteps() {
    let step1 = "Step 1";
    let step2 = "Step 2";
    let step3 = "Step 3";
    let step4 = "Step 4";

    let array = [];

    array.push(step1, step2, step3, step4);
    return array;
  }
  const steps = getSteps();
  const myForm = React.useRef(null);

  const auth = useAuthentication();

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = (e) => {
    let newState = Object.assign({}, businessReg);
    newState.error = false;
    setBusinessReg(newState);
    e.preventDefault();
    let newSkipped = skipped;
    if (!myForm.current.checkValidity()) {
      let newState = Object.assign({}, businessReg);
      newState.error = true;
      setBusinessReg(newState);
      return;
    }
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setProgress((activeStep) => activeStep + 25);
    setSkipped(newSkipped);
    mixpanel.track("Business Reg. Process", {
      step: activeStep + 1,
    });
  };

  const handleBack = (e) => {
    e.preventDefault();
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setProgress((activeStep) => activeStep - 25);
    mixpanel.track("Business Reg. Process", {
      step: activeStep - 1,
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleSignUp = (e) => {
    e.preventDefault();

    const phoneNumber = parsePhoneNumber(businessReg.telefono);
    setLoading(true);

    auth
      .verifyOtp(
        phoneNumber.countryCallingCode,
        phoneNumber.nationalNumber,
        businessReg?.otp,
        businessReg?.email
      )
      .then((response) => {
        console.log("response", response);

        if (response.status === 200) {
          if (response.data.response.status === "approved") {
            //TODO Iscrivi
            handleSignUp2();
          } else {
            toast.error("Codice OTP non approvato");
            setLoading(false);
          }
        }
      })
      .catch((error) => {
        toast.error("Errore nella verifica del codice OTP");
        setLoading(false);
      });
  };
  const handleSignUp2 = () => {
    auth
      .signUpEmailBusiness(businessReg)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        setLoading(false);
        history.push("/");
      })
      .catch(function (error) {
        setLoading(false);
        var errorMessage = error.response.data.message;
        toast.error(errorMessage);
      });
  };

  return (
    <>
      <Stepper activeStep={activeStep} className="row">
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};

          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel
                /* StepIconComponent={<PersonIcon></PersonIcon>} */
                {...labelProps}
              >
                {label}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <form ref={myForm}>
        {activeStep === steps.length ? (
          <div>
            <Typography>All the steps are completed</Typography>
            <Button onClick={handleReset}>Reset</Button>
          </div>
        ) : (
          <div>
            <div className=" mt-4 " style={{ marginBottom: 40 }}>
              {getStepContent(activeStep)}
            </div>

            <LinearProgress
              variant="determinate"
              value={progress}
            ></LinearProgress>

            <div className="copyright-text">
              <div className="row  mt-3 mb-3">
                <div className="col-6 text-center  ">
                  <CaButton
                    className="btn btn-border btn--with-shadow c-primary"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    text="Back"
                  ></CaButton>
                  {isStepOptional(activeStep) && <div></div>}
                </div>
                <div className="col-6 text-center  ">
                  {activeStep === steps.length - 1 ? (
                    <CaButton
                      className="btn btn-border btn-red c-primary"
                      variant="contained"
                      color="primary"
                      disabled={
                        !(
                          businessReg.privacy &&
                          businessReg.termsCondition &&
                          businessReg.email &&
                          businessReg.password &&
                          businessReg.password === businessReg.passwordConfirm
                        )
                      }
                      onClick={
                        activeStep === steps.length - 1
                          ? (e) => handleSignUp(e)
                          : handleNext
                      }
                      isLoading={loading}
                      text={"Iscriviti"}
                    ></CaButton>
                  ) : (
                    <CaButton
                      className="btn btn-border btn-green btn--with-shadow c-primary"
                      variant="contained"
                      color="primary"
                      onClick={
                        activeStep === steps.length - 1
                          ? (e) => handleSignUp(e)
                          : handleNext
                      }
                      text={"Next"}
                    ></CaButton>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </form>
    </>
  );
}
