import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";

import errorImg from "../../../img/svg/error.svg";
import CheckOutBusiness from "./checkout/CheckOutBusiness";
import CaContainer from "../../../components/CaContainer";
import CaButton from "../../../components/CaButton";
import { useTranslation } from "react-i18next";

export default function PageBusinessPayment(props) {
  const stripeKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
  const stripePromise = loadStripe(stripeKey);
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();

  if (!location?.state?.doctor || !location?.state?.request) {
    return (
      <CaContainer backButton={"/"}>
        <Row>
          <Col sm={12} md={6}>
            <h6 className="mt-4">{t("general_specify_proposal")}</h6>
          </Col>
          <Col sm={12} md={6}>
            <img
              src={errorImg}
              className="img-fluid"
              style={{ maxHeight: 300 }}
              alt="error"
            ></img>
          </Col>
        </Row>
      </CaContainer>
    );
  }

  return (
    <CaContainer>
      <Container fluid>
        <Row>
          <Col className="mt-4">
            <Elements stripe={stripePromise}>
              <CheckOutBusiness
                doctor={location?.state?.doctor}
                requestData={location?.state?.request}
              ></CheckOutBusiness>
            </Elements>
          </Col>
        </Row>
      </Container>
    </CaContainer>
  );
}
