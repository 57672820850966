import React, { useEffect, useState } from "react";
import usePrice from "../../hooks/usePrice";
import CaSelect from "../CaSelect";

export default function PricePicker({
  businessID,
  handleChange,
  defaultSelected,
  disabled,
}) {
  const [priceCatalog, setPriceCatalog] = useState(defaultPriceCatalog);
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(defaultSelected);
  const { getPriceByBusinessID } = usePrice();

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Se la richiesta ha un business ID, cerco se la struttura ha dei prezzi settati nel pannello admin
        if (businessID) {
          setLoading(true);
          const response = await getPriceByBusinessID(businessID);

          setLoading(false);

          if (response.status === 200 && response?.data?.prices.length > 0) {
            const arr = response.data.prices.map((obj) => ({
              label: obj.name,
              value: obj.price,
            }));
            setPriceCatalog(arr);
          }
        }
      } catch (error) {
        // Gestione degli errori
        setLoading(false);
      }
    };

    fetchData(); // Chiamata alla funzione fetchData
  }, [businessID]); // Aggiunta businessID come dipendenza

  return (
    <CaSelect
      options={priceCatalog}
      selected={selectedOption}
      onChange={(v) => {
        if (handleChange) {
          handleChange(v?.value);
        }
        setSelectedOption(v);
      }}
      isLoading={loading}
      isDisabled={disabled}
    ></CaSelect>
  );
}

let defaultPriceCatalog = [
  { label: "1 euro", value: "1" },
  { label: "10 euro", value: "10" },
  { label: "20 euro", value: "20" },
  { label: "30 euro", value: "30" },
  { label: "40 euro", value: "40" },
  { label: "50 euro", value: "50" },
  { label: "60 euro", value: "60" },
  { label: "70 euro", value: "70" },
  { label: "80 euro", value: "80" },
  { label: "90 euro", value: "90" },
  { label: "120 euro", value: "120" },
  { label: "100 euro", value: "100" },
  { label: "100 euro", value: "100" },
  { label: "110 euro", value: "110" },
  { label: "110 euro", value: "110" },
  { label: "120 euro", value: "120" },
  { label: "120 euro", value: "120" },
  { label: "130 euro", value: "130" },
  { label: "140 euro", value: "140" },
  { label: "150 euro", value: "150" },
  { label: "160 euro", value: "160" },
  { label: "170 euro", value: "170" },
  { label: "180 euro", value: "180" },
  { label: "190 euro", value: "190" },
  { label: "200 euro", value: "200" },
  { label: "210 euro", value: "210" },
  { label: "220 euro", value: "220" },
  { label: "230 euro", value: "230" },
  { label: "240 euro", value: "240" },
  { label: "250 euro", value: "250" },
  { label: "260 euro", value: "260" },
  { label: "270 euro", value: "270" },
  { label: "280 euro", value: "280" },
  { label: "290 euro", value: "290" },
  { label: "300 euro", value: "300" },
  { label: "310 euro", value: "310" },
  { label: "320 euro", value: "320" },
  { label: "330 euro", value: "330" },
  { label: "340 euro", value: "340" },
  { label: "350 euro", value: "350" },
  { label: "360 euro", value: "360" },
  { label: "350 euro", value: "350" },
  { label: "370 euro", value: "370" },
  { label: "380 euro", value: "380" },
  { label: "390 euro", value: "390" },
  { label: "400 euro", value: "400" },
  { label: "410 euro", value: "410" },
  { label: "420 euro", value: "420" },
  { label: "430 euro", value: "430" },
  { label: "440 euro", value: "440" },
  { label: "450 euro", value: "450" },
  { label: "460 euro", value: "460" },
  { label: "470 euro", value: "470" },
  { label: "480 euro", value: "480" },
  { label: "490 euro", value: "490" },
  { label: "500 euro", value: "500" },
  { label: "510 euro", value: "510" },
  { label: "520 euro", value: "520" },
  { label: "530 euro", value: "530" },
  { label: "540 euro", value: "540" },
  { label: "550 euro", value: "550" },
  { label: "560 euro", value: "560" },
  { label: "570 euro", value: "570" },
  { label: "580 euro", value: "580" },
  { label: "590 euro", value: "590" },
  { label: "600 euro", value: "600" },
];
