import React, { useEffect, useState } from "react";
import { useTheme, useMessages } from "@quillforms/renderer-core";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const TimePicker = (props) => {
  const {
    id,
    attributes,
    setIsValid,
    setIsAnswered,
    setValidationErr,
    isActive,
    val,
    setVal,
    next,
  } = props;

  const { required } = attributes;
  const messages = useMessages();
  const theme = useTheme();
  useEffect(() => {
    setVal(new Date());
    setIsValid(true);
  }, []);

  useEffect(() => {
    if (required) {
      if (!val) {
        setIsValid(false);
        setIsAnswered(false);
        setValidationErr(messages["label.errorAlert.required"]);
      }
      if (val) {
        setIsValid(true);
        setIsAnswered(true);
        setValidationErr(null);
      }
    }
    if (!required) {
      if (!val) {
        setIsValid(true);
        setIsAnswered(true);
      }
      if (val) {
        setIsValid(true);
        setIsAnswered(true);
      }
    }
  }, [val]);

  return (
    <DatePicker
      id={id}
      selected={val}
      onChange={(date) => {
        if (date) {
          setVal(date);
        }
        if (!date) {
          setVal(null);
        }
      }}
      className="form-control"
      showTimeSelect
      showTimeSelectOnly
      timeIntervals={15} // Set the time intervals, e.g., 15 minutes
      dateFormat="h:mm aa" // Format the time as you like
      timeCaption="Time" // Caption for the time picker
      minDate={Date.now()}
    />
  );
};
export default TimePicker;
