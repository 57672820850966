import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Select, { createFilter, components } from "react-select";

import CaError from "./CaError";

const CaSelect = ({
  error,
  placeholder,
  noOptionsMessage,
  loadingMessage,
  isLoading,
  isMulti,
  isDisabled,
  isClearable,
  autoFocus,
  customStyle,
  selected,
  onChange,
  filterOption,
  onInputChange,
  containerWidth,
  onFocus,
  icon,
  formatOptionLabel,
  ...props
}) => {
  const style = {
    control: (provided, state) => {
      return {
        ...provided,
        "&:hover": { borderColor: "var(--bs-gray-400)" },
        boxShadow: error
          ? "0 0 0 0.2rem rgb(220 53 69 / 25%)"
          : state.isFocused
          ? "var(--base-box-shadow)"
          : "none",
        borderColor: error
          ? "red"
          : // : state.isFocused
            // ? "var(--primary)"
            "var(--bs-gray-400)",
        minHeight: "35px",
        ...customStyle.control,
      };
    },
    container: (provided, state) => {
      return {
        ...provided,
        ...customStyle.container,
        width: containerWidth,
        borderColor: error
          ? "red"
          : state.isFocused
          ? "var(--primary)"
          : "var(--border-color)",
      };
    },
    dropdownIndicator: (provided, state) => {
      return {
        ...provided,
        padding: 6,
      };
    },
    clearIndicator: (provided, state) => {
      return {
        ...provided,
        padding: 6,
      };
    },
    menuList: (provided, state) => {
      return {
        ...provided,
        ...customStyle.menuList,
      };
    },
    menu: (provided, state) => {
      return {
        ...provided,
        ...customStyle.menu,
      };
    },

    option: (provided, state) => {
      return {
        ...provided,
        padding: state.data.child ? "8px 24px" : provided.padding,
        ...customStyle.option,
      };
    },
    singleValue: (provided, state) => {
      return {
        ...provided,
        opacity: state.isDisabled ? 0.5 : 1,
        transition: "opacity 300ms",
      };
    },
  };

  const handleChange = (e) => {
    onChange(e);
  };

  const handleInputChange = (e) => {
    if (onInputChange) {
      onInputChange(e);
    }
  };

  return (
    <>
      <Select
        {...props}
        isLoading={isLoading}
        placeholder={isLoading ? "" : placeholder || "Seleziona"}
        noOptionsMessage={() => noOptionsMessage || "Nessuna Opzione"}
        loadingMessage={() => loadingMessage || "Loading..."}
        styles={style}
        value={selected}
        onChange={handleChange}
        onFocus={onFocus}
        onInputChange={handleInputChange}
        isMulti={isMulti}
        isClearable={isClearable}
        isDisabled={isDisabled}
        icon={icon && icon}
        autoFocus={autoFocus}
        formatOptionLabel={formatOptionLabel}
        //filterOption={filterOption || createFilter({ matchFrom: "start" })}
      />
      {error && <CaError>{error}</CaError>}
    </>
  );
};

CaSelect.propTypes = {
  placeholder: PropTypes.string,
  noOptionsMessage: PropTypes.string,
  loadingMessage: PropTypes.string,
  isMulti: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isClearable: PropTypes.bool,
  isLoading: PropTypes.bool,
  autoFocus: PropTypes.bool,
  customStyle: PropTypes.object,
  filterOption: PropTypes.func,
};

CaSelect.defaultProps = {
  placeholder: "",
  noOptionsMessage: "",
  loadingMessage: "",
  isMulti: false,
  isClearable: true,
  isDisabled: false,
  isLoading: false,
  autoFocus: false,
  customStyle: {},
  filterOption: null,
};

export default CaSelect;
