import React from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";

export default function TabellaCheckOut({ proposal }) {
  const { t } = useTranslation();
  return (
    <div className="col-md-6 col-sm-12">
      <p>
        {t("checkout_table_title")}
        <b> {moment(proposal.proposed_visit_date).format("DD/MM/YYYY")} </b>
        {t("checkout_table_subtitle")} <b> {proposal.proposed_visit_time}</b>
      </p>
      <p></p>
      <table className="table table-borderless">
        <thead>
          <tr>
            <th>{t("checkout_table_causale")}</th>
            <th>{t("checkout_table_amount")}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{t("general_prezzo_proposto")}</td>
            <td>{proposal.proposed_price}€</td>
          </tr>
          {proposal?.request_id?.prezzo_preventivo && (
            <tr>
              <td> {t("general_preventivo")} </td>
              <td>{proposal?.request_id?.prezzo_preventivo}€</td>
            </tr>
          )}

          <tr>
            <td style={{ fontWeight: "bold" }}>{t("general_total")}</td>
            <td style={{ fontWeight: "bold" }}>
              {parseInt(proposal.proposed_price) +
                proposal?.request_id?.prezzo_preventivo}
              €
            </td>
          </tr>
        </tbody>
      </table>

      <hr></hr>
      <small>{t("checkout_disclaimer")}</small>
      <br></br>
    </div>
  );
}
