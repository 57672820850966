import React, { useContext, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { UserContext } from "../../context/UserContext";
import CaButton from "../CaButton";
import CaInput from "../CaInput";
import PricePicker from "./PricePicker";
import { useTranslation } from "react-i18next";
import useProfessions from "../../hooks/useProfession";
import moment from "moment";

const AssistanceProposalModal = ({
  showModal,
  handleClose,
  request,
  onProposalSubmitted,
}) => {
  const [formData, setFormData] = useState({
    dateOfVisit: "",
    hourOfVisit: "",
    message: "",
    price: request?.service_id?.service_price,
  });
  const { createProposal } = useProfessions();
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const { user } = useContext(UserContext);
  const { t } = useTranslation();

  const handleChange = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    if (form.checkValidity() === false) {
      e.stopPropagation();
      return;
    }
    if (
      !formData.dateOfVisit ||
      !formData.hourOfVisit ||
      !formData.message ||
      !formData.price
    ) {
      // Handle validation error (e.g., display an alert)
      toast.error(t("general_fill_all_fields"));
      return;
    }

    setValidated(true);
    // Validate form data

    let payload = {
      doctorMessage: formData.message,
      doctorExpoToken: user?.expo_token,
      doctorPrice: formData.price.toString(),
      currencyCode: "EUR",
      proposedVisitDate: formData.dateOfVisit, // "2024-01-31",
      proposedVisitTime: formData.hourOfVisit,
      fcmToken: user?.fcmToken,
      requestID: request?._id,
      userID: user?._id,
    };

    setLoading(true);

    createProposal(payload)
      .then((res) => {
        console.log("res PROPOSAL", res.data);

        if (!res?.status === 200) {
          throw new Error(res?.message);
        }

        toast.success(t("proposal_send_success"));

        if (onProposalSubmitted) {
          onProposalSubmitted();
          handleClose();
        }
      })
      .catch((err) => {
        toast.error(t("error_send_proposal"));
      })
      .finally(() => setLoading(false));
  };

  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{t("modal_proposal_title")}</Modal.Title>
      </Modal.Header>
      <form>
        <Modal.Body>
          <Form.Group controlId="dateOfVisit">
            <Form.Label>{t("general_visit_date")}</Form.Label>
            <CaInput
              type="date"
              name="dateOfVisit"
              value={formData.dateOfVisit}
              onChange={(v) => handleChange("dateOfVisit", v)}
              min={moment().format("YYYY-MM-DD")}
              pattern="\d{2}/\d{2}/\d{4}"
              required
            />
          </Form.Group>

          <Form.Group controlId="hourOfVisit" className="mt-3">
            <Form.Label>{t("general_visit_hour")}</Form.Label>
            <CaInput
              type="time"
              name="hourOfVisit"
              value={formData.hourOfVisit}
              onChange={(v) => handleChange("hourOfVisit", v)}
              required
            />
          </Form.Group>

          <Form.Group controlId="message" className="mt-3">
            <Form.Label>{t("modal_proposal_message_patient")}</Form.Label>
            <CaInput
              as="textarea"
              rows={3}
              name="message"
              value={formData.message}
              onChange={(v) => handleChange("message", v)}
              required
            />
          </Form.Group>

          <Form.Group controlId="price" className="mt-3">
            <Form.Label>{t("modal_proposal_visit_price")}</Form.Label>
            <br></br>
            <small
              className="c-danger"
              style={{ fontWeight: "bolder", fontSize: 12 }}
            >
              {t("general_listed_price")}: {request?.service_id?.service_price}€
            </small>
            <PricePicker
              defaultSelected={{
                label: request?.service_id?.service_price + " euro",
                value: request?.service_id?.service_price,
              }}
              businessID={request?.businessID}
              handleChange={(v) => handleChange("price", v)}
            ></PricePicker>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <CaButton
            variant="secondary"
            onClick={handleClose}
            text={t("general_close")}
          ></CaButton>
          <CaButton
            variant="primary"
            onClick={handleSubmit}
            isLoading={loading}
            text={t("general_submit")}
          ></CaButton>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default AssistanceProposalModal;
