import axios from "axios";

export function useChat() {
  const baseURL = process.env.REACT_APP_BASE_URL;
  // ottieni il singolo utente

  const createConversation = (practitionerID, userID) => {
    if (!practitionerID || !userID) return console.error("mancano id");

    let data = JSON.stringify({
      practitionerID: practitionerID,
      userID: userID,
    });

    let config = {
      method: "post",
      url: baseURL + "/api/chat",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    return axios.request(config);
  };

  const getConversation = (doctorID, userID) => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: baseURL + "/api/chat/users/" + doctorID + "/" + userID,
      headers: {},
    };

    return axios.request(config);
  };
  const getConversationList = (userID) => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: baseURL + "/api/chat/user/" + userID,
      headers: {},
    };

    return axios.request(config);
  };

  const sendMessage = (conversationID, senderID, text) => {
    let data = JSON.stringify({
      conversationID: conversationID,
      senderID: senderID,
      text: text,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: baseURL + "/api/chat/message",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    return axios.request(config);
  };

  return {
    createConversation,
    getConversation,
    sendMessage,
    getConversationList,
  };
}
