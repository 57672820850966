import { Checkbox } from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PhoneInput, { parsePhoneNumber } from "react-phone-number-input";
import { toast } from "react-toastify";
import CaButton from "../../../../../components/CaButton";
import { useAuthentication } from "../../../../../hooks/useAuthentication";

export default function StepVerification({ onSubmit }) {
  const [termsCondition, setTermsCondition] = useState(false);
  const [privacy, setPrivacy] = useState(false);
  const [loading, setLoading] = useState(false);
  const [telefono, setTelefono] = useState("");
  const auth = useAuthentication();
  const { t } = useTranslation();

  const handleTermsCondition = (event) => {
    setTermsCondition(event.target.checked);
  };
  const handlePrivacy = (event) => {
    setPrivacy(event.target.checked);
  };

  const handleSendOTP = (e) => {
    e.preventDefault();
    const phoneNumber = parsePhoneNumber(telefono);
    setLoading(true);

    auth
      .sendOtp(phoneNumber.countryCallingCode, phoneNumber.nationalNumber)
      .then((response) => {
        console.log("response", response);
        setLoading(false);
        if (response.status === 200) {
          toast.success(t("otp_send_success"));
          onSubmit({ telefono });
        }
      })
      .catch((error) => {
        toast.error(t("otp_send_error"));
        setLoading(false);
      });
  };
  return (
    <>
      <div className="col-sm-12">
        <div className="center">
          <p>
            <b>
              <a href={process.env.REACT_TERMS_CONDITION_LINK}>
                {t("general_accept_term_conditions")}
              </a>
            </b>
          </p>

          <Checkbox
            checked={termsCondition}
            onChange={handleTermsCondition}
            required
            color="primary"
            inputProps={{ "aria-label": "secondary checkbox" }}
          />
        </div>
        <div className="center">
          <p>
            <b>
              <a href={process.env.REACT_PRIVACY_POLICY_LINK}>
                {t("general_accept_privacy_policy")}
              </a>
            </b>
          </p>

          <Checkbox
            checked={privacy}
            onChange={handlePrivacy}
            required
            color="primary"
            inputProps={{ "aria-label": "secondary checkbox" }}
          />
        </div>
      </div>
      <div className="col-sm-12 mb-3 mt-3">
        <PhoneInput
          style={{
            width: "100%",
            fontSize: "1rem",
            fontWeight: 400,
            lineHeight: 1.5,
            padding: 10,
            border: "1px solid lightgray",
            borderRadius: 5,
          }}
          defaultCountry="IT"
          value={telefono}
          onChange={(val) => setTelefono(val)}
          rules={{ required: true }}
          disabled={!termsCondition || !privacy}
        ></PhoneInput>
      </div>
      <div className="col-sm-12 mb-3">
        <CaButton
          className="btn btn-primary"
          text={t("general_send_otp")}
          disabled={!telefono}
          onClick={handleSendOTP}
          isLoading={loading}
        ></CaButton>
      </div>
    </>
  );
}
