import moment from "moment";

export const validEmail = new RegExp(
  "^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$"
);
export const validPassword = new RegExp("^(?=.*?[A-Za-z])(?=.*?[0-9]).{6,}$");

export function isValidUrl(url) {
  // Regular expression pattern for a valid website URL
  const regex = /^(https?:\/\/)?[a-z0-9\-]+(\.[a-z0-9\-]+)+([/?#].*)?$/i;

  return regex.test(url);
}

export const validCodiceFiscale = (cf) => {
  var codiceFiscale =
    "^[a-zA-Z]{6}[0-9]{2}[abcdehlmprstABCDEHLMPRST]{1}[0-9]{2}([a-zA-Z]{1}[0-9]{3})[a-zA-Z]{1}$";
  let re = new RegExp(codiceFiscale);
  return re.test(cf);
};
export const isValidEmail = (cf) => {
  return validEmail.test(cf);
};
export const validPartitaIva = (piva) => {
  var re = /^[0-9]{11}$/;
  let piv = new RegExp(re);

  return piv.test(parseInt(piva));
};

export const formatDate = (apiBirthdate) => {
  if (!apiBirthdate) {
    return null; // or handle it in a way that makes sense for your application
  }

  // Assuming apiBirthdate is in the format "YYYY-MM-DD"
  const parts = apiBirthdate.split("-");
  const year = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10) - 1; // Months are 0-based in JavaScript
  const day = parseInt(parts[2], 10);

  const dateObject = new Date(year, month, day);

  if (isNaN(dateObject.getTime())) {
    console.error("Invalid date:", apiBirthdate);
    return null; // or handle the error in a way that makes sense for your application
  }

  const formattedBirthdate = dateObject.toISOString().split("T")[0];
  return formattedBirthdate;
};

export const mapUserData = (userData, type) => {
  let obj = {
    id: userData?._id,
    name: userData?.name,
    surname: userData?.surname,
    codiceFiscale: userData?.codice_fiscale,
    indirizzo: userData?.indirizzo,
    entity: userData?.entity,
    userId: userData?._id,
    email: userData?.user_email,
    password: userData?.password,
    giorno: userData?.giorno,
    mese: userData?.mese,
    anno: userData?.anno,
    professione: userData?.occupation,
    about: userData?.about,
    paese: userData?.paese,
    telefono: userData?.telephone,
    sito: userData?.website,
    linkedin: userData?.linkedin,
    twitter: userData?.twitter,
    facebook: userData?.facebook,
    instagram: userData?.instagram,
    image: userData?.image_url,
    fcmToken: userData?.fcmToken,
    partner: userData?.partner,
    mailchimpTags: userData?.mailchimpTags,
    stripeId: userData?.stripeId,
    piva: userData?.piva,
    type: type,
    birthdate: userData?.birthdate,
    ...userData,
  };

  localStorage.setItem("type", type);

  return obj;
};

export const mergeObjects = (obj1, obj2) => {
  const mergedObj = { ...obj2 };

  for (const key in obj1) {
    if (obj1.hasOwnProperty(key)) {
      if (obj1[key] !== null && obj1[key] !== undefined) {
        mergedObj[key] = obj1[key];
      }
    }
  }

  return mergedObj;
};

//esempio di input "1993-07-23"
export const calculateAge = (birthDate) => {
  // Ottieni la data corrente
  var currentDate = moment();
  // Calcola l'età sottraendo la data di nascita dalla data corrente
  var age = currentDate.diff(birthDate, "years");
  // Restituisci l'età calcolata
  return age;
};
