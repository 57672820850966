import React from "react";
import { Button } from "react-bootstrap";

const CaButton = ({
  isLoading,
  text,
  icon,
  loadingText,
  onClick,
  className,
  error,
  variant,
  type,
  size,
  hide,
  disabled = false,
  ...props
}) => {
  if (hide) return <></>;
  return (
    <Button
      size={size}
      disabled={disabled || isLoading}
      onClick={onClick}
      type={type}
      variant={variant}
      className={className}
      {...props}
    >
      {icon && <i className={icon + " me-3"}></i>}

      {isLoading && (
        <>
          <i className="fa fa-spinner  spinnerLoad"></i>
        </>
      )}
      {!isLoading && text}
    </Button>
  );
};
export default CaButton;

CaButton.defaultProps = {
  variant: "primary",
  isLoading: false,
  error: false,
  text: "",
};
