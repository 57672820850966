import axios from "axios";
import { mergeObjects } from "../utils/Utils";
// una lista di tutti i commenti

export function useBusiness() {
  const baseURL = process.env.REACT_APP_BASE_URL;

  /**
   * Ottieni la lista dei business partner
   * @returns
   */
  const getBusinessPartners = () => {
    var config = {
      method: "get",
      url: baseURL + "/api/business/partners/open",
    };
    return axios(config);
  };
  /**
   * Ottieni la lista dei business type
   * @returns
   */
  const getBusinessType = () => {
    var config = {
      method: "get",
      url: baseURL + "/api/business/types",
    };
    return axios(config);
  };

  // passa l' utente e le modifiche che vuoi apportare come 2 oggetti
  const updateBusinessById = (userID, modifiedBusiness) => {
    var config = {
      method: "put",
      url: baseURL + "/api/businesses/edit-business/" + userID,
      headers: {
        "Content-Type": "application/json",
      },
      data: modifiedBusiness,
    };

    return axios(config);
  };

  return { getBusinessPartners, getBusinessType, updateBusinessById };
}
