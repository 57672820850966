import HistoryIcon from "@material-ui/icons/History";
import MessageIcon from "@material-ui/icons/Message";
import PersonIcon from "@material-ui/icons/Person";
import EventIcon from "@mui/icons-material/Event";
import ExploreIcon from "@mui/icons-material/Explore";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";

export default function CaBottomNavigation() {
  const [value, setValue] = useState("");
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const path = location.pathname.split("/")[1];
    handleSelect(path);
  }, []);

  const handleSelect = (path) => {
    switch (path) {
      case "":
        setValue("");
        break;
      case "history":
        setValue("history");
        break;
      case "account":
        setValue("account");
        break;
      case "account-edit":
        setValue("account");
        break;
      case "visits":
        setValue("visits");
        break;
      case "appointment":
        setValue("appointment");
        break;
      case "conversations":
        setValue("conversations");
        break;
      case "partner":
        setValue("partner");
        break;
      default:
        break;
    }
  };

  const handleChange = (event, newValue) => {
    history.push("/" + newValue);
  };

  return (
    <BottomNavigation
      value={value}
      onChange={handleChange}
      className="bg-light"
      style={{ zIndex: 1000 }}
    >
      <BottomNavigationAction
        label={t("general_menu_explore")}
        value=""
        icon={<ExploreIcon />}
      />
      <BottomNavigationAction
        label={t("general_menu_appointment")}
        value="appointment"
        icon={<EventIcon />}
      />
      <BottomNavigationAction
        label={t("general_menu_visits")}
        value="visits"
        icon={<HistoryIcon />}
      />
      <BottomNavigationAction
        label={t("general_menu_conversations")}
        value="conversations"
        icon={<MessageIcon />}
      />
      <BottomNavigationAction
        label={t("general_menu_profile")}
        value="account"
        icon={<PersonIcon />}
      />
    </BottomNavigation>
  );
}
