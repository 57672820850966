import { registerBlockType } from "@quillforms/blocks";
import datePicker from "./datePicker";
import timePicker from "./timePicker";
import phoneNumber from "./phoneNumber";
import legalNotice from "./legalNotice";
import locationChecker from "./locationChecker";
import professionPicker from "./professionPicker";
import SelectPickerCustom from "./selectPicker";

registerBlockType("date-picker", {
  supports: {
    editable: true,
  },
  attributes: {
    min: {
      type: "number",
      default: 1,
    },
    max: {
      type: "number",
      default: 10,
    },
  },
  display: datePicker,
});
registerBlockType("time-picker", {
  supports: {
    editable: true,
  },
  attributes: {
    min: {
      type: "number",
      default: 1,
    },
    max: {
      type: "number",
      default: 10,
    },
  },
  display: timePicker,
});
registerBlockType("phone-number", {
  supports: {
    editable: true,
  },
  attributes: {},
  display: phoneNumber,
});
registerBlockType("legal-notice", {
  supports: {
    editable: true,
  },
  attributes: {},
  display: legalNotice,
});

registerBlockType("profession-picker", {
  supports: {
    editable: true,
  },
  attributes: {},
  display: professionPicker,
});

registerBlockType("location-checker", {
  supports: {
    editable: true,
  },
  attributes: {},
  display: locationChecker,
});

registerBlockType("select-picker", {
  supports: {
    editable: true,
    defaultValue: true,
  },
  isActive: true,
  attributes: {
    required: false,
    defaultValue: "",
    choices: [],
    disabled: false,
  },

  display: SelectPickerCustom,
});
