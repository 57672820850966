import moment from "moment";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import CaCardDetails from "../../../components/CaCardDetails";
import CaVisitCard from "../../../components/CaVisitCard";
import CaContainer from "../../../components/CaContainer";
import withRequestData from "../../../hoc/withRequestData";
import { useHistory } from "react-router-dom";
import CaRequestStatusBadge from "../../../components/general/CaRequestStatusBadge";

function PageProfessionistManageAppointment({
  request,
  proposal,
  proposalList,
  doctor,
  loading,
}) {
  const { t } = useTranslation();
  const history = useHistory();
  console.log("request: ", request);

  const handleGoCheckout = (request) => {
    if (!request) return;
    history.push("/checkout-request/" + request?._id);
  };
  return (
    <CaContainer backButton="/appointment">
      <Row>
        <Col md={6} sm={12}>
          <CaCardDetails
            status_id={request?.status_id?.status_id}
            title={t("general_appointment_details")}
            list={[
              {
                label: t("general_service_name"),
                value: request?.service_id?.service_name,
                show: request?.service_id?.service_name,
              },
              {
                label: t("general_address"),
                value: request?.address,
                show: request?.address,
              },
              {
                label: t("general_date"),
                value:
                  moment(proposal?.proposed_visit_date).format("DD/MM/YYYY") +
                  " " +
                  proposal?.proposed_visit_time,
                show: true,
              },
              {
                label: t("general_estimated_price"),
                value: proposal?.proposed_price + " €",
                show: true,
              },
              {
                label: t("general_request_type"),
                value: request?.request_type_id?.request_type,
                show: true,
              },
            ]}
            actions={[
              {
                text: t("general_termina_visita"),
                disabled:
                  request?.status_id?.status_id === 5 ||
                  request?.status_id?.status_id === 4,
                onClick: () => handleGoCheckout(request),
              },
            ]}
          ></CaCardDetails>
        </Col>
        <Col md={6} sm={12}>
          <CaVisitCard
            image={request?.user_id?.image_url}
            name={request?.patient_name}
            surname={request?.patient_surname}
            role={null}
            latitude={request?.latitude}
            longitude={request?.longitude}
            descrizione={null}
            list={[
              {
                label: t("general_patient_message"),
                value: request?.message,
              },
              {
                label: t("general_patient_language"),
                value: request?.language_id?.language_name,
              },
              {
                label: t("general_patient_age"),
                value: request?.patient_age,
              },
            ]}
            cell={request?.patient_phone}
            email={request?.request_email}
          ></CaVisitCard>
        </Col>
      </Row>
    </CaContainer>
  );
}

export default withRequestData(PageProfessionistManageAppointment);
