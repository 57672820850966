import React, { useEffect, useState } from "react";

import { useMessages } from "@quillforms/renderer-core";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import CaGoogleAutocomplete from "../../general/CaGoogleAutocomplete";

const LocationChecker = (props) => {
  const {
    id,
    attributes,
    setIsValid,
    setIsAnswered,
    setValidationErr,
    isActive,
    showNextBtn,
    val,
    setVal,
    next,
  } = props;
  const { t } = useTranslation();
  const messages = useMessages();
  const [locationText, setLocationText] = useState(null);
  const { required } = attributes;

  useEffect(() => {
    if (required) {
      if (val) {
        setIsValid(true);
      } else {
        setIsValid(false);
        setValidationErr(messages["label.errorAlert.required"]);
      }
    } else {
      setIsValid(true);
    }
  }, [val]);

  const handleSelectPlace = (place) => {
    console.log(place);
    setVal(place);
  };

  return (
    <>
      <CaGoogleAutocomplete
        country="IT"
        value={locationText}
        onSelectedPlace={(selectedZone) => handleSelectPlace(selectedZone)}
        types={["address"]}
        onChange={(val) => {
          setLocationText(val);
        }}
        label={t("general_address")}
        enabled
      ></CaGoogleAutocomplete>
    </>
  );
};
export default LocationChecker;
