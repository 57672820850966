import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { TextField, InputAdornment, IconButton } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import LockIcon from "@material-ui/icons/Lock";

export default function CaInput({
  value,
  onChange,
  type,
  placeholder,
  label,
  disabled,
  readOnly,
  accept,
  error,
  icon,
  autocomplete,
  iconStartComponent,
  variant,
  required,
  children,
  onFocus,
  min,
  max,
}) {
  const inputClassName = error ? "form-control-error" : "";
  // Add these variables to your component to track the state
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  return (
    <>
      {icon && icon}
      {label && <Form.Label>{label}</Form.Label>}
      {type === "password" && (
        <Form.Control
          variant={variant}
          autoComplete={autocomplete}
          placeholder={placeholder}
          aria-label={label}
          disabled={disabled}
          readOnly={readOnly}
          value={value}
          required={required}
          isInvalid={error}
          style={{ border: error && "2px solid red" }}
          className={inputClassName} // Aggiungi la classe CSS in base all'errore
          type={showPassword ? "text" : "password"} // <-- This is where the magic happens
          onChange={(e) => onChange(e.target.value)}
        />
      )}
      {type !== "password" && (
        <Form.Control
          variant={variant}
          autoComplete={autocomplete}
          InputProps={
            iconStartComponent && {
              startAdornment: (
                <InputAdornment position="start">
                  {iconStartComponent}
                </InputAdornment>
              ),
            }
          }
          min={min}
          max={max}
          type={type}
          error={error}
          isInvalid={error}
          placeholder={placeholder}
          aria-label={label}
          disabled={disabled}
          readOnly={readOnly}
          value={value}
          onFocus={onFocus}
          onChange={(e) => onChange(e.target.value)}
          accept={accept}
          className={inputClassName} // Aggiungi la classe CSS in base all'errore
          label={label}
          required={required}
        />
      )}
      {children}
    </>
  );
}

CaInput.defaultProps = {
  variant: "outlined",
};
