import React, { useEffect, useState } from "react";
import SubscriptionStep1 from "./steps/SubscriptionStep1";
import SubscriptionStep2 from "./steps/SubscriptionStep2";
import SubscriptionStep3 from "./steps/SubscriptionStep3";
import usePrice from "../../../../hooks/usePrice";
import { toast } from "react-toastify";
import CaLoader from "../../../../components/CaLoader";
import { useTranslation } from "react-i18next";

export default function SubscriptionStepper({ onClose }) {
  const [activeStep, setActiveStep] = useState(1);
  const [type, setType] = useState(null);
  const [loading, setLoading] = useState(false);
  const [subscriptions, setSubscriptions] = useState([]);
  const { t } = useTranslation();
  const { getBusinessSubscriptionsPrices } = usePrice();

  useEffect(() => {
    setLoading(true);
    getBusinessSubscriptionsPrices()
      .then((result) => {
        setLoading(false);

        if (result.status === 200) {
          setSubscriptions(result.data.subscription);
        } else {
          toast.error(t("error_while_recovering_subscriptions"));
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  }, []);

  const handleChange = (step) => {
    setActiveStep(step);
  };
  return (
    <>
      <CaLoader loading={loading}></CaLoader>
      {activeStep === 1 && (
        <SubscriptionStep1
          onChange={handleChange}
          setType={setType}
          subscriptions={subscriptions}
        ></SubscriptionStep1>
      )}
      {activeStep === 2 && (
        <SubscriptionStep2
          onChange={handleChange}
          type={type}
        ></SubscriptionStep2>
      )}
      {activeStep === 3 && (
        <SubscriptionStep3
          onChange={handleChange}
          type={type}
          onClose={onClose}
        ></SubscriptionStep3>
      )}
    </>
  );
}
