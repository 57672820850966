import React, { useContext, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";

import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import CaContainer from "../../../components/CaContainer";
import ProfessionistRequestDetail from "../../../components/professionist/ProfessionistRequestDetail";
import { UserContext } from "../../../context/UserContext";
import useProfessionRequests from "../../../hooks/professionist/useProfessionRequests";
import { useDoctor } from "../../../hooks/useDoctor";

export default function PageProfessionistManageRequest() {
  const { getDoctorById } = useDoctor();
  const location = useLocation();
  const { user } = useContext(UserContext);
  const { getSingleRequest } = useProfessionRequests();
  const [richiesta, setRichiesta] = useState(null);
  const [proposte, setProposte] = useState([]);
  const [proposta, setProposta] = useState(null);
  const [loading, setLoading] = useState(null);
  const { t } = useTranslation();
  const { requestID } = useParams();

  useEffect(() => {
    // get single request
    getRequest();
  }, [requestID]);

  const getRequest = () => {
    setLoading(true);
    getSingleRequest(requestID)
      .then((res) => {
        setLoading(false);
        console.log("SINGLE  REQ:", res);
        setRichiesta(res.data?.response?.visit_request);
        setProposte(res.data?.response?.visit_proposals);

        // trova la proposta accettata
        let propostaAccettata = res.data?.response?.visit_proposals.filter(
          (proposal) => proposal?.status_id.status_id === 1
        )[0];

        if (propostaAccettata) {
          setProposta(propostaAccettata);
        }
        // la proposta accettata è quella del professionista ?
        if (propostaAccettata?.user_id?._id === user?._id) {
          console.log("MIA proposta accettata", propostaAccettata);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error("errore", err);
        toast.error(t("error_while_getting_request"));
      });
  };

  const handleSubmitProposal = (proposta) => {
    getRequest();
    return;
  };

  return (
    <CaContainer loading={loading} backButton={"/"}>
      <Row>
        <Col>
          <ProfessionistRequestDetail
            request={richiesta}
            proposals={proposte}
            onProposalSubmitted={handleSubmitProposal}
          ></ProfessionistRequestDetail>
        </Col>
      </Row>
    </CaContainer>
  );
}
