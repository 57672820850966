import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import CaButton from "../../../../components/CaButton";
import { UserContext } from "../../../../context/UserContext";
import CheckOutFormPacchetto from "../../../shared/checkout/CheckOutFormPacchetto";
import CheckOutFormPayment from "../../../shared/checkout/CheckOutFormPayment";
import TabellaCheckOut from "../../../../components/shared/TabellaCheckOut";
import { useTranslation } from "react-i18next";

const CheckOutBusiness = (props) => {
  const { user } = useContext(UserContext);
  const [commissioneCaducea, setCommissioneCaducea] = useState(0);
  const { t } = useTranslation();
  const history = useHistory();
  useEffect(() => {
    if (user?.partner || user?.requestsRemaining > 0) {
      setCommissioneCaducea(0);
    }
  }, []);

  //TODO
  const handleShowPaymentForm = () => {
    if (user?.requestsRemaining && user?.requestsRemaining > 0) {
      return (
        <CheckOutFormPacchetto
          doctor={props.doctor}
          requestData={props.requestData}
          url={"/api/send/invoice/business-with-requests"}
        ></CheckOutFormPacchetto>
      );
    }
    if (!user?.requestsRemaining || user?.requestsRemaining <= 0) {
      return (
        <CheckOutFormPayment
          commissioneCaducea={commissioneCaducea}
          doctor={props.doctor}
          requestData={props.requestData}
          url={"/api/send/invoice/customer"}
        ></CheckOutFormPayment>
      );
    }
  };

  return (
    <div className="widget widget-activity-five">
      <div className="widget-heading">
        <CaButton
          text={t("general_back_home")}
          onClick={() => history.push("/")}
          icon="fa fa-arrow-left"
          variant="link"
          className="btn btn-link"
        ></CaButton>
        <h5>
          {t("general_doctor_name")}
          <b> {props.doctor.doctorName}</b>
        </h5>
      </div>

      <div className="widget-content widget-content-area">
        <div className="row mt-4">
          {handleShowPaymentForm()}

          <TabellaCheckOut
            doctor={props.doctor}
            commissioneCaducea={commissioneCaducea}
          ></TabellaCheckOut>
        </div>
      </div>
    </div>
  );
};
export default CheckOutBusiness;
