import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import CaButton from "../../../components/CaButton";
import CaContainer from "../../../components/CaContainer";
import CaLoader from "../../../components/CaLoader";
import useProfessionRequests from "../../../hooks/professionist/useProfessionRequests";
import errorImg from "../../../img/svg/error.svg";
import CheckOutUser from "./checkout/CheckOutUser";

export default function PageUserPayment(props) {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const { proposalID } = useParams();
  const { getSingleProposal } = useProfessionRequests();
  const [loading, setLoading] = useState();
  const [proposal, setProposal] = useState(null);

  useEffect(() => {
    // get single request
    getProposal();
  }, [proposalID]);

  const getProposal = () => {
    setLoading(true);
    getSingleProposal(proposalID)
      .then((res) => {
        setLoading(false);
        console.log("PROPOSAL REQ:", res);
        setProposal(res.data?.response);
      })
      .catch((err) => {
        setLoading(false);
        console.error("errore", err);
        toast.error(t("error_while_getting_request"));
      });
  };

  if (!proposal) {
    return (
      <CaContainer backButton={"/"} loading={loading}>
        <Row>
          <Col sm={12} md={6}>
            <h6 className="mt-4">{t("select_proposal_to_go_next")}</h6>
          </Col>
          <Col sm={12} md={6}>
            <img
              src={errorImg}
              className="img-fluid"
              style={{ maxHeight: 300 }}
              alt="error"
            ></img>
          </Col>
        </Row>
      </CaContainer>
    );
  }

  return (
    <CaContainer backButton={"/"}>
      <CheckOutUser proposal={proposal}></CheckOutUser>
    </CaContainer>
  );
}
