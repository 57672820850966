import Checkbox from "@material-ui/core/Checkbox";
import React, { useState } from "react";
import { Col, Row, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import PhoneInput, { parsePhoneNumber } from "react-phone-number-input";
import { toast } from "react-toastify";
import CaButton from "../../../../../components/CaButton";
import CaInput from "../../../../../components/CaInput";
import { useAuthentication } from "../../../../../hooks/useAuthentication";

export default function StepOTPProfessionistReg({
  show,
  onSubmit,
  onGoBack,
  onChange,
  formData,
  disableNext,
}) {
  const auth = useAuthentication();
  const [loading, setLoading] = useState(false);
  const [stepOtp, setStepOtp] = useState(1);
  const { t } = useTranslation();

  const handleSendOTP = (e) => {
    e.preventDefault();
    const phoneNumber = parsePhoneNumber(formData.telefono);
    setLoading(true);

    auth
      .sendOtp(phoneNumber.countryCallingCode, phoneNumber.nationalNumber)
      .then((response) => {
        console.log("response", response);
        setLoading(false);
        if (response.status === 200) {
          setStepOtp(2);

          toast.success(t("otp_code_send"));
        }
      })
      .catch((error) => {
        toast.error(t("otp_code_send_error"));
        setLoading(false);
      });
  };
  return (
    <Container style={{ display: !show && "none" }}>
      <Row className="mt-4">
        {stepOtp === 1 && (
          <>
            <Col
              sm={12}
              style={{
                display: stepOtp === 1 ? "block" : "none",
              }}
            >
              <div className="center">
                <a href={process.env.REACT_TERMS_CONDITION_LINK}>
                  {t("general_accept_terms_conditions")}
                </a>

                <Checkbox
                  checked={formData?.termsCondition}
                  onChange={(e) => onChange(e.target.checked, "termsCondition")}
                  required
                  color="primary"
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />
              </div>

              <div className="center">
                <a href={process.env.REACT_PRIVACY_POLICY_LINK}>
                  {t("general_accept_privacy_policy")}
                </a>

                <Checkbox
                  checked={formData?.privacyPolicy}
                  onChange={(e) => onChange(e.target.checked, "privacyPolicy")}
                  required
                  color="primary"
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />
              </div>
            </Col>
            <Col sm={12} className="mt-3">
              <PhoneInput
                style={{
                  width: "100%",
                  fontSize: "1rem",
                  fontWeight: 400,
                  lineHeight: 1.5,
                  padding: 10,
                  border: "1px solid lightgray",
                  borderRadius: 5,
                }}
                defaultCountry="IT"
                value={formData?.telefono}
                onChange={(val) => onChange(val, "telefono")}
                rules={{ required: true }}
                disabled={!formData?.termsCondition || !formData?.privacyPolicy}
              ></PhoneInput>
              <CaButton
                text={t("general_send_otp")}
                onClick={handleSendOTP}
                isLoading={loading}
                disabled={!formData.telefono}
                className={"btn btn-primary mt-3"}
              ></CaButton>
            </Col>
          </>
        )}

        <Col
          className="mb-3"
          style={{ display: stepOtp === 2 ? "block" : "none" }}
        >
          <CaInput
            className="single-input"
            variant="outlined"
            onChange={(e) => onChange(e, "otp")}
            text={t("general_otp")}
            type="number"
            value={formData.otp}
            required
          ></CaInput>
          {/*  <CaButton
          className="btn btn-primary mt-3 c-white"
          isLoading={loading}
          text="Verifica OTP"
          disabled={!otp}
          onClick={handleSubmit}
        ></CaButton> */}
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <CaButton
            text={t("general_back")}
            variant={"outline"}
            onClick={(e) => {
              e.preventDefault();
              onGoBack();
            }}
          ></CaButton>
        </Col>
        <Col>
          <CaButton
            text={t("general_next")}
            disabled={!formData.otp}
            onClick={(e) => {
              e.preventDefault();
              onSubmit();
            }}
          ></CaButton>
        </Col>
      </Row>
    </Container>
  );
}
