import React from "react";
import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import CaContainer from "../../../components/CaContainer";
import ChatProfessionist from "./ChatProfessionist";

export default function PageProfessionistChat() {
  const { patientID } = useParams();

  return (
    <CaContainer backButton={"/conversations"}>
      <Row>
        <ChatProfessionist patientID={patientID}></ChatProfessionist>
      </Row>
    </CaContainer>
  );
}
