import axios from "axios";
// una lista di tutti i commenti

export default function usePrice() {
  const baseURL = process.env.REACT_APP_BASE_URL;

  const getBusinessSubscriptionsPrices = (data) => {
    return axios.get(baseURL + "/api/price/subscription/business", data);
  };
  const getUserPrices = (data) => {
    return axios.get(baseURL + "/api/price/user/prenotazione", data);
  };

  const getPriceByBusinessID = (businessID) => {
    if (!businessID) return console.error("Nessuno Business id");
    var config = {
      method: "get",
      url: baseURL + "/api/price/business/" + businessID,
    };

    return axios(config);
  };

  return {
    getBusinessSubscriptionsPrices,
    getUserPrices,
    getPriceByBusinessID,
  };
}
