import { Avatar } from "@material-ui/core";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-number-input";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import CaButton from "../../../components/CaButton";
import CaContainer from "../../../components/CaContainer";
import CaInput from "../../../components/CaInput";
import CaSelect from "../../../components/CaSelect";
import CaGoogleAutocomplete from "../../../components/general/CaGoogleAutocomplete";
import ProfileDetail from "../../../components/profiloComponent/ProfileDetail";
import { UserContext } from "../../../context/UserContext";
import { useBusiness } from "../../../hooks/useBusiness";
import {
  isValidUrl,
  validCodiceFiscale,
  validPartitaIva,
} from "../../../utils/Utils";

export default function PageEditBusinessProfile() {
  const [name, setname] = useState("");
  const [surname, setSurname] = useState("");
  const [professione, setprofessione] = useState("");
  const [about, setabout] = useState("");
  const [paese, setpaese] = useState("");
  const [indirizzo, setindirizzo] = useState("");
  const [type, setType] = useState("");
  const [telefono, settelefono] = useState("");
  const [sito, setsito] = useState("");
  const [linkedin, setlinkedin] = useState("");
  const [registroDelleImprese, setRegistroDelleImprese] = useState("");
  const [selectedPlace, setSelectedPlace] = useState(false);
  const [pec, setPec] = useState("");
  const [sedeLegale, setSedeLegale] = useState("");
  const [image, setimage] = useState("");
  const [facebook, setfacebook] = useState("");
  const [twitter, settwitter] = useState("");
  const [instagram, setinstagram] = useState("");
  const [piva, setPiva] = useState("");
  const [codiceFiscale, setCodiceFiscale] = useState("");
  const { user, setUser, userType } = useContext(UserContext);
  const { updateBusinessById } = useBusiness();
  const history = useHistory();
  const { t } = useTranslation();
  const [businessType, setBusinessType] = useState([]);

  const { getBusinessType } = useBusiness();

  useEffect(() => {
    getBusinessType()
      .then((res) => {
        if (res.status === 200) {
          setBusinessType(res.data.response);
        }
      })
      .catch((e) => {
        toast.error(t("general_error_data_recovering"));
      });
  }, []);

  useEffect(() => {
    if (typeof user !== "undefined") {
      //FIXME aggiungi tutti i dati iniziali sul business
      let nameSplitted = user.name.split(" ");
      setname(nameSplitted[0]);
      setSurname(nameSplitted[1]);
      setprofessione(user.professione);
      setabout(user.about);
      setpaese(user.paese);
      setindirizzo(user.indirizzo);
      settelefono(user.telefono);
      setsito(user.sito);
      setlinkedin(user.linkedin);
      setfacebook(user.facebook);
      settwitter(user.twitter);
      setinstagram(user.instagram);
      setType(user.type);
      setCodiceFiscale(user.codiceFiscale);
      setPiva(user.piva);
      setimage(user.image);
    }
  }, [user]);
  const handleSave = (e) => {
    e.preventDefault();

    if (codiceFiscale && !validCodiceFiscale(codiceFiscale))
      return toast.error(t("error_fiscal_code_not_valid"));
    if (piva && !validPartitaIva(piva))
      return toast.error(t("error_piva_not_valid"));
    if (sito && !isValidUrl(sito))
      return toast.error(t("error_website_not_valid"));

    let modifiedBusiness = {
      name: name,
      surname: surname,
      telefono: telefono,

      codiceFiscale: codiceFiscale,
      businessPIVA: piva,
      businessType: type, //FIXME setta da api
      registroDelleImprese: registroDelleImprese,
      pec: pec,

      languageId: "658ebb1adc51ef347a360385",
      descrizione: about,

      website: sito,
      linkedin: linkedin,
      twitter: twitter,
      facebook: facebook,
      instagram: instagram,

      fcmToken: user?.fcmToken, //TODO
      expoToken: user?.expoToken, //TODO

      //FIXME - riempire con i dati business
      indirizzo: indirizzo,
      citta: selectedPlace?.city,
      cittaShort: selectedPlace?.cityShort,
      codicePostale: selectedPlace?.postal_code,
      paese: paese,
      paeseShort: selectedPlace?.countryShort,
      latitude: selectedPlace?.lat,
      longitude: selectedPlace?.lng,
      sedeLegale: sedeLegale,
    };

    updateBusinessById(user, modifiedBusiness)
      .then((response) => {
        setUser({ ...response.data });
        toast.success(t("general_user_updated_successfully"));
      })
      .catch((e) => toast.error(t("error_while_updating_user")));
  };
  const fileInputRef = useRef();

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];

    // Create a FormData object and append the file to it
    const formData = new FormData();
    formData.append("image", file);

    try {
      // Send the FormData object to the server using fetch
      const response = await fetch(
        process.env.REACT_APP_BASE_URL + "/api/upload",
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      // Get the response data as JSON
      const data = await response.json();
      let modifiedUser = {
        image: data.fileUrl,
      };
      let typeOfUser = "business";

      updateBusinessById(user, modifiedUser, typeOfUser)
        .then((response) => {
          setUser({ ...response.data });
          toast.success(t("general_user_updated_successfully"));
        })
        .catch((e) => toast.error(t("error_while_updating_user")));

      // Do something with the response data, e.g. display the uploaded image
    } catch (error) {
      console.error(error);
      toast.error(t("error_while_updating_user"));
    }
  };

  return (
    <CaContainer backButton={"/account"}>
      <Container fluid>
        <Row className="mt-4">
          <Col>
            <Form>
              <Row>
                <Form.Group className="mb-3 col-sm-12 col-md-12">
                  <Form.Label>{t("general_image")}</Form.Label>
                  <Avatar className="mb-2" src={image}></Avatar>

                  <CaButton
                    variant="primary"
                    onClick={handleClick}
                    text={t("general_upload_image")}
                  ></CaButton>
                  <input
                    type="file"
                    accept="image/*"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                </Form.Group>
              </Row>

              <Row>
                <Form.Group className="mb-3 col-sm-12 col-md-4">
                  <Form.Label>{t("general_name")}</Form.Label>
                  <CaInput
                    value={name}
                    onChange={(val) => setname(val)}
                  ></CaInput>
                </Form.Group>
                <Form.Group className="mb-3 col-sm-12 col-md-4">
                  <Form.Label>{t("general_surname")}</Form.Label>
                  <CaInput
                    value={surname}
                    onChange={(val) => setSurname(val)}
                  ></CaInput>
                </Form.Group>
                <Form.Group className="mb-3 col-sm-12 col-md-4">
                  <Form.Label>{t("general_profession")}</Form.Label>
                  <CaInput
                    value={professione}
                    onChange={(val) => setprofessione(val)}
                  ></CaInput>
                </Form.Group>
              </Row>

              <Row>
                <Form.Group className="mb-3 col-sm-12 col-md-6">
                  <Form.Label>{t("general_fiscal_code")}</Form.Label>
                  <CaInput
                    value={codiceFiscale}
                    onChange={(val) => setCodiceFiscale(val)}
                    error={!validCodiceFiscale(codiceFiscale)}
                  ></CaInput>
                </Form.Group>
                <Form.Group className="mb-3 col-sm-12 col-md-6">
                  <Form.Label>{t("general_piva")}</Form.Label>
                  <CaInput
                    value={piva}
                    onChange={(val) => setPiva(val)}
                    type={"number"}
                    error={!validPartitaIva(piva)}
                  ></CaInput>
                </Form.Group>
              </Row>

              <Row>
                <Form.Group className="mb-3 col-sm-12 col-md-6">
                  <Form.Label>{t("general_about_me")}</Form.Label>
                  <CaInput
                    value={about}
                    onChange={(val) => setabout(val)}
                  ></CaInput>
                </Form.Group>
                <Form.Group className="mb-3 col-sm-12 col-md-6">
                  <Form.Label>{t("general_select_business_type")}</Form.Label>
                  <CaSelect
                    placeholder={t("general_select_business_type")}
                    options={businessType}
                    getOptionLabel={(option) =>
                      t("general_business_type_" + option.type_id)
                    }
                    getOptionValue={(option) => option._id}
                    onChange={(option) => {
                      if (option) {
                        setType(option?._id);
                        return;
                      }
                      setType(null);
                    }}
                    required
                  ></CaSelect>
                </Form.Group>

                <Form.Group className="mb-3 col-sm-12 col-md-6">
                  <Form.Label>{t("general_address")}</Form.Label>
                  <CaGoogleAutocomplete
                    placeholder={"Via Roma n° 4 Siena"}
                    onSelectedPlace={(address) => setSelectedPlace(address)}
                    //error={specificAlert}
                    enabled={true}
                    value={indirizzo}
                  ></CaGoogleAutocomplete>
                </Form.Group>
                <Form.Group className="mb-3 col-sm-12 col-md-6">
                  <Form.Label>{t("general_sede_legale")}</Form.Label>
                  <CaInput
                    value={sedeLegale}
                    onChange={(val) => setSedeLegale(val)}
                    type="text"
                  ></CaInput>
                </Form.Group>
              </Row>

              <Row>
                <Form.Group className="mb-3 col-sm-12 col-md-6">
                  <Form.Label>{t("general_nation")}</Form.Label>
                  <CaSelect
                    options={countries}
                    value={countries.filter((option) => option.value === paese)}
                    onChange={(val) => setpaese(val?.value)}
                  ></CaSelect>
                </Form.Group>

                <Form.Group className="mb-3 col-sm-12 col-md-6">
                  <Form.Label>{t("general_phone")}</Form.Label>
                  <PhoneInput
                    style={{
                      width: "100%",
                      fontSize: "1rem",
                      fontWeight: 400,
                      lineHeight: 1.5,
                      padding: 10,
                      border: "1px solid lightgray",
                      borderRadius: 5,
                    }}
                    defaultCountry="IT"
                    value={telefono}
                    onChange={(val) => settelefono(val)}
                    rules={{ required: true }}
                  ></PhoneInput>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group className="mb-3 col-sm-12 col-md-6">
                  <Form.Label>{t("general_website")}</Form.Label>
                  <CaInput
                    value={sito}
                    onChange={(val) => setsito(val)}
                    type="text"
                  ></CaInput>
                </Form.Group>
                <Form.Group className="mb-3 col-sm-12 col-md-6">
                  <Form.Label>{t("general_email")}</Form.Label>
                  <CaInput value={user?.email} disabled type="email"></CaInput>
                </Form.Group>
                <Form.Group className="mb-3 col-sm-12 col-md-6">
                  <Form.Label>{t("general_pec")}</Form.Label>
                  <CaInput
                    value={pec}
                    onChange={(val) => setPec(val)}
                    type="email"
                  ></CaInput>
                </Form.Group>
                <Form.Group className="mb-3 col-sm-12 col-md-6">
                  <Form.Label>{t("general_registro_delle_imprese")}</Form.Label>
                  <CaInput
                    value={user?.registroDelleImprese}
                    type="text"
                  ></CaInput>
                </Form.Group>
              </Row>

              <Row>
                <Form.Group className="mb-3 col-sm-12 col-md-6">
                  <Form.Label>Linkedin </Form.Label>
                  <CaInput
                    value={linkedin}
                    onChange={(val) => setlinkedin(val)}
                    type="text"
                  ></CaInput>
                </Form.Group>
                <Form.Group className="mb-3 col-sm-12 col-md-6">
                  <Form.Label>Twitter </Form.Label>
                  <CaInput
                    value={twitter}
                    onChange={(val) => settwitter(val)}
                    type="text"
                  ></CaInput>
                </Form.Group>
                <Form.Group className="mb-3 col-sm-12 col-md-6">
                  <Form.Label>Facebook </Form.Label>
                  <CaInput
                    value={facebook}
                    onChange={(val) => setfacebook(val)}
                    type="text"
                  ></CaInput>
                </Form.Group>
                <Form.Group className="mb-3 col-sm-12 col-md-6">
                  <Form.Label>Instagram </Form.Label>
                  <CaInput
                    value={instagram}
                    onChange={(val) => setinstagram(val)}
                    type="text"
                  ></CaInput>
                </Form.Group>
              </Row>
            </Form>
          </Col>
        </Row>
        <CaButton
          text={t("general_save")}
          onClick={handleSave}
          className="btn btn-primary mt-4"
        ></CaButton>
      </Container>
      <ProfileDetail></ProfileDetail>
    </CaContainer>
  );
}

const countries = [
  { value: "US", label: "United States" },
  { value: "CA", label: "Canada" },
  { value: "MX", label: "Mexico" },
  { value: "GB", label: "United Kingdom" },
  { value: "FR", label: "France" },
  { value: "DE", label: "Germany" },
  { value: "JP", label: "Japan" },
  { value: "KR", label: "South Korea" },
  { value: "IT", label: "Italy" },
];
