import DateFnsUtils from "@date-io/date-fns";
import { TextField } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Switch from "@material-ui/core/Switch";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { DoctorRequestContext } from "../../../../context/DoctorRequestContext";
import { useTranslation } from "react-i18next";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function StepTwo(props) {
  const { doctorRequest, setDoctorRequest } = useContext(DoctorRequestContext);
  const { t } = useTranslation();
  useEffect(() => {
    let newState = Object.assign({}, doctorRequest);
    newState.timeStart = new Date();
    setDoctorRequest(newState);
  }, []);

  const [state, setState] = React.useState({
    checkedA: false,
    checkedB: true,
  });

  const handleDateChange = (e) => {
    let newState = Object.assign({}, doctorRequest);
    let prenotationDate = moment(e).format("DD-MM-YYYY");
    let prenotationHour = moment(e).format("LT");
    newState.timeStart = new Date(e).getTime();
    newState.dataPrenotazione = prenotationDate;
    newState.oraPrenotazione = prenotationHour;
    setDoctorRequest(newState);
  };

  const handleChange = (event) => {
    var date = new Date().getTime();
    setState({ ...state, [event.target.name]: event.target.checked });
    let newState = Object.assign({}, doctorRequest);
    let prenotationDate = moment(event).format("DD-MM-YYYY");
    let prenotationHour = moment(event).format("LT");
    newState.timeChecked = event.target.checked;
    newState.timeStart = date;
    newState.dataPrenotazione = prenotationDate;
    newState.oraPrenotazione = prenotationHour;
    setDoctorRequest(newState);
  };
  const languageChange = (lang) => {
    let newState = Object.assign({}, doctorRequest);
    newState.language = lang;
    setDoctorRequest(newState);
  };

  const typeChange = (e) => {
    let newState = Object.assign({}, doctorRequest);
    newState.testType = e.target.value;
    setDoctorRequest(newState);
  };

  const numberChange = (e) => {
    let newState = Object.assign({}, doctorRequest);
    newState.testNumber = e.target.value;
    setDoctorRequest(newState);
  };

  return (
    <div className="container mt-4 " style={{ marginBottom: 200 }}>
      <div className="row mt-4 ">
        <div className="col-sm-12 col-md-6 mt-6 ">
          <h4>{t("general_asap")}</h4>

          <Switch
            checked={state.checkedA}
            onChange={(e) => handleChange(e)}
            name="checkedA"
            inputProps={{ "aria-label": "secondary checkbox" }}
          />
          {!state.checkedA ? (
            <div>
              <h4>{t("request_select_visit_hour")}</h4>
              <div className="marginForm ">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DateTimePicker
                    minDate={Date.now()}
                    style={{ maxWidth: 250 }}
                    value={doctorRequest.timeStart}
                    onChange={handleDateChange}
                  ></DateTimePicker>
                </MuiPickersUtilsProvider>
              </div>
            </div>
          ) : null}
        </div>
        {doctorRequest.requestType === "covid" ? (
          <div className="col-sm-12 col-md-6">
            <h4 className="mb-4">{t("request_select_covid_test_type")}</h4>

            <Select
              labelId="demo-simple-select-filled-label"
              id="demo-simple-select-filled"
              variant="outlined"
              error={
                doctorRequest.testType == ""
                  ? doctorRequest.error
                    ? true
                    : false
                  : null
              }
              helperText={
                doctorRequest.testType == ""
                  ? doctorRequest.error
                    ? "Required"
                    : null
                  : null
              }
              required
              value={doctorRequest.testType}
              onChange={(e) => typeChange(e)}
            >
              <MenuItem value={"Molecolare"}>
                {t("request_select_covid_test_type_molecolare")}
              </MenuItem>
              <MenuItem value={"Rapido"}>
                {t("request_select_covid_test_type_rapido")}
              </MenuItem>
            </Select>
          </div>
        ) : null}
        {doctorRequest.requestType == "covid" ? (
          <div className="col-sm-12 col-md-6">
            <h4 className="mb-4">{t("request_select_covid_test_number")}</h4>
            <TextField
              id="outlined-basic"
              label="es. 1"
              variant="outlined"
              error={
                doctorRequest.testNumber == ""
                  ? doctorRequest.error
                    ? true
                    : false
                  : null
              }
              helperText={
                doctorRequest.testNumber == ""
                  ? doctorRequest.error
                    ? "Required"
                    : null
                  : null
              }
              type="number"
              required
              value={doctorRequest.testNumber}
              onChange={(e) => numberChange(e)}
            />
          </div>
        ) : null}
        <div className="col-sm-12 col-md-6">
          <h4 className="mb-4">{t("request_select_language")}</h4>
          <Autocomplete
            multiple
            required
            id="checkboxes-tags-demo"
            error={
              doctorRequest.language == ""
                ? doctorRequest.error
                  ? true
                  : false
                : null
            }
            helperText={
              doctorRequest.language == ""
                ? doctorRequest.error
                  ? "Required"
                  : null
                : null
            }
            options={top100Films}
            disableCloseOnSelect
            value={doctorRequest.language}
            onChange={(e, value) => languageChange(value)}
            getOptionLabel={(option) => option}
            renderOption={(option, { selected }) => (
              <React.Fragment>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option}
              </React.Fragment>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Languages"
                placeholder={t("general_favourite_language")}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
}

const top100Films = ["Spanish", "English", "German", "French", "Italian"];
