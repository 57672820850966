import React from "react";
import { Image } from "react-bootstrap";
import { USER_PLACEHOLDER } from "../utils/const";

export default function CaAvatar({ image, width, height, className }) {
  return (
    <Image
      roundedCircle
      src={image || USER_PLACEHOLDER}
      style={{ width: width, height: height }}
      className={className}
    ></Image>
  );
}

CaAvatar.defaultProps = {
  width: 40,
  height: 40,
  image: USER_PLACEHOLDER,
};
