import React, { useContext } from "react";
import StepperSignupUser from "./components/StepperSignupUser";
import { CompanyContext } from "../../../context/CompanyContext";
import { Card, Col, Container, Form, Row, Image } from "react-bootstrap";

const PageSignUpUser = (props) => {
  const { company } = useContext(CompanyContext);

  return (
    <Container style={{ height: "100vh" }} fluid>
      <Row className="center h-100">
        <Col md={6} lg={4}>
          <Card>
            <Card.Body>
              <Form>
                <Form.Group className="center mb-4">
                  <Image
                    alt="logo"
                    src={company?.configuration?.logo}
                    style={{ maxWidth: 200 }}
                  />
                </Form.Group>

                <StepperSignupUser></StepperSignupUser>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default PageSignUpUser;
