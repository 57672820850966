import React, { useEffect } from "react";
import CaSelect from "../../CaSelect";

const SelectPickerCustom = (props) => {
  const {
    id,
    attributes,
    isValid,
    setIsValid,
    setIsAnswered,
    setValidationErr,
    isActive,
    val,
    setVal,
    next,
  } = props;

  const { required, choices, disabled, defaultValue } = attributes;

  useEffect(() => {
    if (defaultValue) {
      setVal(defaultValue);
      setIsValid(true);
    }
  }, []);

  useEffect(() => {
    if (required) {
      if (!val) {
        setIsValid(false);
        setIsAnswered(false);
      }
      if (val) {
        setIsValid(true);
        setIsAnswered(true);
      }
    }
    if (!required) {
      if (!val) {
        setIsValid(true);
        setIsAnswered(true);
      }
      if (val) {
        setIsValid(true);
        setIsAnswered(true);
      }
    }
  }, [val]);

  return (
    <CaSelect
      options={choices}
      onChange={(e) => setVal(e)}
      selected={choices.filter((opt) => opt.value === val)[0]}
      error={!isValid}
      isDisabled={disabled}
    ></CaSelect>
  );
};
export default SelectPickerCustom;
