import axios from "axios";
import { useAuthentication } from "./useAuthentication";
// una lista di tutti i commenti

export function useProposal() {
  const baseURL = process.env.REACT_APP_BASE_URL;

  /**
   * @returns List of proposal
   */
  const getProposalByRequestID = (requestID) => {
    var config = {
      method: "get",
      url: baseURL + "/api/proposals/person/" + requestID + "/request",
    };
    return axios(config);
  };

  /**
   * @returns Accept professionists proposal
   */
  const acceptProfessionistProposal = (
    idProposal,
    stripe_token,
    payment_method
  ) => {
    var config = {
      method: "put",
      url: baseURL + "/api/proposals/person/" + idProposal + "/accept",
      data: { stripe_token: stripe_token, payment_method: payment_method },
    };
    return axios(config);
  };

  return { getProposalByRequestID, acceptProfessionistProposal };
}
