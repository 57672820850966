import i18n from "i18next";
import { initReactI18next } from "react-i18next";

let languages = "en,it"; // aggiungi qui le nuove lingue sulla base del nome del json
const languagesEl = document.getElementById("platform-languages");
if (languagesEl) {
  languages = languagesEl.getAttribute("data");
}
const resources = {};
languages.split(",").forEach((l) => {
  resources[l] = { translation: require(`../src/translations/${l}.json`) };
});

let lng = "it";

const preferredLanguages = navigator.languages;
const primaryLanguage = preferredLanguages[0];
console.log("Primary Preferred Language:", primaryLanguage);

i18n.use(initReactI18next).init({
  resources,
  lng,
  fallbackLng: "it",
  debug: false,
  react: {
    transKeepBasicHtmlNodesFor: ["br", "strong", "i", "b", "sup"],
  },
});

export default i18n;
