import React, { createContext, useEffect, useState } from "react";
import axios from "axios";
import { useCompany } from "../hooks/useCompany";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

export const CompanyContext = createContext();

export const CompanyProvider = (props) => {
  const { getCompanyConfig } = useCompany();
  const [company, setCompany] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    let subdomain = window.location.hostname.split(".").slice(0, -2).join(".");
    console.log("subdomain", subdomain);
    if (
      !subdomain ||
      subdomain === "app" ||
      subdomain === "dev--caducea-live"
    ) {
      subdomain = "caducea";
    }

    getCompanyConfig(subdomain)
      .then((response) => {
        console.log("resposne", response);
        if (response.status === 200) {
          setCompany(response.data.response.company_info);

          let companySecret = response.data.response.company_info.secret;

          if (!companySecret) {
            throw new Error();
          }

          localStorage.setItem("secret", companySecret);
          document.cookie = "secret=" + companySecret;
          // setto l' auth token
          axios.defaults.headers.common["secret"] = companySecret;
          axios.defaults.withCredentials = true;
        } else {
          throw new Error();
        }
      })
      .catch((e) => {
        console.log("error company", e);
        toast.error(t("error_can_not_get_company"));
      });
  }, []);

  //const [tab, setTab] = useState(<ManageRequest></ManageRequest>);

  return (
    <CompanyContext.Provider value={{ company, setCompany }}>
      {props.children}
    </CompanyContext.Provider>
  );
};
