import React, { useContext, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { parsePhoneNumber } from "react-phone-number-input";
import { toast } from "react-toastify";
import { DoctorRequestContext } from "../../../../context/DoctorRequestContext";
import { UserContext } from "../../../../context/UserContext";
import { useAuthentication } from "../../../../hooks/useAuthentication";
import StepPersonalInfo from "./steps/StepPersonalInfo";
import StepSubmit from "./steps/StepSubmit";
import StepVerification from "./steps/StepVerification";
import { useTranslation } from "react-i18next";

export default function StepperSignupUser() {
  const [formData, setFormData] = useState({
    name: null,
    surname: null,
    email: null,
    pwd: null,
    confirmPwd: null,
    otp: null,
    telefono: null,
  });
  const { user, setUser, setLoggedIn, setToken, setUserType } =
    useContext(UserContext);

  const auth = useAuthentication(setUser, setLoggedIn, setToken, setUserType);
  const { doctorRequest, setDoctorRequest } = useContext(DoctorRequestContext);
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const handleSubmitStepPersonalInfo = ({
    name,
    surname,
    email,
    pwd,
    confirmPwd,
  }) => {
    let newState = Object.assign({}, formData);
    newState.name = name;
    newState.surname = surname;
    newState.email = email;
    newState.pwd = pwd;
    newState.confirmPwd = confirmPwd;
    setFormData(newState);

    console.log("newState", newState);
    setStep(2);
  };

  const handleSubmitVerification = ({ telefono }) => {
    let newState = Object.assign({}, formData);
    newState.telefono = telefono;
    setFormData(newState);
    setStep(3);
  };

  const handleSubmit = ({ otp }) => {
    if (!formData.name) {
      toast.error(t("error_define_name"));
      return;
    }
    if (!otp) {
      toast.error(t("error_define_otp"));
      return;
    }
    if (!formData.surname) {
      toast.error(t("error_define_surname"));
      return;
    }
    if (!formData.email) {
      toast.error("error_define_email");
      return;
    }
    if (!formData.pwd) {
      toast.error(t("error_define_password"));
      return;
    }
    const phoneNumber = parsePhoneNumber(formData.telefono);
    setLoading(true);

    auth
      .verifyOtp(
        phoneNumber.countryCallingCode,
        phoneNumber.nationalNumber,
        otp,
        formData.email
      )
      .then((response) => {
        setLoading(false);

        if (response.status === 200) {
          if (response.data.response.status === "approved") {
            auth.signUpEmail(
              formData.name,
              formData.surname,
              formData?.telefono,
              formData.email,
              formData.pwd
            );
            let newState = Object.assign({}, doctorRequest);
            newState.modalShow = false;
            setDoctorRequest(newState);
          } else {
            toast.error(t("error_otp_not_approved"));
          }
        }
      })
      .catch((error) => {
        toast.error(t("error_otp_not_approved"));
        setLoading(false);
      });

    return;
  };

  return (
    <Row>
      <Col>
        <h4>{t("user_subscription_form")}</h4>

        {step === 1 && (
          <StepPersonalInfo
            onSubmit={handleSubmitStepPersonalInfo}
          ></StepPersonalInfo>
        )}

        {step === 2 && (
          <StepVerification
            onSubmit={handleSubmitVerification}
          ></StepVerification>
        )}
        {step === 3 && (
          <StepSubmit onSubmit={handleSubmit} loading={loading}></StepSubmit>
        )}
      </Col>
    </Row>
  );
}
