import { CircularProgress, LinearProgress } from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";

export default function CaLoader({ loading, type }) {
  return (
    <>
      {loading && type === "circular" && (
        <div className="center m-4">
          <CircularProgress></CircularProgress>
        </div>
      )}
      {loading && type === "linear" && (
        <div className="center m-4">
          <LinearProgress />
        </div>
      )}
    </>
  );
}

CaLoader.propTypes = {
  loading: PropTypes.bool,
  type: PropTypes.string,
};

CaLoader.defaultProps = {
  loading: false,
  type: "circular",
};
