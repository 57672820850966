import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import CaButton from "../../../../../components/CaButton";
import CaInput from "../../../../../components/CaInput";
import CaGoogleAutocomplete from "../../../../../components/general/CaGoogleAutocomplete";

export default function StepPersonalInfoProfessionist({
  show,
  onSubmit,
  onChange,
  formData,
  disableNext,
}) {
  const { t } = useTranslation();
  const [specificAlert, setSpecificAlert] = useState(false);
  const [selectedPlace, setSelectedPlace] = useState(false);

  useEffect(() => {
    if (selectedPlace) {
      handleSelectPlace(selectedPlace);
    }
  }, [selectedPlace]);

  const handleSelectPlace = (address) => {
    let { lat, lng, geometry } = address;
    if (geometry.location_type == "APPROXIMATE") {
      setSpecificAlert(true);
    } else {
      setSpecificAlert(false);
    }

    console.log("address", address);
    let newState = Object.assign({}, formData?.indirizzo);
    newState.selectedValue = "";
    newState.indirizzo = address.address;
    newState.codicePostale = address?.postal_code;
    newState.citta = address?.city;
    newState.cittaShort = address?.cityShort;
    newState.paese = address?.country;
    newState.paeseShort = address?.countryShort;
    newState.latitude = lat;
    newState.longitude = lng;
    onChange(newState, "indirizzo");
  };
  return (
    <Container style={{ display: !show && "none" }}>
      <Row className="mt-4">
        <Col>
          <CaInput
            className="single-input"
            variant="outlined"
            onChange={(e) => onChange(e, "nome")}
            value={formData.nome}
            label={t("general_name")}
            type="text"
            required
          ></CaInput>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <CaInput
            className="single-input"
            variant="outlined"
            onChange={(e) => onChange(e, "cognome")}
            value={formData.cognome}
            label={t("general_surname")}
            type="text"
            required
          ></CaInput>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col>
          <CaGoogleAutocomplete
            label={t("general_sede_legale")}
            placeholder={"Via Roma n° 4 Siena"}
            onSelectedPlace={(address) => {
              setSelectedPlace(address);
            }}
            value={formData.indirizzo?.address}
            error={specificAlert}
            enabled={true}
          ></CaGoogleAutocomplete>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <CaButton
            className="btn btn-primary"
            text={t("general_next")}
            disabled={disableNext}
            onClick={(e) => {
              e.preventDefault();
              onSubmit();
            }}
          ></CaButton>
        </Col>
      </Row>
    </Container>
  );
}
