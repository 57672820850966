import axios from "axios";
import React, { useContext, useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import CaPaymentForm from "../../../../../components/CaPaymentForm";
import { UserContext } from "../../../../../context/UserContext";

export default function SubscriptionStep3({ type, onClose }) {
  const { user, setUser, token } = useContext(UserContext);
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation();
  const handleSubmit = (paymentMethod, stripeToken) => {
    if (!paymentMethod) return console.error("paymentMethod non presente");
    if (!token) return console.error("idToken non presente");

    var config = {
      method: "post",
      url: process.env.REACT_APP_BASE_URL + "/api/business/subscription",
      headers: {
        "Content-Type": "application/json",
        token: token && token,
      },
      data: {
        userId: user.id,
        user: user,
        paymentMethod: paymentMethod,
        subscriptionType: type,
        token: stripeToken.id,
      },
    };

    setShowModal(false);
    axios(config)
      .then(function (response) {
        onClose(false);
        console.log(response);
        if (response?.data?.success) {
          let newState = Object.assign({}, user);
          newState.partner = true;
          setUser(newState);
          toast.success(t("general_subscribe_success"));
        } else {
          toast.error(response?.data?.message);
        }
      })
      .catch((error) => {
        console.log("errore: ", error);
      });
  };
  return (
    <>
      <Modal.Body>
        <div className="row p-3">
          <div className="col-md-8">
            <div className="mb-3">
              <h5>{type.name}</h5>
              <p>
                {type.description} a {type.unit_amount / 100} € /mese
              </p>
            </div>
          </div>
          <div className="col-md-12">
            <CaPaymentForm
              handleSubmit={(paymentMethod, token) =>
                handleSubmit(paymentMethod, token)
              }
            ></CaPaymentForm>
          </div>
        </div>
      </Modal.Body>
    </>
  );
}
