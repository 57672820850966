import React from "react";

import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  makeStyles,
} from "@material-ui/core";
import CaButton from "./CaButton";

import doctorImage2 from "../img/svg/doctors.svg";

import AccessTimeIcon from "@material-ui/icons/AccessTime";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import MessageIcon from "@material-ui/icons/Message";
import { Rating } from "@material-ui/lab";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  card: {
    display: "flex",
    maxWidth: 600,
    margin: "0 auto",
    marginTop: 20,
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
  },
  media: {
    width: 200,
  },
  content: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    paddingTop: 16,
    paddingLeft: 16,
    paddingRight: 16,
    paddingBottom: "0px !important",
  },
  button: {
    alignSelf: "flex-end",
  },
});

const CaDoctorProposalCard = ({
  doctorName,
  doctorImage,
  visitDate,
  visitHour,
  doctorMessage,
  visitPrice,
  onAcceptClick,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Card className={classes.card}>
      <CardMedia
        className={classes.media + " noMobile"}
        component="img"
        image={doctorImage ? doctorImage : doctorImage2}
        alt={doctorName}
      />
      <CardContent className={classes.content}>
        <div>
          <Typography gutterBottom variant="h5" component="div">
            Dott. {doctorName}
            <Rating name="read-only" value={5} readOnly />
          </Typography>
          <Typography
            variant="body2"
            className="d-flex align-items-center mt-2"
            color="text.secondary"
          >
            <AccessTimeIcon /> <strong>Date:</strong> {visitDate} at {visitHour}
          </Typography>

          <Typography
            variant="body2"
            className="d-flex align-items-center mt-2"
            color="text.secondary"
          >
            <MessageIcon /> <strong>Message:</strong> {doctorMessage}
          </Typography>
          <Typography
            variant="body2"
            className="d-flex align-items-center mt-2 mb-3"
            color="text.secondary"
          >
            <AttachMoneyIcon /> <strong>Price:</strong> {visitPrice}€
          </Typography>
        </div>
        <CaButton
          className={"btn btn-primary"}
          variant="contained"
          onClick={onAcceptClick}
          text={t("general_accept")}
        />
      </CardContent>
    </Card>
  );
};

export default CaDoctorProposalCard;
