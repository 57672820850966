import React, { createContext, useState, useEffect } from "react";

import { useUser } from "../hooks/useUser";
import axios from "axios";
import { mapUserData } from "../utils/Utils";
import { useHistory } from "react-router-dom";
import { useAuthentication } from "../hooks/useAuthentication";
import { toast } from "react-toastify";

export const UserContext = createContext();

export const UserProvider = (props) => {
  const [user, setUser] = useState(null);
  const [practitioner, setPractitioner] = useState(null);
  const [business, setBusiness] = useState(null);
  const [loggedIn, setLoggedIn] = useState(null);
  const [token, setToken] = useState(null);
  const [loadingAuth, setLoadingAuth] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [userType, setUserType] = useState(null);
  const [userTypePersonID, setUserTypePersonID] = useState(null);
  const [userTypeBusinessID, setUserTypeBusinessID] = useState(null);
  const { getUserById, getUserTypeList } = useUser();
  const history = useHistory();
  const { logout } = useAuthentication();

  // se c' è un utente nella localstorage lo metto nello stato
  useEffect(() => {
    let token = localStorage.getItem("token");
    let secret = localStorage.getItem("secret");
    let userID = localStorage.getItem("userID");

    if (token && userID) {
      // setto token come header per tutte le chiamate successive
      axios.defaults.headers.common["token"] = token;
      axios.defaults.headers.common["secret"] = secret;
      apiCallUserID(userID);
    }
  }, []);

  useEffect(() => {
    if (user)
      getUserTypeList()
        .then((res) => {
          console.log("usertypes", res);
          let arr = res.data.response;
          setUserTypePersonID(
            arr.filter((type) => type?.visit_type == "person")[0]?._id
          );
          setUserTypeBusinessID(
            arr.filter((type) => type?.visit_type == "business")[0]?._id
          );
        })
        .catch((e) => toast.error("erro_getting_user_types"));
  }, [user]);

  const apiCallUserID = (userID) => {
    setLoadingAuth(true);
    getUserById(userID)
      .then((response) => {
        console.log("getUserById: ", response);
        setLoadingAuth(false);
        console.log(response);

        let practitioner_info = response?.data?.response?.practitioner_info;
        let business_info = response?.data?.response?.business_info;
        let user_address = response?.data?.response?.user_address;

        setUser(
          mapUserData(
            {
              ...response?.data?.response?.user_info,
              practitioner_info,
              business_info,
              user_address,
            },
            response?.data?.userType
          )
        );

        setPractitioner(practitioner_info);
        setBusiness(business_info);
        setLoggedIn(true);
        // Registriamo la funzione come un interceptor di richiesta globale di Axios
        setToken(token);
        setUserType(response?.data?.userType);
      })
      .catch((error) => {
        console.log("myerror", error);
        logout();
        history.push("/");
        toast.error("error_getting_user");

        setLoadingAuth(false);
      });
  };

  return (
    <UserContext.Provider
      value={{
        user,
        business,
        practitioner,
        setUser,
        loggedIn,
        setLoggedIn,
        token,
        setToken,
        loadingAuth,
        setLoadingAuth,
        userType,
        setUserType,
        notifications,
        setNotifications,
        apiCallUserID,
        userTypePersonID,
        userTypeBusinessID,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};
