import React, { useEffect, useState, useContext } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import CaContainer from "../../../components/CaContainer";
import { useVisits } from "../../../hooks/useVisits";
import CardVisit from "../../../components/profiloComponent/CardVisit";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../../context/UserContext";

export default function PageProfessionistVisits() {
  const { getVisitsList } = useVisits();
  const { user } = useContext(UserContext);
  const [visits, setVisits] = useState([]);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (user) {
      setLoading(true);
      getVisitsList("practitioner", user?.id)
        .then((res) => {
          console.log("visit list", res);
          let arr = res.data.response;
          setVisits(arr);
        })
        .catch((e) => toast.error("erro_getting_user_types"))
        .finally(() => setLoading(false));
    }
  }, [user]);
  return (
    <CaContainer loading={loading} title={t("general_visits_made")}>
      <Row className="mt-4">
        {visits &&
          visits.map((visit) => (
            <Col sm={4} className="mt-2">
              <CardVisit
                visit_type={visit?.visit_type_id?.visit_type}
                visit_address={visit?.visit_address}
                visit_date={visit?.visit_date}
                visit_time={visit?.visit_time}
                visit_price={visit?.visit_price}
                currency_code={visit?.currency_code}
                practitioner_invoice={visit?.practitioner_invoice}
                patient_name={visit?.patient_id?.name}
                patient_surname={visit?.patient_id?.surname}
              ></CardVisit>
            </Col>
          ))}
      </Row>
    </CaContainer>
  );
}
