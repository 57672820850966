import moment from "moment";
import React, { useState } from "react";
import { Card, ListGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import CaButton from "../CaButton";
import CaRequestStatusBadge from "../general/CaRequestStatusBadge";
import ModalProposal from "./ModalProposal";

export default function CardRequest({ request }) {
  const [modalShow, setModalShow] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();
  // Data passata
  const dataPassata = moment(request?.created);

  // Calcola la differenza utilizzando fromNow
  const timeDiff = dataPassata.fromNow();

  const handleGoMenage = () => {
    history.push("/manage-request/" + request?._id);
  };

  return (
    <>
      <Card className="mt-3">
        <Card.Header style={{ position: "relative" }}>
          <Card.Title>{request?.service_id?.service_name}</Card.Title>
          <Card.Subtitle>
            {request?.service_id?.service_description}
          </Card.Subtitle>
          <CaRequestStatusBadge
            status_id={request?.status_id?.status_id}
            style={{ position: "absolute", top: 0, right: 10 }}
            numberOfProposal={request?.proposal_num}
          ></CaRequestStatusBadge>
        </Card.Header>
        <Card.Body className="p-0">
          <ListGroup className="list-group-flush">
            <ListGroup.Item>
              <b>{t("general_time_passed_since_request")}</b> : {timeDiff}
            </ListGroup.Item>
            <ListGroup.Item>
              <b> {t("general_patient_name")}: </b> {request?.patient_name}{" "}
              {request?.patient_surname}
            </ListGroup.Item>
            <ListGroup.Item>
              <b> {t("general_address")}: </b> {request?.address}
            </ListGroup.Item>
            <ListGroup.Item>
              <b>{t("general_requested_visit_date")}</b> :{" "}
              {moment(request.requested_visit_date).format("DD/MM/YYYY")}{" "}
              {request.requested_visit_time}
            </ListGroup.Item>
            <ListGroup.Item>
              <b>{t("general_request_type")}: </b>

              {t(
                "general_request_type_" + request.request_type_id.request_type
              )}
            </ListGroup.Item>
          </ListGroup>
        </Card.Body>

        <Card.Footer>
          <CaButton
            size={"sm"}
            text={t("general_manage_request")}
            onClick={handleGoMenage}
          ></CaButton>
          <CaButton
            size={"sm"}
            variant={"warning"}
            text={
              t("general_see_proposal") + " (" + request?.proposal_num + ")"
            }
            hide={!request?.proposal_num || request?.status_id?.status_id == 5} // nascondi se richiesta terminata
            className={"ms-2"}
            onClick={() => setModalShow(true)}
          ></CaButton>
        </Card.Footer>
      </Card>

      <ModalProposal
        modalShow={modalShow}
        setModalShow={setModalShow}
        requestID={request._id}
      />
    </>
  );
}
