import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import React from "react";
import { hydrate, render } from "react-dom";
import Root from "./App";
import mixpanel from "mixpanel-browser";

if (process.env.REACT_APP_DEBUG === "false") {
  mixpanel.init(process.env.REACT_APP_MIXPANEL_KEY, { debug: false });
} else {
  // se sono in debug non inviare eventi a mixpanel
  mixpanel.init(process.env.REACT_APP_MIXPANEL_KEY, { disable_tracking: true });
}

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(<Root />, rootElement);
} else {
  render(<Root />, rootElement);
}
