import React from "react";
import { useTranslation } from "react-i18next";
import doc from "../../img/svg/doctors.svg";

export default function CaMissingRequest() {
  const { t } = useTranslation();
  return (
    <div className="container">
      <div className="row mt-4">
        <div className="col-12 center">
          <h4 style={{ textAlign: "center" }}>{t("general_no_request_now")}</h4>
        </div>
        <div className="col-12 center">
          <img alt="img" src={doc} style={{ width: 200, height: 200 }}></img>
        </div>
      </div>
    </div>
  );
}
