// useChatLogic.js
import { useEffect, useState, useTransition } from "react";
import ObserveNotification from "../utils/ObserveNotification";
import { useChat } from "./useChat";
import { ChatFeed, Message } from "react-chat-ui";
import moment from "moment";
import { useTranslation } from "react-i18next";

export const useChatLogic = (userID, patientID, role) => {
  const [messages, setMessages] = useState([]);
  const [patient, setPatient] = useState(null);
  const [practitioner, setPractitioner] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [existingConversation, setExistingConversation] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState(false);
  const { createConversation, getConversation, sendMessage } = useChat();
  const { t } = useTranslation();

  useEffect(() => {
    if (patientID && userID) {
      apiCallChatMessages();
      ObserveNotification.subscribe(apiCallChatMessages);
    }
  }, [patientID, userID]);

  const apiCallChatMessages = () => {
    getConversation(userID, patientID)
      .then((res) => {
        console.log("conversation", res);
        if (res?.status === 402) {
          setExistingConversation(false);
        } else {
          setExistingConversation(res?.data?._id);
          setPatient(res?.data.user);
          setPractitioner(res?.data.practitioner);
          if (res?.data?.messages.length === 0) return;
          // se sono presenti...
          let arr = [];

          let myArr = assignSenderIds(res?.data?.messages);
          myArr.map((message) => {
            let newMessage = new Message({
              id: message.id,
              message: message.text,
              senderName: moment(message.timestamp).format("DD/MM/YYYY hh:mm"),
            });
            arr.push(newMessage);
          });

          setMessages(arr);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  function assignSenderIds(messages) {
    const senderIds = {};
    let counter;

    if (role === "professionist") {
      counter = -2;
    }
    if (role === "person") {
      counter = -1;
    }

    const resultArr = [];

    messages.forEach((message) => {
      const { sender } = message;
      if (!(sender in senderIds)) {
        counter = counter + 1;
        senderIds[sender] = counter;
      }
      const newMessage = { ...message, id: senderIds[sender] };
      resultArr.push(newMessage);
    });

    return resultArr;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoadingMessage(true);
    let newMessage = new Message({
      id: 0,
      message: inputValue,
    });
    setMessages([...messages, newMessage]);
    setInputValue("");

    if (inputValue !== "") {
      sendMessage(existingConversation, userID, inputValue)
        .then((response) => {
          console.log("messaggio inviato", response);

          setLoadingMessage(false);
        })
        .catch((e) => {
          setLoadingMessage(false);
        });
    }
  };

  const handleCreateConversation = () => {
    if (existingConversation) return;

    console.log("focus input");

    createConversation(patientID, userID)
      .then((res) => {
        setExistingConversation(res?.data?._id);
        console.log("conversazione creata", res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return {
    messages,
    loadingMessage,
    existingConversation,
    handleSubmit,
    handleCreateConversation,
    patient,
    setInputValue,
    inputValue,
    practitioner,
  };
};
