import React, { useContext } from "react";
import { Col } from "react-bootstrap";
import CaChatFeed from "../../../components/CaChatFeed";
import { UserContext } from "../../../context/UserContext";

const ChatProfessionist = ({ patientID }) => {
  const { user } = useContext(UserContext);

  return (
    <Col>
      <CaChatFeed
        userID1={user?._id}
        userID2={patientID}
        role="professionist"
      ></CaChatFeed>
    </Col>
  );
};

export default ChatProfessionist;
