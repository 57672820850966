import EmailIcon from "@material-ui/icons/Email";
import axios from "axios";
import mixpanel from "mixpanel-browser";
import React, { useContext, useState } from "react";
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import CaButton from "../../../components/CaButton";
import CaInput from "../../../components/CaInput";
import { CompanyContext } from "../../../context/CompanyContext";
import { UserContext } from "../../../context/UserContext";
import { useAuthentication } from "../../../hooks/useAuthentication";

const LoginPage = (props) => {
  const { setUser, setLoggedIn, setToken, setUserType, apiCallUserID } =
    useContext(UserContext);
  const { company } = useContext(CompanyContext);
  const { t } = useTranslation();
  let history = useHistory();

  const [error, setError] = useState(null);
  const [email, setemail] = useState("");
  const [pwd, setpwd] = useState("");
  const { signInEmail } = useAuthentication(
    setUser,
    setLoggedIn,
    setToken,
    setUserType
  );
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    signInEmail(email, pwd)
      .then((response) => {
        mixpanel.track("Login", {
          email: email,
          loginDate: new Date(),
          loginType: "email",
        });
        console.log(response);
        localStorage.setItem("token", response?.data?.token);
        setToken(response.data.token);
        localStorage.setItem("userID", response?.data?.userID);

        document.cookie = "token=" + response?.data?.token;
        // setto l' auth token
        axios.defaults.headers.common["token"] = response?.data?.token;
        axios.defaults.withCredentials = true;

        apiCallUserID(response?.data?.userID);

        // window.location.reload();
      })
      .catch((e) => {
        console.log(e);
        if (e?.response?.data?.message) {
          console.log(e.response.data.message);
          toast.error(e.response.data.message);
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <Container style={{ height: "100vh" }} fluid>
      <Row className="center h-100">
        <Col md={6} lg={4}>
          <Card>
            <Row className="center">
              <Col md={6} sm={6} className="center">
                <Card.Img
                  src={company?.configuration?.logo}
                  style={{ maxWidth: 200 }}
                ></Card.Img>
              </Col>
            </Row>
            <Card.Body>
              <Form>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <CaInput
                    className="single-input"
                    variant="outlined"
                    /* value={email} */
                    onChange={(value) => setemail(value)}
                    label={t("general_email")}
                    type="email"
                    required
                    iconStartComponent={<EmailIcon></EmailIcon>}
                  ></CaInput>
                  <Form.Text className="text-muted">
                    {t("general_password_disclaimer")}
                  </Form.Text>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <CaInput
                    className="single-input"
                    variant="outlined"
                    /* value={pwd} */
                    onChange={(value) => setpwd(value)}
                    label={t("general_password")}
                    type="password"
                    required
                  ></CaInput>
                </Form.Group>
                <Form.Group className="mb-3">
                  <CaButton
                    variant="primary"
                    onClick={handleSubmit}
                    text={t("general_submit")}
                    isLoading={loading}
                  ></CaButton>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <CaButton
                    onClick={(e) => {
                      e.preventDefault();
                      history.push("/signup");
                    }}
                    variant="link"
                    text={t("general_create_account")}
                  ></CaButton>
                  <CaButton
                    onClick={() => history.push("/reset-password")}
                    variant="link"
                    text={t("general_reset_password")}
                  ></CaButton>
                </Form.Group>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default LoginPage;
