import React, { useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import CaButton from "../../../../components/CaButton";
import CaContainer from "../../../../components/CaContainer";
import { UserContext } from "../../../../context/UserContext";
import HorizontalLinearStepperBusiness from "../../../business/request/normalRequest/HorizontalLinearStepperBusiness";
import { useTranslation } from "react-i18next";

export default function PageBusinessRequest(props) {
  const history = useHistory();
  const { user, setUser } = useContext(UserContext);
  const { t } = useTranslation();
  return (
    <CaContainer>
      <Row className="noMobile">
        <Col>
          <CaButton
            text={t("general_back_home")}
            onClick={() => history.push("/")}
            icon="fa fa-arrow-left"
            variant="link"
            className="btn btn-link"
          ></CaButton>
        </Col>
      </Row>

      <HorizontalLinearStepperBusiness></HorizontalLinearStepperBusiness>
    </CaContainer>
  );
}
