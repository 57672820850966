import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import useProfessionRequests from "../hooks/professionist/useProfessionRequests";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const withRequestData = (WrappedComponent) => {
  return function WithApiData(props) {
    const { getSingleRequest } = useProfessionRequests();
    const [richiesta, setRichiesta] = useState(null);
    const [proposte, setProposte] = useState([]);
    const [loading, setLoading] = useState(null);
    const [doctor, setDoctor] = useState(null);
    const [proposta, setProposta] = useState(null);
    const { t } = useTranslation();
    const { requestID } = useParams();

    useEffect(() => {
      // get single request
      getRequest();
    }, [requestID]);

    const getRequest = () => {
      setLoading(true);
      getSingleRequest(requestID)
        .then((res) => {
          setLoading(false);
          setRichiesta(res.data?.response?.visit_request);
          setProposte(res.data?.response?.visit_proposals);
          // trova la proposta accettata
          let propostaAccettata = res.data?.response?.visit_proposals.filter(
            (proposal) => proposal?.status_id.status_id === 1
          );

          if (propostaAccettata.length > 0) {
            setDoctor(propostaAccettata[0]?.user_id);
            setProposta(propostaAccettata[0]);
          }
        })
        .catch((err) => {
          setLoading(false);
          console.error("errore", err);
          toast.error(t("error_while_getting_request"));
        });
    };

    return (
      <WrappedComponent
        request={richiesta}
        requestID={requestID}
        proposal={proposta}
        proposalList={proposte}
        doctor={doctor}
        loading={loading}
        {...props}
      />
    );
  };
};

export default withRequestData;
