import React, { useContext } from "react";
import { Route } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import PageProfessionistCheckoutRequest from "../pages/prosessionists/checkOut/PageProfessionistCheckoutRequest";
import PageProfessionistDashboard from "../pages/prosessionists/dashboard/PageProfessionistsDashboard";
import PageEditProfessionistProfile from "../pages/prosessionists/editProfile/PageEditProfessionistProfile";
import PageProfessionistHistory from "../pages/prosessionists/history/PageProfessionistHistory";
import PageProfessionistManageRequest from "../pages/prosessionists/manageRequest/PageProfessionistManageRequest";
import PageProfessionistProfile from "../pages/prosessionists/profile/PageProfessionistProfile";
import PageProfessionistManageAppointment from "../pages/prosessionists/manageAppointment/PageProfessionistManageAppointment";
import PageProfessionistAppointment from "../pages/prosessionists/manageAppointment/PageProfessionistAppointment";
import PageProfessionistVisits from "../pages/prosessionists/visits/PageProfessionistVisits";
import PageProfessionistChat from "../pages/prosessionists/chat/PageProfessionistChat";
import PageProfessionistConversations from "../pages/prosessionists/chat/PageProfessionistConversations";

const ProfessionistsRoutes = (props) => {
  const { user } = useContext(UserContext);

  return (
    <>
      <Route
        exact
        path="/"
        render={() => (
          <PageProfessionistDashboard {...user}></PageProfessionistDashboard>
        )}
      />
      <Route
        exact
        path="/account"
        render={() => <PageProfessionistProfile></PageProfessionistProfile>}
      />
      <Route
        exact
        path="/account-edit"
        render={() => (
          <PageEditProfessionistProfile></PageEditProfessionistProfile>
        )}
      />
      <Route
        exact
        path="/history"
        render={() => <PageProfessionistHistory></PageProfessionistHistory>}
      />

      <Route
        exact
        path="/manage-request/:requestID"
        render={() => (
          <PageProfessionistManageRequest></PageProfessionistManageRequest>
        )}
      />
      <Route
        exact
        path="/manage-appointment/:requestID"
        render={() => (
          <PageProfessionistManageAppointment></PageProfessionistManageAppointment>
        )}
      />
      <Route
        exact
        path="/appointment"
        render={() => (
          <PageProfessionistAppointment></PageProfessionistAppointment>
        )}
      />

      <Route
        exact
        path="/visits"
        render={() => <PageProfessionistVisits></PageProfessionistVisits>}
      />
      <Route
        exact
        path="/checkout-request/:requestID"
        render={() => (
          <PageProfessionistCheckoutRequest></PageProfessionistCheckoutRequest>
        )}
      />

      <Route
        exact
        path="/chat/:patientID"
        render={() => <PageProfessionistChat></PageProfessionistChat>}
      />
      <Route
        exact
        path="/conversations"
        render={() => (
          <PageProfessionistConversations></PageProfessionistConversations>
        )}
      />
    </>
  );
};

export default ProfessionistsRoutes;
