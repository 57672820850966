import { useContext, useEffect, useState } from "react";

import mixpanel from "mixpanel-browser";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { useUser } from "./useUser.js";
import { toast } from "react-toastify";
import { CompanyContext } from "../context/CompanyContext.js";

export function useAuthentication(setUser, setLoggedIn, setToken, setUserType) {
  const [authenticated, setAuthenticated] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { company } = useContext(CompanyContext);

  const [loadingAuth, setLoadingAuth] = useState(true);

  let history = useHistory();

  const handleUserRegistration = (name, surname, telefono, email, password) => {
    if (password.length < 6)
      return toast.error("La password deve essere di almeno 6 caratteri");

    let userData = {
      name: name,
      surname: surname,
      user_email: email,
      password: password,
      telefono: telefono,
      codiceFiscale: "",
      companyID: company?._id,
      privacy: true,
      termsCondition: true,
    };
    var config = {
      method: "post",
      url: process.env.REACT_APP_BASE_URL + "/api/auth/register/user",
      headers: {
        "Content-Type": "application/json",
      },
      data: userData,
    };

    return axios(config);
  };

  function logout() {
    history.push("/");
    localStorage.removeItem("token");
    localStorage.removeItem("userID");
    localStorage.removeItem("type");

    window.location.reload();
  }
  function signInEmail(email, pwd) {
    setLoadingAuth(true);
    var axios = require("axios");
    var data = JSON.stringify({
      user_email: email,
      password: pwd,
    });

    var config = {
      method: "post",
      url: process.env.REACT_APP_BASE_URL + "/api/auth/login",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    return axios(config);
  }

  function signUpEmail(name, surname, telefono, email, pwd) {
    setLoadingAuth(true);

    handleUserRegistration(name, surname, telefono, email, pwd)
      .then((res) => {
        setLoadingAuth(false);
        history.push("/login");
        mixpanel.register("Sign Up", {
          email: email,
          registerDate: new Date(),
          registerType: "normal user",
        });
        mixpanel.alias(email);
        mixpanel.identify(email);
        mixpanel.people.set({
          $email: email,
          $name: name,
          $Type: "person",
        });
        window.Reflio.signup(email);
      })
      .catch((error) => {
        var errorMessage = error?.response?.data?.message;
        toast.error(errorMessage);
      });
  }

  function signUpEmailBusiness(businessReg) {
    if (businessReg.password.length < 6)
      return alert("La password deve essere di almeno 6 caratteri");

    let data = {
      name: businessReg.cognomeLegaleRappresentante,
      surname: businessReg.nomeLegaleRappresentante,
      user_email: businessReg.email,
      password: businessReg.password,
      telefono: businessReg.telefono,
      codiceFiscale: businessReg.codiceFiscale,
      //"statusID": "659563c1c28c7bbbf3ec3f5e",
      companyID: company?._id,
      piva: businessReg.piva,
      privacy: businessReg?.privacy,
      termsCondition: businessReg?.termsCondition,

      business_email: businessReg.pec,
      businessName: businessReg.nomeSocieta,
      businessPIVA: businessReg.piva,
      businessType: businessReg.type,
      registroDelleImprese: businessReg.registroDelleImprese,
      pec: businessReg.pec,
      image: window.location.origin + "/assets/img/svg-icons/home.svg",

      indirizzo: businessReg.indirizzo,
      citta: businessReg.citta,
      cittaShort: businessReg.cittaShort,
      codicePostale: businessReg.codicePostale,
      paese: businessReg.paese,
      paeseShort: businessReg.paeseShort,
      latitude: businessReg.latitude,
      longitude: businessReg.longitude,
      sedeLegale: businessReg.sedeLegale,
    };

    var config = {
      method: "post",
      url: process.env.REACT_APP_BASE_URL + "/api/auth/register/business",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    return axios(config);
  }

  const resetPasswordEmail = (email) => {
    let data = JSON.stringify({
      email: email,
    });

    let config = {
      method: "post",
      url: process.env.REACT_APP_BASE_URL + "/api/auth/reset-password-email",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    return axios(config);
  };
  const resetPasswordForm = (token, pwd) => {
    if (!token) return toast.error("Manca il token");
    if (!pwd) return toast.error("Manca la pwd");

    let data = JSON.stringify({
      password: pwd,
    });

    let config = {
      method: "put",
      url: process.env.REACT_APP_BASE_URL + "/api/auth/reset-password/" + token,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    return axios(config);
  };

  const sendOtp = (countryCode, phoneNumber) => {
    let data = JSON.stringify({
      countryCode: countryCode,
      phoneNumber: phoneNumber,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: process.env.REACT_APP_BASE_URL + "/api/otp/send-otp",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    return axios(config);
  };
  const verifyOtp = (countryCode, phoneNumber, otp, email) => {
    let data = JSON.stringify({
      countryCode: countryCode,
      phoneNumber: phoneNumber,
      otp: otp,
      email: email,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: process.env.REACT_APP_BASE_URL + "/api/otp/verify-otp",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    return axios(config);
  };

  return {
    logout,
    loggedIn: authenticated,
    signInEmail,
    signUpEmail,
    signUpEmailBusiness,
    error,
    errorMessage,
    loadingAuth,
    setLoadingAuth,
    resetPasswordEmail,
    resetPasswordForm,
    sendOtp,
    verifyOtp,
  };
}
