import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import CaButton from "../../../../../components/CaButton";
import CaInput from "../../../../../components/CaInput";
import CaSelect from "../../../../../components/CaSelect";
import useProfessions from "../../../../../hooks/useProfession";
import {
  validCodiceFiscale,
  validPartitaIva,
} from "../../../../../utils/Utils";

export default function StepProfessionalInfoProfessionist({
  show,
  onSubmit,
  onGoBack,
  onChange,
  formData,
  disableNext,
}) {
  const { t } = useTranslation();
  const [professionList, setProfessionList] = useState([]);

  const { getProfessions } = useProfessions();

  useEffect(() => {
    if (show)
      getProfessions()
        .then((res) => {
          if (res.status === 200) {
            setProfessionList(res.data.response);
          }
        })
        .catch((e) => {
          toast.error(t("error_unable_to_recover_professionists"));
        });
  }, [show]);

  let findSelected = () => {
    return professionList.filter((p) => p._id == formData.professione);
  };

  return (
    <Container style={{ display: !show && "none" }}>
      <Row className="mt-4">
        <Col>
          <CaSelect
            placeholder={t("general_select_profession")}
            options={professionList}
            getOptionLabel={(option) =>
              t("general_profession_" + option.profession)
            }
            selected={findSelected()}
            getOptionValue={(option) => option._id}
            onChange={(v) => {
              if (v) {
                onChange(v?._id, "professione");
                return;
              }
              onChange(null, "professione");
            }}
            required
          ></CaSelect>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <CaInput
            className="single-input"
            error={!validPartitaIva(formData?.piva)}
            type="number"
            variant="outlined"
            value={formData?.piva}
            onChange={(e) => onChange(e, "piva")}
            label={t("general_piva")}
            required
          ></CaInput>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <CaInput
            className="single-input"
            error={!validCodiceFiscale(formData?.codiceFiscale)}
            variant="outlined"
            onChange={(e) => onChange(e, "codiceFiscale")}
            label={t("general_fiscal_code")}
            type="text"
            required
          ></CaInput>
        </Col>
        <Row className="mt-4">
          <Col>
            <CaButton
              text={t("general_back")}
              variant={"outline"}
              onClick={(e) => {
                e.preventDefault();
                onGoBack();
              }}
            ></CaButton>
          </Col>
          <Col>
            <CaButton
              text={t("general_next")}
              disabled={
                !validCodiceFiscale(formData?.codiceFiscale) ||
                !validPartitaIva(formData?.piva) ||
                !formData?.professione
              }
              onClick={(e) => {
                e.preventDefault();
                onSubmit(
                  formData?.professione,
                  formData?.piva,
                  formData?.codiceFiscale
                );
              }}
            ></CaButton>
          </Col>
        </Row>
      </Row>
    </Container>
  );
}
