import React, { useState } from "react";
import PropTypes from "prop-types";

export default function CaBadge({
  title,
  variant,
  className,
  children,
  onClear,
  isClearable,
  style,
}) {
  const [buttonHover, setButtonHover] = useState(false);
  return (
    <span className={"badge bg-" + variant + " " + className} style={style}>
      {title}
      {children}
      {isClearable && (
        <span
          className="ms-2"
          onClick={onClear}
          onMouseEnter={() => {
            setButtonHover(true);
          }}
          onMouseLeave={() => {
            setButtonHover(false);
          }}
          style={{
            cursor: buttonHover ? "pointer" : "initial",
            position: "relative",
            top: "1px",
          }}
        >
          <i className="fa fa-times"></i>
        </span>
      )}
    </span>
  );
}

CaBadge.propTypes = {
  variant: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
};

CaBadge.defaultProps = {
  variant: "primary",
  className: "",
  title: null,
};
