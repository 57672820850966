import React, { useContext, useEffect, useState } from "react";

import InputAdornment from "@material-ui/core/InputAdornment";
import EmailIcon from "@material-ui/icons/Email";
import PersonIcon from "@material-ui/icons/Person";
import "react-phone-number-input/style.css";

import { TextField } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-number-input";
import { DoctorRequestContext } from "../../../../context/DoctorRequestContext";
import { isValidEmail } from "../../../../utils/Utils";

export default function StepOne(props) {
  const { doctorRequest, setDoctorRequest } = useContext(DoctorRequestContext);
  const [clock, setClock] = useState();
  const [validEmail, setValidEmail] = useState(true);
  const [validAge, setValidAge] = useState(true);
  const { t } = useTranslation();
  useEffect(() => {
    let data = new Date();
    let ora = data.getHours();
    let minuti = data.getMinutes();

    let orass = ora + ":" + minuti;

    setClock(orass);
  }, []);

  const nameChange = (e) => {
    let newState = Object.assign({}, doctorRequest);
    newState.name = e.target.value;
    setDoctorRequest(newState);
  };

  const emailChange = (e) => {
    let newState = Object.assign({}, doctorRequest);
    newState.email = e.target.value;
    handleValidEmail(e.target.value);
    setDoctorRequest(newState);
  };
  const phoneChange = (e) => {
    let newState = Object.assign({}, doctorRequest);

    newState.phone = e;
    setDoctorRequest(newState);
  };

  const ageChange = (e) => {
    let newState = Object.assign({}, doctorRequest);
    newState.age = e.target.value;
    handleValidAge(e.target.value);
    setDoctorRequest(newState);
  };

  const handleValidEmail = (e) => {
    if (isValidEmail(e)) {
      setValidEmail(true);
    }
    if (!isValidEmail(e)) {
      setValidEmail(false);
    }
  };
  const handleValidAge = (e) => {
    if (e < 140) {
      setValidAge(true);
    }
    if (e > 140) {
      setValidAge(false);
    }
  };

  return (
    <div className="row ">
      <div className="col-lg-12 center">
        <div className="riyaqas-form-wrap  mt-lg-0">
          <div className="row custom-gutters-16">
            <div className="col-sm-6">
              <div className="marginForm">
                <p>{t("general_patient_name")}</p>
                <TextField
                  className="single-input"
                  error={
                    doctorRequest.name == ""
                      ? doctorRequest.error
                        ? true
                        : false
                      : null
                  }
                  helperText={
                    doctorRequest.name == ""
                      ? doctorRequest.error
                        ? "Required"
                        : null
                      : null
                  }
                  variant="outlined"
                  value={doctorRequest.name}
                  onChange={(e) => nameChange(e)}
                  label={t("general_patient_name")}
                  type="text"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PersonIcon />
                      </InputAdornment>
                    ),
                  }}
                  required
                ></TextField>
              </div>
            </div>

            <div className="col-sm-6">
              <div className="marginForm">
                <p>{t("general_email")}</p>
                <TextField
                  className="single-input"
                  error={!validEmail}
                  helperText={
                    doctorRequest.email == ""
                      ? doctorRequest.error
                        ? "Required"
                        : null
                      : null
                  }
                  variant="outlined"
                  value={doctorRequest.email}
                  onChange={(e) => emailChange(e)}
                  label="Email"
                  type="email"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EmailIcon />
                      </InputAdornment>
                    ),
                  }}
                  required
                ></TextField>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="marginForm">
                <PhoneInput
                  style={{
                    width: "100%",
                    fontSize: "1rem",
                    fontWeight: 400,
                    lineHeight: 1.5,
                    padding: 10,
                    border: "1px solid lightgray",
                    borderRadius: 5,
                  }}
                  defaultCountry="IT"
                  value={doctorRequest.phone}
                  onChange={(e) => phoneChange(e)}
                  rules={{ required: true }}
                ></PhoneInput>
              </div>
            </div>

            <div className="col-sm-6">
              <div className="marginForm">
                <TextField
                  type="number"
                  error={!validAge}
                  helperText={
                    doctorRequest.age == ""
                      ? doctorRequest.error
                        ? "Required"
                        : null
                      : null
                  }
                  required
                  label={t("general_age")}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PersonIcon />
                      </InputAdornment>
                    ),
                    inputProps: {
                      min: 0,
                      max: 100,
                    },
                  }}
                  value={doctorRequest.age}
                  onChange={(e) => ageChange(e)}
                  variant="outlined"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
