import React, { useEffect, useState } from "react";
/**
 * QuillForms Depndencies
 */
import { useTheme, useMessages } from "@quillforms/renderer-core";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";

const PhoneNumber = (props) => {
  const {
    id,
    attributes,
    setIsValid,
    setIsAnswered,
    setValidationErr,
    isActive,
    val,
    setVal,
    next,
  } = props;

  const { required } = attributes;
  const messages = useMessages();
  const theme = useTheme();
  useEffect(() => {
    setVal(new Date());
    setIsValid(true);
  }, []);

  useEffect(() => {
    console.log("VAL", val);

    if (val && typeof val === "string") {
      if (!isValidPhoneNumber(val)) {
        setIsValid(false);
        setIsAnswered(false);
        setValidationErr(messages["label.errorAlert.required"]);
      }
      if (isValidPhoneNumber(val)) {
        setIsValid(true);
        setIsAnswered(true);
        setValidationErr(null);
      }
    } else {
      setIsValid(false);
      setIsAnswered(false);
      setValidationErr(null);
    }
  }, [val]);

  return (
    <PhoneInput
      style={{
        width: "100%",
        fontSize: "1rem",
        fontWeight: 400,
        lineHeight: 1.5,
        padding: 10,
        border: "1px solid lightgray",
        borderRadius: 5,
        display: "flex",
      }}
      defaultCountry="IT"
      selected={val}
      onChange={(value) => {
        if (value) {
          setVal(value);
        }
        if (!value) {
          setVal(null);
        }
      }}
      className="form-control"
    />
  );
};
export default PhoneNumber;
