import axios from "axios";
import ObserveNotification from "../utils/ObserveNotification";
// una lista di tutti i commenti

export function useNotification() {
  const baseURL = process.env.REACT_APP_BASE_URL;

  const listenNotifications = (userID) => {
    let token = localStorage.getItem("token");
    let secret = localStorage.getItem("secret");
    const eventSource = new EventSource(
      baseURL + "/api/notification/stream/" + userID,
      {
        headers: {
          // Add your headers here
          secret: secret, // Replace with your actual access token
          token: token, // Replace with your actual access token
          // Other headers if needed
        },
      }
    );

    console.log("listenNotifications : ", userID);

    eventSource.addEventListener("message", function (event) {
      let notification = JSON.parse(event.data);

      console.log("Evento notifica", event.data);

      ObserveNotification.notify(notification);

      // do something with the event data, e.g. update the UI
    });
  };

  const getNotifications = (userID) => {
    if (!userID) return console.error("Specificare user ID");
    var config = {
      method: "get",
      url: baseURL + "/api/notification/user/" + userID,
      headers: {},
    };

    return axios(config);
  };

  const readAllNotifications = (userID) => {
    if (!userID) return console.error("Specificare user ID");
    var config = {
      method: "delete",
      url: baseURL + "/api/notification/user/" + userID,
    };

    return axios(config);
  };

  return { listenNotifications, getNotifications, readAllNotifications };
}
