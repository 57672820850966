import { FormControl, InputLabel, Select, TextField } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";

import CaInput from "../../../../../components/CaInput";
import { BusinessRegContext } from "../../../../../context/BusinessRegContext";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useBusiness } from "../../../../../hooks/useBusiness";
import CaSelect from "../../../../../components/CaSelect";

export default function StepThreeBusinessReg() {
  const { businessReg, setBusinessReg } = useContext(BusinessRegContext);
  const { t } = useTranslation();
  const handleChange = (value, target) => {
    let newState = Object.assign({}, businessReg);
    newState[target] = value;
    setBusinessReg(newState);
  };
  const [businessType, setBusinessType] = useState([]);

  const { getBusinessType } = useBusiness();

  useEffect(() => {
    getBusinessType()
      .then((res) => {
        if (res.status === 200) {
          setBusinessType(res.data.response);
        }
      })
      .catch((e) => {
        toast.error(t("general_error_data_recovering"));
      });
  }, []);

  return (
    <>
      <Row>
        <Col className="center flex-column">
          <FormControl variant="outlined" className="single-input">
            <InputLabel htmlFor="outlined-age-native-simple">
              {t("general_business_type")}
            </InputLabel>
            <CaSelect
              placeholder={t("general_select_business_type")}
              options={businessType}
              getOptionLabel={(option) =>
                t("general_business_type_" + option.type_id)
              }
              getOptionValue={(option) => option._id}
              onChange={(option) => {
                if (option) {
                  handleChange(option?._id, "type");
                  return;
                }
                handleChange(null, "type");
              }}
              required
            ></CaSelect>
          </FormControl>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col>
          <CaInput
            className="single-input"
            variant="outlined"
            value={businessReg.email}
            onChange={(e) => handleChange(e, "email")}
            label={t("general_email")}
            type="email"
            required
          ></CaInput>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col>
          <CaInput
            className="single-input"
            variant="outlined"
            value={businessReg.password}
            /* value={pwd} */
            onChange={(e) => handleChange(e, "password")}
            label={t("general_password")}
            type="password"
            required
          ></CaInput>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <CaInput
            className="single-input"
            variant="outlined"
            value={businessReg.passwordConfirm}
            /* value={pwd} */
            onChange={(e) => handleChange(e, "passwordConfirm")}
            label={t("general_password_confirm")}
            type="password"
            error={businessReg.password !== businessReg.passwordConfirm}
            required
          ></CaInput>
        </Col>
      </Row>
    </>
  );
}
