import React, { useContext, useState } from "react";

import Radio from "@material-ui/core/Radio";

import { DoctorRequestContext } from "../../context/DoctorRequestContext";

import { useTranslation } from "react-i18next";
import { useRequest } from "../../hooks/useRequest";
import CaAddressFinder from "./CaAddressFinder";
import CaGoogleAutocomplete from "./CaGoogleAutocomplete";

export default function LocationHandlerV2(props) {
  const [specificAlert, setSpecificAlert] = useState(false);
  const { doctorRequest, setDoctorRequest } = useContext(DoctorRequestContext);
  const [address, setAddress] = useState(doctorRequest?.address);
  const { areWeActiveInSelectedZone } = useRequest();
  const { t } = useTranslation();

  const handleSelectPlace = (selectedZone) => {
    // fai una chiamata a BE che ti dice se ci sono o no professionisti in zona.
    handleSearch(
      selectedZone.lat,
      selectedZone.lng,
      doctorRequest?.doctorType,
      selectedZone.address
    );
  };

  const handleSearch = (lat, lng, role, address) => {
    setAddress(address);
    areWeActiveInSelectedZone(lat, lng, role)
      .then((res) => {
        console.log(res);
        if (res.data.result) {
          let newState = Object.assign({}, doctorRequest);

          newState.selectedRadioColor = "green";
          newState.address = address;
          newState.latitude = lat;
          newState.longitude = lng;
          setDoctorRequest(newState);
        }
        if (!res.data.result) {
          let newState = Object.assign({}, doctorRequest);
          newState.selectedRadioColor = "red";
          newState.address = address;
          newState.latitude = lat;
          newState.longitude = lng;

          setDoctorRequest(newState);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="row">
      <div className="col-12">
        <h4 className=" mt-4 mb-4">{t("stepper_geolocate_title")}</h4>
        {specificAlert ? (
          <p className=" text-danger">
            {t("alert_geolocation_must_be_specific")}
          </p>
        ) : (
          ""
        )}
      </div>

      <div className="col-12 mt-4 mb-4">
        <div className="with-icon">
          <CaGoogleAutocomplete
            country="IT"
            value={address}
            onSelectedPlace={(selectedZone) => handleSelectPlace(selectedZone)}
            types={["address"]}
            onChange={(val) => {
              setAddress(val);
            }}
            label={t("general_address")}
            enabled={!doctorRequest.businessID}
          ></CaGoogleAutocomplete>
        </div>
        <CaAddressFinder
          handleSelectPlace={(data) => handleSelectPlace(data)}
          disabled={address}
        ></CaAddressFinder>
        <h4 className="text-center">{t("we_are_active_in_zone")}</h4>

        {doctorRequest.selectedRadioColor === "green" && (
          <p>{t("we_are_active_in_zone_comment")}👍</p>
        )}
        {doctorRequest.selectedRadioColor === "red" && (
          <div>
            <p>{t("we_are_not_active_in_zone")}🚨</p>
            <p>
              {t("contact_us_for_help")}{" "}
              <a href="mailto:info@caducea.it">{t("general_contact_us")}</a>
            </p>
          </div>
        )}

        <Radio
          checked={doctorRequest.selectedRadioColor === "green"}
          color="default"
          /*  onChange={handleChange} */
          style={{
            color: doctorRequest.selectedRadioColor,
            transform: "scale(2)",
          }}
          value="c"
          name="radio-button-demo"
          inputProps={{ "aria-label": "C" }}
          required
        />
      </div>
    </div>
  );
}
