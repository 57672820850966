import axios from "axios";
import { toast } from "react-toastify";
export default function useProfessionRequests() {
  const baseURL = process.env.REACT_APP_BASE_URL;
  /**
   * Restituisce le richieste nella zona di azione del dottore
   * @param {int} raggio raggio di azione del dottore
   * @param {float} latitude coordinate doc
   * @param {float} longitude coordinate doc
   * @returns
   */
  const getActiveRequests = (raggio, latitude, longitude) => {
    if (!raggio || !latitude || !longitude)
      return toast.error(
        "Errore",
        "Impossibile recuperare le richieste di assistenza"
      );
    var data = {
      raggio: raggio,
      latitude: latitude,
      longitude: longitude,
    };
    var config = {
      method: "post",
      url: baseURL + "/api/requests/richieste-nelle-vicinanze",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    return axios(config);
  };

  const getDoctorRequest = async (doctorID) => {
    let config = {
      method: "get",
      url: baseURL + "/api/requests/doctor/" + doctorID,
      headers: {},
    };

    return axios(config);
  };

  /**
   * Gestisce l' invio della proposta lato BE
   * @param {Object} proposal=> Oggetto della proposta
   */
  const sendNewProposal = async (proposal) => {
    var config = {
      method: "post",
      url: baseURL + "/api/requests/add-proposal",
      headers: {
        "Content-Type": "application/json",
      },
      data: proposal,
    };

    return axios(config);
  };
  const getSingleRequest = async (requestID) => {
    var config = {
      method: "get",
      url: baseURL + "/api/requests/person/" + requestID + "/details",
    };

    return axios(config);
  };

  const getSingleProposal = async (proposalID) => {
    var config = {
      method: "get",
      url: baseURL + "/api/proposals/person/" + proposalID + "/details",
    };

    return axios(config);
  };

  const annullaVisita = async (requestID) => {
    if (!requestID) return;
    var data = {
      requestID: requestID,
    };

    var config = {
      method: "post",
      url: baseURL + "/api/requests/annul-visit",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    return axios(config);
  };

  return {
    getActiveRequests,
    getSingleRequest,
    sendNewProposal,
    annullaVisita,
    getDoctorRequest,
    getSingleProposal,
  };
}
