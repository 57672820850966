import axios from "axios";

export function useVisits() {
  const baseURL = process.env.REACT_APP_BASE_URL;
  // ottieni il singolo utente

  const createVisit = (data) => {
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: baseURL + "/api/visits/create",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    };

    return axios.request(config);
  };

  const getVisitsList = (type, userID) => {
    var config = {
      method: "get",
      url: baseURL + "/api/visits/"+type+"/"+userID,
    };
    return axios(config);
  };

  return {
    createVisit,
    getVisitsList,
  };
}
