import EmailIcon from "@material-ui/icons/Email";
import PersonIcon from "@material-ui/icons/Person";
import React, { useState } from "react";

import { isValidEmail } from "../../../../../utils/Utils";
import { useHistory } from "react-router-dom";
import CaInput from "../../../../../components/CaInput";
import CaButton from "../../../../../components/CaButton";
import { useTranslation } from "react-i18next";

export default function StepPersonalInfo({ onSubmit }) {
  const [name, setname] = useState("");
  const [surname, setsurname] = useState("");
  const [email, setemail] = useState("");
  const [pwd, setpwd] = useState("");
  const [confirmPwd, setConfirmPwd] = useState("");
  const { t } = useTranslation();

  let history = useHistory();
  return (
    <>
      <div className="col-sm-12 m-2 ">
        <CaInput
          className="single-input"
          variant="outlined"
          onChange={(e) => setname(e)}
          label={t("general_name")}
          type="text"
          required
          iconStartComponent={<PersonIcon></PersonIcon>}
        ></CaInput>
      </div>
      <div className="col-sm-12 m-2 ">
        <CaInput
          className="single-input"
          variant="outlined"
          onChange={(e) => setsurname(e)}
          label={t("general_surname")}
          iconStartComponent={<PersonIcon></PersonIcon>}
          type="text"
          required
        ></CaInput>
      </div>
      <div className="col-sm-12 m-2">
        <CaInput
          className="single-input"
          variant="outlined"
          onChange={(value) => setemail(value)}
          label={t("general_email")}
          iconStartComponent={<EmailIcon></EmailIcon>}
          type="email"
          required
        ></CaInput>
      </div>
      <div className="col-sm-12 m-2">
        <CaInput
          className="single-input"
          variant="outlined"
          onChange={(value) => setpwd(value)}
          label={t("general_password")}
          type="password"
          required
        ></CaInput>
      </div>
      <div className="col-sm-12 m-2">
        <CaInput
          className="single-input"
          variant="outlined"
          value={confirmPwd}
          onChange={(value) => setConfirmPwd(value)}
          label={t("general_confirm_password")}
          type="password"
          error={pwd !== confirmPwd}
          required
        ></CaInput>
      </div>
      <div className="col-sm-12 m-2">
        <CaButton
          className="btn btn-primary"
          text={t("general_next")}
          disabled={
            !name ||
            !surname ||
            !isValidEmail(email) ||
            !pwd ||
            !confirmPwd ||
            pwd !== confirmPwd
          }
          onClick={(e) => {
            e.preventDefault();
            onSubmit({ name, surname, email, pwd, confirmPwd });
          }}
        ></CaButton>
      </div>
      <div class="col-12 center">
        <small>{t("already_have_account")} ? </small>
        <CaButton
          variant="link"
          onClick={() => history.push("/login")}
          text={t("general_login")}
        ></CaButton>
      </div>
    </>
  );
}
