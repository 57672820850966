import moment from "moment";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import CaCardDetails from "../../../components/CaCardDetails";
import CaVisitCard from "../../../components/CaVisitCard";
import CaContainer from "../../../components/CaContainer";
import withRequestData from "../../../hoc/withRequestData";

function PageUserManageAppointment({
  request,
  proposal,
  proposalList,
  doctor,
  loading,
}) {
  const { t } = useTranslation();

  console.log("proposal: ", proposal);
  return (
    <CaContainer backButton={"/appointment"}>
      <Row>
        <Col md={6} sm={12}>
          <CaCardDetails
            title={t("general_appointment_details")}
            list={[
              {
                label: t("general_service_name"),
                value: request?.service_id?.service_name,
                show: true,
              },
              {
                label: t("general_patient_name"),
                value: request?.patient_name + " " + request?.patient_surname,
                show: true,
              },
              {
                label: t("general_address"),
                value: request?.address,
                show: true,
              },
              {
                label: t("general_date"),
                value:
                  moment(proposal?.proposed_visit_date).format("DD/MM/YYYY") +
                  " " +
                  proposal?.proposed_visit_time,
                show: true,
              },
              {
                label: t("general_estimated_price"),
                value: proposal?.proposed_price + " €",
                show: true,
              },
            ]}
            actions={[]}
          ></CaCardDetails>
        </Col>
        <Col md={6} sm={12}>
          <CaVisitCard
            image={doctor?.image_url}
            name={doctor?.name}
            surname={doctor?.surname}
            practitionerID={doctor?._id}
            role={null}
            latitude={request?.latitude}
            longitude={request?.longitude}
            list={[
              {
                label: t("general_doctor_message"),
                value: proposal?.proposal_message,
              },
            ]}
            descrizione={proposal?.proposal_message}
            cell={doctor?.telephone}
            email={doctor?.user_email}
          ></CaVisitCard>
        </Col>
      </Row>
    </CaContainer>
  );
}

export default withRequestData(PageUserManageAppointment);
