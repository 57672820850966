import moment from "moment";
import React from "react";
import { Col, ListGroup, Row, Image, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import CaButton from "./CaButton";
import { capitalize } from "@material-ui/core";

export default function CaProposalCard({
  proposals,
  title,
  showButton,
  listStyle,
  onAcceptProposal,
}) {
  const { t } = useTranslation();
  if (!proposals || proposals.length === 0)
    return <p>{t("general_no_proposal")}</p>;

  return (
    <Card>
      {title && (
        <Card.Header>
          <Card.Title>{title}</Card.Title>
        </Card.Header>
      )}

      <Card.Body className="m-0 p-0" style={listStyle}>
        <ListGroup>
          {proposals.map((proposal) => (
            <ListGroup.Item key={proposal._id}>
              <Row>
                <Col md={showButton && 7} className="d-flex">
                  <div className="me-3">
                    <strong>
                      {capitalize(proposal?.user_id?.name) +
                        " " +
                        capitalize(proposal?.user_id?.surname)}
                    </strong>
                    <p>
                      {t("general_message")}: {proposal?.proposal_message}
                    </p>
                  </div>
                  <div>
                    <p className="mb-0">
                      {t("general_price")}: {proposal?.proposed_price}€
                    </p>
                    <p className="mb-0">
                      {t("general_date")}:{" "}
                      {moment(proposal?.proposed_visit_date).format(
                        "DD/MM/YYYY"
                      )}{" "}
                      at {proposal?.proposed_visit_time}
                    </p>
                  </div>
                </Col>
                {showButton && (
                  <Col md={12}>
                    <CaButton
                      text={t("general_accept")}
                      onClick={() => onAcceptProposal(proposal._id)}
                    ></CaButton>
                  </Col>
                )}
              </Row>
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Card.Body>
    </Card>
  );
}
