import moment from "moment";
import React from "react";
import { Card, Col, Image, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import CaButton from "../CaButton";
import CaRequestStatusBadge from "../general/CaRequestStatusBadge";

const CardRequestProfessionist = ({
  acceptedDoctorName,
  acceptedDoctorId,
  userId,
  address,
  age,
  dateOfVisit,
  email,
  image,
  language,
  nationality,
  visitRequestDate,
  visitRequestTime,
  service_name,
  patientPhone,
  stato_id,
  stripeId,
  visitType,
  onViewMore,
  professionistStatus,
  proposal_num,
  disabled,
}) => {
  const { t } = useTranslation();
  const defaultImage =
    "https://via.placeholder.com/300x200?text=No+Image+Available";
  return (
    <Card className="my-3" style={{ position: "relative" }}>
      <Card.Body>
        <CaRequestStatusBadge
          status_id={stato_id}
          style={{ position: "absolute", right: 5, top: 5 }}
          numberOfProposal={proposal_num}
        ></CaRequestStatusBadge>
        <Row>
          <Col sm={4}>
            <Image
              variant="top"
              src={image || defaultImage}
              style={{ height: 80, width: 80, objectFit: "cover" }}
              roundedCircle
              thumbnail
            />
          </Col>
          <Col sm={8}>
            <Card.Title>{service_name}</Card.Title>
          </Col>
        </Row>

        <Card.Text>
          <strong>{t("general_address")}:</strong> {address} <br />
          <strong>{t("general_visit_type")}:</strong> {visitType} <br />
          <strong>{t("general_requested_visit_date")}:</strong>{" "}
          {moment(visitRequestDate).format("DD/MM/YYYY")} {visitRequestTime}
          <br />
          <strong>{t("general_proposal_number")}:</strong> {proposal_num} <br />
          <strong>{t("general_language")}:</strong> {language} <br />
        </Card.Text>
      </Card.Body>
      <Card.Footer>
        <CaButton
          variant="primary"
          onClick={onViewMore}
          text={t("general_view_detail")}
          disabled={disabled}
        ></CaButton>
      </Card.Footer>
    </Card>
  );
};

export default CardRequestProfessionist;
