import React, { useState } from "react";
import CaButton from "../CaButton";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const CaAddressFinder = ({ handleSelectPlace, disabled }) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const handleClick = (e) => {
    e.preventDefault();

    if (navigator.geolocation) {
      setLoading(true);
      navigator.geolocation.getCurrentPosition((position) => {
        const latLng = `${position.coords.latitude},${position.coords.longitude}`;
        const geocodingUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latLng}&key=${process.env.REACT_APP_GOOGLE_MAPS_TOKEN}`;
        fetch(geocodingUrl)
          .then((response) => response.json())
          .then((data) => {
            setLoading(false);
            if (data.results.length > 0) {
              handleSelectPlace({
                lat: position.coords.latitude,
                lng: position.coords.longitude,
                address: data.results[0].formatted_address,
              });
            }
          })
          .catch((error) => {
            setLoading(false);
            toast.error(t("error_unable_to_geolocate"));
          });
      });
    } else {
      toast.error(t("error_unable_to_geolocate"));
    }
  };

  return (
    <div>
      <CaButton
        className="btn btn-warning btn-with-shadow mb-4 mt-4"
        onClick={handleClick}
        text={t("general_find_address")}
        icon="fas fa-map-marker-alt me-3"
        disabled={disabled}
        isLoading={loading}
      ></CaButton>
    </div>
  );
};

export default CaAddressFinder;
