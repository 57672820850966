import React, { useContext, useState } from "react";
import mixpanel from "mixpanel-browser";
import { useHistory } from "react-router-dom";
import CaButton from "../CaButton";
import { UserContext } from "../../context/UserContext";
import CaModalSubscription from "../../pages/business/partner/components/CaModalSubscription";
import { DoctorRequestContext } from "../../context/DoctorRequestContext";
import { useTranslation } from "react-i18next";

export default function ButtonRequestBusiness(props) {
  const history = useHistory();
  const { doctorRequest, setDoctorRequest } = useContext(DoctorRequestContext);
  const { user } = useContext(UserContext);
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation();

  const handleRequest = () => {
    mixpanel.track("Request Visit", {
      type: "visita normale",
    });

    if (user?.partner || user?.requestsRemaining > 0) {
      let newState = Object.assign({}, doctorRequest);
      newState.requestType = "normal";
      setDoctorRequest(newState);
      history.push("/new-request");
    }
    if (!user?.partner && user?.requestsRemaining === 0) {
      setShowModal(true);
    }
  };

  return (
    <div>
      <CaButton
        onClick={handleRequest}
        className="btn btn-radius btn-red c-white"
        style={{ lineHeight: "none" }}
        text={t("general_new_request")}
        icon="fas fa-user-md me-2"
        disabled={!(user?.partner || user?.requestsRemaining > 0)}
      ></CaButton>

      <CaModalSubscription
        showModal={showModal}
        setShowModal={setShowModal}
      ></CaModalSubscription>
    </div>
  );
}
