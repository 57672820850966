import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import CaContainer from "../../../components/CaContainer";
import CardAppointment from "../../../components/profiloComponent/CardAppointment";
import { UserContext } from "../../../context/UserContext";
import { useRequest } from "../../../hooks/useRequest";
import doc from "../../../img/svg/doctors.svg";
import CaMissingRequest from "../../../components/shared/CaMissingRequest";

export default function PageProfessionistAppointment(props) {
  const { user } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [appointment, setAppointment] = useState([]);

  const { getPractionerAppointment } = useRequest();

  const { t } = useTranslation();
  console.log("PageUserDashboard user", user);

  useEffect(() => {
    if (user) {
      setLoading(true);
      getPractionerAppointment({ userID: user?.id })
        .then((res) => setAppointment(res.data))
        .catch((e) => toast.error(e))
        .finally(() => setLoading(false));
    }
  }, [user]);

  //TODO mostrare richiesta solo se utente Business e se è la prima richiesta che fa ( non ci sono altre richieste)

  return (
    <CaContainer loading={loading} title={t("general_user_appointment")}>
      <Row>
        {appointment &&
          appointment.map((app) => (
            <Col sm={12} lg={4}>
              <CardAppointment appointment={app}></CardAppointment>
            </Col>
          ))}

        {appointment && appointment.length === 0 && (
          <CaMissingRequest></CaMissingRequest>
        )}
      </Row>
    </CaContainer>
  );
}
