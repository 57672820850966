import HistoryIcon from "@material-ui/icons/History";
import HomeIcon from "@material-ui/icons/Home";
import PersonIcon from "@material-ui/icons/Person";
import EventIcon from "@mui/icons-material/Event";
import StarIcon from "@material-ui/icons/Star";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import React, { useContext, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { Menu, MenuItem, Sidebar } from "react-pro-sidebar";
import { useHistory } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import Message from "@material-ui/icons/Message";
import { CompanyContext } from "../context/CompanyContext";

export default function CaSideBar() {
  const [active, setActive] = useState("home");
  const { user } = useContext(UserContext);
  const { company } = useContext(CompanyContext);
  const { t } = useTranslation();
  useEffect(() => {
    var url = window.location.href;
    var urlParts = new URL(url);
    var lastWord = urlParts.pathname.split("/")[1];
    handleSelect(lastWord);
  }, []);
  const history = useHistory();
  const handleSelect = (lastWord) => {
    switch (lastWord) {
      case "":
        history.push("/");
        setActive("");
        break;
      case "history":
        history.push("/history");
        setActive("history");
        break;
      case "account":
        history.push("/account");
        setActive("account");
        break;
      case "visits":
        history.push("/visits");
        setActive("visits");
        break;
      case "appointment":
        history.push("/appointment");
        setActive("appointment");
        break;
      case "account-edit":
        setActive("account");
        break;
      case "conversations":
        history.push("/conversations");
        setActive("conversations");
        break;
      case "partner":
        history.push("/partner");
        setActive("partner");
        break;
      default:
        break;
    }
  };
  return (
    <Sidebar
      breakPoint={isMobile ? "always" : null}
      style={{ zIndex: 1000 }}
      backgroundColor="#01358d"
    >
      <Menu
        menuItemStyles={{
          button: ({ level, active, disabled }) => {
            // only apply styles on first level elements of the tree
            if (level === 0)
              return {
                color: "white",
                backgroundColor: active ? "#c8c8c8cf" : undefined,
              };
          },
        }}
      >
        <MenuItem
          active={active === ""}
          icon={<TravelExploreIcon />}
          onClick={() => handleSelect("")}
          style={{ marginTop: 50 }}
        >
          {t("general_active_request")}
        </MenuItem>
        <MenuItem
          active={active === "appointment"}
          icon={<EventIcon />}
          onClick={() => handleSelect("appointment")}
        >
          {t("general_appointment")}
        </MenuItem>
        <MenuItem
          active={active === "visits"}
          icon={<HistoryIcon />}
          onClick={() => handleSelect("visits")}
        >
          {t("general_visits")}
        </MenuItem>
        <MenuItem
          active={active === "conversations"}
          icon={<Message />}
          onClick={() => handleSelect("conversations")}
        >
          {t("general_chat")}
        </MenuItem>
        <MenuItem
          active={active === "account"}
          icon={<PersonIcon />}
          onClick={() => handleSelect("account")}
        >
          {t("general_profile")}
        </MenuItem>
        {user?.type === "business" && (
          <MenuItem
            active={active === "partner"}
            icon={<StarIcon />}
            onClick={() => handleSelect("partner")}
          >
            {t("general_became_partner")}
          </MenuItem>
        )}
      </Menu>
    </Sidebar>
  );
}
