import React, { useContext, useEffect } from "react";
import { useNotification } from "../hooks/useNotification";
import ObserveNotification from "../utils/ObserveNotification";
import { UserContext } from "./../context/UserContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CompanyContext } from "../context/CompanyContext";

export default function Toaster() {
  const { listenNotifications } = useNotification();
  const { user, setNotifications } = useContext(UserContext);
  const { company } = useContext(CompanyContext);
  useEffect(() => {
    if (user) {
      listenNotifications(user?._id);
      ObserveNotification.subscribe(notify);
    }
  }, [user]);

  const notify = (data) => {
    setNotifications((prev) => [data, ...prev]);

    toast.info(data.body, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  return (
    <ToastContainer
      position="bottom-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop
      closeOnClick={true}
      rtl={false}
      pauseOnFocusLoss
      pauseOnHover
      draggable={false}
    ></ToastContainer>
  );
}
