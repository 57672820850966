import React, { useContext } from "react";
import { Route } from "react-router-dom";
import { CompanyContext } from "../context/CompanyContext";
import { UserContext } from "../context/UserContext";
import PageSignUpBusiness from "../pages/business/signup/PageSignUpBusiness";
import LoginPage from "../pages/shared/login/LoginPage";
import PageResetPwd from "../pages/shared/resetPassword/PageResetPwd";
import PageResetPwdForm from "../pages/shared/resetPassword/PageResetPwdForm";
import PageSignUpUser from "../pages/users/signup/PageSignUpUser";
import BusinessRoutes from "./BusinessRoutes";
import ProfessionistsRoutes from "./ProfessionistsRoutes";
import UserRoutes from "./UsersRoutes";
import PageSignUpProfessionist from "../pages/prosessionists/signup/PageSignUpProfessionist";

const NormalRoutes = (props) => {
  // il problema è che in questpo compenente non si aggiorna il contesto al cambiare della lingua
  const { user, loggedIn, loadingAuth } = useContext(UserContext);
  const { company } = useContext(CompanyContext);

  if (loadingAuth) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <img
          src={company?.configuration?.logo}
          className="img-fluid"
          style={{ height: 200, width: 400, objectFit: "contain" }}
          alt="logo"
        ></img>
      </div>
    );
  }

  return (
    <>
      {!loggedIn && (
        <Route exact path="/" render={() => <LoginPage></LoginPage>} />
      )}
      {loggedIn && user?.type === "business" && (
        <BusinessRoutes></BusinessRoutes>
      )}
      {loggedIn && user?.type === "person" && <UserRoutes></UserRoutes>}
      {loggedIn && user?.type === "professionist" && (
        <ProfessionistsRoutes></ProfessionistsRoutes>
      )}
      {loggedIn &&
        user?.type !== "professionist" &&
        user?.type !== "person" &&
        user?.type !== "business" && <p>No user routes {user?.type}</p>}

      <Route
        exact
        path="/signup"
        render={() => <PageSignUpUser></PageSignUpUser>}
      />
      <Route
        exact
        path="/reset-password"
        render={() => <PageResetPwd></PageResetPwd>}
      />
      <Route
        exact
        path="/reset-password/:token"
        render={() => <PageResetPwdForm></PageResetPwdForm>}
      />
      <Route
        exact
        path="/signup-business"
        render={() => <PageSignUpBusiness></PageSignUpBusiness>}
      />

      <Route
        exact
        path="/signup-professionist"
        render={() => <PageSignUpProfessionist></PageSignUpProfessionist>}
      />
      <Route exact path="/login" render={() => <LoginPage></LoginPage>} />
    </>
  );
};

export default NormalRoutes;
