import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import SubscriptionStepper from "./SubscriptionStepper";
import { useTranslation } from "react-i18next";

export default function CaModalSubscription({ showModal, setShowModal }) {
  const handleCloseModal = () => setShowModal(false);
  const { t } = useTranslation();
  return (
    <Modal show={showModal} onHide={handleCloseModal} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{t("page_subscription_modal_title")}</Modal.Title>
      </Modal.Header>

      <SubscriptionStepper onClose={setShowModal}></SubscriptionStepper>
    </Modal>
  );
}
