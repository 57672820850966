import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ProSidebarProvider } from "react-pro-sidebar";
import { DoctorRequestProvider } from "./context/DoctorRequestContext";
import { RegistrationStepProvider } from "./context/RegistrationStepContext";
import Spin from "./components/Spin";
import Toaster from "./components/Toaster";
import { BusinessRegProvider } from "./context/BusinessRegContext";
import { MesssageProvider } from "./context/MessageContext";
import { RequestProvider } from "./context/RequestContext";
import { UserProvider } from "./context/UserContext";
import NormalRoutes from "./routes/NormalRoutes";
import "./i18n";
import { ProfessionistRegProvider } from "./context/ProfessionistRegContext";
import { CompanyProvider } from "./context/CompanyContext";
import "bootstrap/dist/css/bootstrap.min.css";

function Root() {
  return (
    <Router>
      <ProSidebarProvider>
        <CompanyProvider>
          <UserProvider>
            <RegistrationStepProvider>
              <ProfessionistRegProvider>
                <BusinessRegProvider>
                  <DoctorRequestProvider>
                    <RequestProvider>
                      <MesssageProvider>
                        <NormalRoutes></NormalRoutes>
                      </MesssageProvider>
                    </RequestProvider>
                    <Spin></Spin>
                    <Toaster></Toaster>
                  </DoctorRequestProvider>
                </BusinessRegProvider>
              </ProfessionistRegProvider>
            </RegistrationStepProvider>
          </UserProvider>
        </CompanyProvider>
      </ProSidebarProvider>
    </Router>
  );
}

export default Root;
