import React, { useContext, useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import CaContainer from "../../../components/CaContainer";
import CardRequest from "../../../components/profiloComponent/CardRequest";
import { RequestContext } from "../../../context/RequestContext";
import doc from "../../../img/svg/doctors.svg";
import { useTranslation } from "react-i18next";

export default function PageUserHistory(props) {
  const { richieste, setRichieste } = useContext(RequestContext);
  const [richiesteTermintate, setRichiesteTerminate] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (richieste) {
      let richiesteFilter = richieste.filter((req) => req.status_id === 4);
      setRichiesteTerminate(richiesteFilter);
    }
  }, [richieste]);

  return (
    <CaContainer>
      {richiesteTermintate && richiesteTermintate.length === 0 && (
        <div className="container">
          <div className="row mt-4">
            <div className="col-12 center">
              <h4 style={{ textAlign: "center" }}>
                {t("general_all_past_request")}
              </h4>
            </div>
            <div className="col-12 center">
              <img
                alt="img"
                src={doc}
                style={{ width: 200, height: 200 }}
              ></img>
            </div>
          </div>
        </div>
      )}
      <Row>
        {richiesteTermintate &&
          richiesteTermintate.map((req) => (
            <Col sm={12} lg={4}>
              <CardRequest request={req}></CardRequest>
            </Col>
          ))}
      </Row>
    </CaContainer>
  );
}
