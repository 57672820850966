import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import CaButton from "../../components/CaButton";
import QuillForm from "../../components/request/QuillForm";
import { CompanyContext } from "../../context/CompanyContext";
import { RequestContext } from "../../context/RequestContext";
import { UserContext } from "../../context/UserContext";
import { useRequest } from "../../hooks/useRequest";
import { calculateAge } from "../../utils/Utils";
import { useBlocks } from "./hooks/useBlocks";

export default function PageSpotRequest() {
  const [loading, setLoading] = useState(false);
  const [companyBlocks, setCompanyBlocks] = useState(false);
  const { user } = useContext(UserContext);
  const { company } = useContext(CompanyContext);

  const { apiCallRequestContext } = useContext(RequestContext);
  const { createNewRequestPerson, createNewRequestBusiness } = useRequest();
  const { getBlockSiAssiste, defaultNationality, defaultLanguage } =
    useBlocks();
  const { t } = useTranslation();
  const history = useHistory();

  const handleFreeRequest = (request) => {
    console.log(defaultNationality, defaultLanguage);
    console.log("QuillForm request", request);
    let answers = request?.answers;

    let payload = {
      patientName: answers?.nomeCliente.value, //ok
      patientSurname: answers?.cognomeCliente.value, //ok
      patientAge: calculateAge(answers?.dataNascita.value), //ok
      patientPhone: answers?.phoneNumber.value, //ok
      nationalitySpecificata: answers?.nationality?.value
        ? answers?.nationality?.value
        : defaultNationality, //ok
      emailPrenotazione: answers?.emailCliente.value, //ok
      message: answers?.message.value, //ok
      prenotazioneDate: answers?.dataRichiesta.value, //"2024-01-31",//ok
      prenotazioneTime: answers?.oraRichiesta.value, //ok
      address: answers?.address?.value?.address, //ok
      prezzo_preventivo: company?.configuration?.prezzo_preventivo, //ok
      prezzo_preventivo_currency: company?.configuration?.currency, //ok
      latitude: answers?.address?.value?.lat, //ok
      longitude: answers?.address?.value?.lng, //ok
      userID: user?.id, //ok
      serviceID: answers?.service?.value, //ok
      nationality: answers?.nationality?.value
        ? answers?.nationality?.value
        : defaultNationality, //ok
      professione: answers?.profession?.value, //ok
      requestType: answers?.request_type?.value, //ok
      languageId: answers?.language?.value
        ? answers?.language?.value
        : defaultLanguage, //ok

      patient_birth_date: answers?.dataNascita.value,
      patient_birth_place: answers?.luogoNascita.value,
    };

    console.log("user", user);

    if (user?.type === "business") {
      setLoading(true);
      createNewRequestBusiness(payload)
        .then((response) => {
          console.log("createNewRequestPerson response", response);
          setLoading(false);
          history.push("/");
          // aggiorno
          apiCallRequestContext();
          toast.success(t("general_request_created_success"));
        })
        .catch((error) => {
          setLoading(false);
          toast.error(t("general_request_created_error"));
        });
    }
    if (user?.type === "person") {
      setLoading(true);
      createNewRequestPerson(payload)
        .then((response) => {
          console.log("createNewRequestPerson response", response);
          setLoading(false);
          history.push("/");
          // aggiorno
          apiCallRequestContext();
          toast.success(t("general_request_created_success"));
        })
        .catch((error) => {
          setLoading(false);

          toast.error(t("general_request_created_error"));
        });
    }
  };

  useEffect(() => {
    setLoading(true);
    //TODO cambiare sulla base della company
    getBlockSiAssiste()
      .then((res) => {
        console.log("getBlockSiAssiste", res);
        // se c' è un undefined
        if (thereIsUndefinedElement(res)) {
          throw new Error("Errore nell ottenimento dei servizi");
        }
        setCompanyBlocks(res);
      })
      .catch((e) => {
        toast.error("Errore nell' ottenimento dei dati ");
        history.push("/");
      })
      .finally(() => setLoading(false));
  }, []);

  function thereIsUndefinedElement(array) {
    return array.includes(undefined);
  }

  return (
    <div style={{ height: "90vh", position: "relative" }}>
      <CaButton
        text={t("general_go_back")}
        onClick={() => history.push("/")}
        icon="fa fa-arrow-left"
        variant="link"
        style={{ position: "absolute", top: 10, left: 10, zIndex: 10000 }}
        className="btn btn-link"
      ></CaButton>
      {companyBlocks && (
        <QuillForm
          onSubmit={handleFreeRequest}
          blocks={companyBlocks}
          formId={1}
          logo={company?.configuration?.logo}
        ></QuillForm>
      )}
    </div>
  );
}
