import React from "react";
import { MapContainer, Popup, TileLayer, Marker } from "react-leaflet";

export default function CaMapV2({ center, zoom, marker, style }) {
  if (!center[0] || !center[1]) return <></>;
  return (
    <MapContainer
      center={center}
      zoom={zoom}
      scrollWheelZoom={false}
      style={{ minHeight: 300, ...style }}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {marker.map((m, i) => (
        <Marker key={i} position={[m?.latitude, m?.longitude]}>
          <Popup>{m.text}</Popup>
        </Marker>
      ))}
    </MapContainer>
  );
}

CaMapV2.defaultProps = {
  center: [51.505, -0.09],
  zoom: 13,
  marker: [],
};
