import React from "react";
import CaContainer from "../../../components/CaContainer";
import { useParams } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import ChatUser from "./ChatUser";

export default function PageUserChat() {
  const { practitionerID } = useParams();
  return (
    <CaContainer backButton={"/conversations"}>
      <ChatUser practitionerID={practitionerID}></ChatUser>
    </CaContainer>
  );
}
