import { useTranslation } from "react-i18next";
import useServices from "../../../hooks/useServices";
import useNationality from "../../../hooks/useNationality";
import useProfessions from "../../../hooks/useProfession";
import { useRequest } from "../../../hooks/useRequest";
import useLanguages from "../../../hooks/useLanguages";
import { useState } from "react";

export const useBlocks = () => {
  const { t } = useTranslation();

  const { getCompanyServices } = useServices();
  const { getCompanyNationalities } = useNationality();
  const { getProfessions } = useProfessions();
  const { getRequestType } = useRequest();
  const { getLanguages } = useLanguages();
  const [defaultNationality, setDefaultNationality] = useState(null);
  const [defaultLanguage, setDefaultLanguage] = useState(null);

  const selectService = async () => {
    let services;
    try {
      const company_services = await getCompanyServices();
      services = company_services?.data?.response;
    } catch (e) {
      console.error(e);
      return e;
    }
    return createSelect(
      "service",
      t("general_service_list"),
      services,
      "service_name",
      "_id"
    );
  };
  const selectRequestType = async () => {
    let request_type;
    try {
      const company_request_type = await getRequestType();
      request_type = company_request_type?.data?.response;
    } catch (e) {
      console.error(e);
      return e;
    }
    //FIXME - Va generalizzato.
    let defaultOption = request_type.filter(
      (types) => types.request_type === "domiciliare"
    )[0];
    console.log("company_request_type defaultOption", defaultOption);
    return createCustomSelect(
      "request_type",
      t("general_request_type_list"),
      request_type,
      "request_type",
      "_id",
      undefined,
      defaultOption?._id,
      true
    );
  };
  const selectLanguages = async () => {
    let languages;
    try {
      const company_languages = await getLanguages();
      languages = company_languages?.data?.response;
      console.log("languages", languages);
    } catch (e) {
      console.error(e);
      return e;
    }

    let defaultID = languages.filter((nat) => nat?.language_id === 3)[0]?._id; //italiano
    setDefaultLanguage(defaultID);
    return createSelect(
      "language",
      t("general_languages_list"),
      languages,
      "language_name",
      "_id"
    );
  };

  const selectProfession = async () => {
    let professions;
    try {
      const company_profession = await getProfessions();
      professions = company_profession?.data?.response;
      console.log("profession", professions);
    } catch (e) {
      console.error(e);
      return e;
    }

    let defaultOption = professions.filter(
      (pro) => pro?.profession_id === 1
    )[0];

    return createCustomSelect(
      "profession",
      t("general_profession_list"),
      professions,
      "profession",
      "_id",
      undefined,
      defaultOption?._id,
      true
    );
  };
  const selectNationality = async () => {
    let nationalities;
    try {
      const company_nationalities = await getCompanyNationalities();
      nationalities = company_nationalities?.data?.response;
    } catch (e) {
      console.error(e);
      return e;
    }

    let defaultID = nationalities.filter((nat) => nat?.nationality_id === 1)[0]
      ?._id;
    setDefaultNationality(defaultID);

    return createSelect(
      "nationality",
      t("general_nationalities_list"),
      nationalities,
      "code",
      "_id"
    );
  };

  const getBlockSiAssiste = async () => {
    let serviceList = await selectService();
    let nationalityList = await selectNationality();
    let professionsList = await selectProfession();
    let requestTypeList = await selectRequestType();
    let languagesList = await selectLanguages();

    console.log("nationalityList", nationalityList);
    console.log("languagesList", languagesList);

    return [
      requestTypeList,
      {
        id: "address",
        name: "location-checker",
        attributes: {
          required: true,
          label: t("general_request_place"),
        },
      },

      professionsList,
      serviceList,

      ...blockSiAssiste,
    ];
  };

  const createSelect = (id, label, arr, arrKey, arrValue, translate) => {
    if (!id) return console.error("createSelect: specificare ID");
    if (!label) return console.error("createSelect: specificare label");
    if (!arr) return console.error("createSelect: specificare arr");
    if (arr.length === 0) return console.error("createSelect: array vuoto");
    return {
      name: "dropdown",
      id: id,
      attributes: {
        required: true,
        multiple: false,
        verticalAlign: false,
        label: label,
        choices: arr.map((obj) => {
          return {
            label: obj[arrKey],
            value: translate
              ? t("general_" + translate + "_" + obj[arrValue])
              : obj[arrValue],
          };
        }),
      },
    };
  };

  const createCustomSelect = (
    id,
    label,
    arr,
    arrKey,
    arrValue,
    translate,
    defaultValue,
    disabled
  ) => {
    if (!id) return console.error("createSelect: specificare ID");
    if (!label) return console.error("createSelect: specificare label");
    if (!arr) return console.error("createSelect: specificare arr");
    if (arr.length === 0) return console.error("createSelect: array vuoto");

    console.log("default value", defaultValue);
    return {
      name: "select-picker",
      id: id,
      attributes: {
        required: true,
        disabled: disabled,
        verticalAlign: false,
        label: label,
        defaultValue: defaultValue,
        choices: arr.map((obj) => {
          return {
            label: obj[arrKey],
            value: translate
              ? t("general_" + translate + "_" + obj[arrValue])
              : obj[arrValue],
          };
        }),
      },
    };
  };

  const blockSiAssiste = [
    {
      id: "dataRichiesta",
      name: "date-picker",
      attributes: {
        label: t("general_request_date"),
        format: "MMDDYYYY" | "DDMMYYYY" | "YYYYMMDD",
        separator: "/",
      },
    },

    {
      id: "oraRichiesta",
      name: "dropdown",
      attributes: {
        label: t("general_select_request_hour"),
        required: true,
        choices: [
          {
            value: "8:00",
            label: "8:00",
          },
          {
            value: "8:30",
            label: "8:30",
          },
          {
            value: "9:00",
            label: "9:00",
          },
          {
            value: "9:30",
            label: "9:30",
          },
          {
            value: "10:00",
            label: "10:00",
          },
          {
            value: "10:30",
            label: "10:30",
          },
          {
            value: "11:00",
            label: "11:00",
          },
          {
            value: "11:30",
            label: "11:30",
          },
          {
            value: "12:00",
            label: "12:00",
          },
          {
            value: "12:30",
            label: "12:30",
          },
          {
            value: "13:00",
            label: "13:00",
          },
          {
            value: "13:30",
            label: "13:30",
          },
          {
            value: "14:00",
            label: "14:00",
          },
          {
            value: "14:30",
            label: "14:30",
          },
          {
            value: "15:00",
            label: "15:00",
          },
          {
            value: "15:30",
            label: "15:30",
          },
          {
            value: "16:00",
            label: "16:00",
          },
          {
            value: "16:30",
            label: "16:30",
          },
          {
            value: "17:00",
            label: "17:00",
          },
          {
            value: "17:30",
            label: "17:30",
          },
          {
            value: "18:00",
            label: "18:00",
          },
          {
            value: "18:30",
            label: "18:30",
          },
          {
            value: "19:00",
            label: "19:00",
          },
        ], // Default:  [ { "value": "123e45z7o89b",	"label": "Choice 1" }]
        verticalAlign: true, // Default : false
        multiple: false, // Default : false,
        max: false, // Default: false,
        min: false, // Default: false
      },
    },
    {
      id: "1dsdf12e",
      name: "group",
      attributes: {
        label: t("insert_information_about_patient"),
      },
      innerBlocks: [
        {
          id: "nomeCliente",
          name: "short-text",
          attributes: {
            label: t("general_patient_name"),
            required: true,
            placeholder: null,
          },
        },
        {
          id: "cognomeCliente",
          name: "short-text",
          attributes: {
            label: t("general_patient_surname"),
            required: true,
            placeholder: null,
          },
        },
      ],
    },
    {
      id: "ewqewqew",
      name: "group",
      attributes: {
        label: t("insert_information_about_patient"),
      },
      innerBlocks: [
        {
          id: "dataNascita",
          name: "date-picker",
          attributes: {
            label: t("general_patient_birth_date"),
            format: "MMDDYYYY" | "DDMMYYYY" | "YYYYMMDD",
            separator: "/",
          },
        },
        {
          id: "luogoNascita",
          name: "short-text",
          attributes: {
            label: t("general_patient_birth_place"),
            required: true,
            placeholder: null,
          },
        },
      ],
    },
    {
      id: "phoneNumber",
      name: "phone-number",
      attributes: {
        label: t("general_phone"),
      },
    },
    {
      id: "message",
      name: "long-text",
      attributes: {
        label: t("general_message_to_praticioner"),
        required: true,
      },
    },
    {
      id: "emailCliente",
      name: "email",
      attributes: {
        label: t("general_patient_email"),
        required: true,
        placeholder: null,
      },
    },
  ];

  return {
    blockSiAssiste,
    getBlockSiAssiste,
    defaultNationality,
    defaultLanguage,
  };
};
