import axios from "axios";
import { useContext } from "react";
import { RequestContext } from "../context/RequestContext";
import { mergeObjects } from "../utils/Utils";
import { UserContext } from "../context/UserContext";
// una lista di tutti i commenti

export function useRequest() {
  const { richieste, setRichieste } = useContext(RequestContext);
  const { user, token } = useContext(UserContext);
  const baseURL = process.env.REACT_APP_BASE_URL;

  const updateRequestById = (id, stato_id, stato) => {
    if (!id)
      return console.error(
        "specificare un ID per eseguire l' aggiornamento dello stato"
      );
    const updatedRichieste = [...richieste];
    const index = updatedRichieste.findIndex((obj) => obj._id === id);
    console.log("index", index);

    if (index !== -1) {
      console.log("dentro index");
      // Verifica che l'oggetto esista nell'array
      const updatedObj = { ...updatedRichieste[index] };
      updatedObj.stato_id = stato_id;
      updatedObj.stato = stato;
      updatedRichieste[index] = updatedObj;
      setRichieste(updatedRichieste);
    }
  };

  /**
   * @returns Restituisce le richieste di utenti in zona
   */
  const getPersonRequestInZone = async () => {
    var config = {
      method: "get",
      url: baseURL + "/api/requests/person/nella-zona",
    };

    return axios(config);
  };

  /**
   * @returns Restituisce le richieste di business in zona
   */
  const getBusinessRequestInZone = async () => {
    var config = {
      method: "get",
      url: baseURL + "/api/requests/business/nella-zona",
    };

    return axios(config);
  };

  /**
   * @returns Restituisce gli appuntameneti dell' utente
   */
  const getUserAppointment = async ({ userID }) => {
    var config = {
      method: "get",
      url: baseURL + "/api/requests/person/" + userID + "/user/appointment",
    };

    return axios(config);
  };
  /**
   * @returns Restituisce gli appuntamenti del professionista
   */
  const getPractionerAppointment = async ({ userID }) => {
    var config = {
      method: "get",
      url:
        baseURL +
        "/api/requests/person/" +
        userID +
        "/practitioner/appointment",
    };

    return axios(config);
  };

  /**
   * Crea una nuova richiesta per una persona
   * @param {*} requestData
   * @returns success
   */
  const createNewRequestPerson = async (requestData) => {
    var config = {
      method: "post",
      url: baseURL + "/api/requests/person/create",
      data: requestData,
    };

    return axios(config);
  };
  /**
   * Crea una nuova richiesta per un business
   * @param {*} requestData
   * @returns success
   */
  const createNewRequestBusiness = async (requestData) => {
    var config = {
      method: "post",
      url: baseURL + "/api/requests/business/create",
      data: requestData,
    };

    return axios(config);
  };

  /**
   * Cancella una nuova richiesta
   * @param {requestID} ID della richiesta
   * @returns success
   */
  const cancelRequest = async (requestID) => {
    if (!requestID) return console.error("cancelRequest: Definire request ID");
    var config = {
      method: "put",
      url:
        baseURL + "/api/requests/" + user?.type + "/" + requestID + "/cancel",
    };
    return axios(config);
  };

  /**
   * @param {float} lat latitudine selezionata
   * @param {float} lng longitudine selezionata
   * @param {string} profession professionista richiesto
   */
  const areWeActiveInSelectedZone = async (lat, lng, profession) => {
    if (!lat) return console.error("Indicare la latitudine");
    if (!lng) return console.error("Indicare la longitudine");
    if (!profession) return console.error("Indicare la professione");
    var config = {
      method: "get",
      url:
        baseURL +
        "/api/doctor/get-avaible/" +
        lat +
        "/" +
        lng +
        "/" +
        profession,
      headers: {},
    };

    return axios(config);
  };

  const getRequestType = async () => {
    return axios.get(baseURL + "/api/request/type");
  };

  return {
    createNewRequestPerson,
    createNewRequestBusiness,
    updateRequestById,
    areWeActiveInSelectedZone,
    getRequestType,
    cancelRequest,
    getPersonRequestInZone,
    getBusinessRequestInZone,
    getUserAppointment,
    getPractionerAppointment,
  };
}
