import React, { createContext, useState, useEffect } from "react";

export const ProfessionistRegContext = createContext();

export const ProfessionistRegProvider = (props) => {
  const [professionistReg, setProfessionistReg] = useState({
    loading: false,
    professione: "",
    nome: "",
    cognome: "",
    sedeLegale: "",
    indirizzo: "",
    codicePostale: "",
    selectedValue: "",
    registroDelleImprese: "",
    codiceFiscale: "",
    piva: "",
    pec: "",
    type: "",
    email: "",
    error: false,
    password: "",
    passwordConfirmed: "",
    citta: "",
    cittaShort: "",
    paese: "",
    paeseShort: "",
    modalShow: false,
    privacy: false,
    termsCondition: false,
    contractCondition: false,
    showPassword: false,
    latitude: null,
    longitude: null,
    telefono: null,
    otp: null,
    stepOtp: 1,
    passwordConfirm: "",
  });

  return (
    <ProfessionistRegContext.Provider
      value={{ professionistReg, setProfessionistReg }}
    >
      {props.children}
    </ProfessionistRegContext.Provider>
  );
};
