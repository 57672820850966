import Slider from "@material-ui/core/Slider";
import React, { useState, useEffect } from "react";

export default function CaSlider({
  initialValue, // New prop for initial value
  step,
  min,
  max,
  suffix,
  onChange,
}) {
  const [value, setValue] = useState(initialValue);
  const sliderId = `slider-${Math.random().toString(36).substring(2, 15)}`;

  useEffect(() => {
    setValue(initialValue); // Update the value when the initialValue prop changes
  }, [initialValue]);

  const handleChange = (_, v) => {
    onChange(v); // Invoke the callback with an object containing the new value
    setValue(v);
  };

  function valuetext(value) {
    return `${value}${suffix}`;
  }

  return (
    <Slider
      value={value}
      getAriaValueText={valuetext}
      aria-labelledby={sliderId}
      step={step}
      marks
      min={min}
      max={max}
      valueLabelDisplay="auto"
      onChange={handleChange}
    />
  );
}
