import React from "react";
import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";

import CaContainer from "../../../components/CaContainer";
import CaLoader from "../../../components/CaLoader";
import VisitFormPage from "../../../components/professionist/VisitFormPage";
import withRequestData from "../../../hoc/withRequestData";

function PageProfessionistCheckoutRequest({
  request,
  proposal,
  requestID,
  proposalList,
  doctor,
  loading,
}) {
  console.log("PROPOSAL:", proposal);
  return (
    <CaContainer backButton={"/manage-appointment/" + requestID}>
      <Row>
        <Col>
          <CaLoader loading={loading}></CaLoader>
          {!loading && (
            <VisitFormPage
              request={request}
              proposal={proposal}
              doctor={doctor}
            ></VisitFormPage>
          )}
        </Col>
      </Row>
    </CaContainer>
  );
}

export default withRequestData(PageProfessionistCheckoutRequest);
