import React, { useEffect, useState } from "react";
import CaButton from "../../../../../components/CaButton";
import { Modal, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export default function SubscriptionStep2({ onChange, type }) {
  const [activeNext, setActiveNext] = useState(false);
  const [url, setUrl] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (!type) {
      return;
    }
    if (type?.name === "Abbonamento Silver") {
      setUrl(
        "https://docs.google.com/document/d/1NExChUoSaLSe7tvBhHa4DLlyE7yc8A0T/preview?usp=drive_link&ouid=102281793825855706741&rtpof=true&sd=true"
      );
    }

    if (type?.name === "Abbonamento GOLD") {
      setUrl(
        "https://docs.google.com/document/d/1JelNBB3xaohgS5bupB7PH2Kl6X-gK3yq/preview?usp=drive_link&ouid=102281793825855706741&rtpof=true&sd=true"
      );
    }

    if (type?.name === "Abbonamento Platinum") {
      setUrl(
        "https://docs.google.com/document/d/16477qhnQuBMd71e2XJcTUZH09bi5n5FI/preview?usp=drive_link&ouid=102281793825855706741&rtpof=true&sd=true"
      );
    }
  }, [type]);

  // quale è stato selezionato ?
  return (
    <>
      <Modal.Body>
        <div className="container">
          <div className="row">
            <div className="col-12" style={{ minHeight: 400 }}>
              <iframe
                title="doc"
                style={{ width: "100%", minHeight: 400 }}
                src={url}
              ></iframe>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12">
              <div class="form-group form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="exampleCheck1"
                  onChange={() => setActiveNext((prev) => !prev)}
                ></input>
                <label className="form-check-label" for="exampleCheck1">
                  {t("general_accept_terms_conditions")}
                </label>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <CaButton
          text={t("general_previous")}
          onClick={() => onChange(1)}
          className="btn btn-secondary"
        ></CaButton>
        <CaButton
          text={t("general_next")}
          disabled={!activeNext}
          onClick={() => onChange(3)}
        ></CaButton>
      </Modal.Footer>
    </>
  );
}
