import { CircularProgress, LinearProgress } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import Typography from "@material-ui/core/Typography";
import mixpanel from "mixpanel-browser";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { DoctorRequestContext } from "../../../../context/DoctorRequestContext";
import { RequestContext } from "../../../../context/RequestContext";
import { UserContext } from "../../../../context/UserContext";
import { useRequest } from "../../../../hooks/useRequest";
import StepFour from "../../../shared/request/stepper/StepFour";
import StepOOne from "../../../shared/request/stepper/StepOOne";
import StepOne from "../../../shared/request/stepper/StepOne";
import StepThree from "../../../shared/request/stepper/StepThree";
import StepTwo from "../../../shared/request/stepper/StepTwo";
import { useTranslation } from "react-i18next";

function getStepContent(step) {
  switch (step) {
    case 0:
      return <StepOne></StepOne>;
    //return <StepThree ></StepThree>;
    case 1:
      return <StepOOne></StepOOne>;
    case 2:
      return <StepTwo></StepTwo>;
    case 3:
      return <StepThree></StepThree>;
    case 4:
      return <StepFour></StepFour>;
    default:
      return "Unknown step";
  }
}

export default function HorizontalLinearStepperBusiness() {
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const [isRequestEnded, setIsRequestEnded] = useState(false);
  const { doctorRequest, setDoctorRequest } = useContext(DoctorRequestContext);
  const { richieste, setRichieste } = useContext(RequestContext);
  const { user } = useContext(UserContext);
  const history = useHistory();
  const { createNewRequestBusiness } = useRequest();
  const { t } = useTranslation();

  function getSteps() {
    let step1 = t("general_contact_info");
    let step11 = t("general_visit_type");
    let step2 = t("general_visit_detail");
    let step3 = t("general_visit_location");
    let step4 = t("general_summary");
    let array = [];

    array.push(step1, step11, step2, step3, step4);
    return array;
  }

  const steps = getSteps();
  const myForm = React.useRef(null);

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = (e) => {
    let newState = Object.assign({}, doctorRequest);
    newState.error = false;
    setDoctorRequest(newState);
    e.preventDefault();
    let newSkipped = skipped;
    if (!myForm.current.checkValidity()) {
      let newState = Object.assign({}, doctorRequest);
      newState.error = true;
      setDoctorRequest(newState);
      return;
    }
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    mixpanel.track("Step Richiesta", { number: activeStep + 1 });

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setProgress((activeStep) => activeStep + 25);
    setSkipped(newSkipped);
  };

  const handleBack = (e) => {
    e.preventDefault();
    mixpanel.track("Step Richiesta", { number: activeStep - 1 });
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setProgress((activeStep) => activeStep - 25);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const sendRequest = (e) => {
    e.preventDefault();
    // aggiorno l' utente per mailchimp

    let newState = Object.assign({}, doctorRequest);
    newState.spinner = true;
    setDoctorRequest(newState);

    var today = new Date();
    var time = today.getHours() + ":" + today.getMinutes();
    let prenotationDate = new Date(doctorRequest.timeStart);

    const Rhours = prenotationDate.getHours();
    const Rminutes = prenotationDate.getMinutes();

    function convertDate(dateString) {
      var date = new Date(dateString);
      return (
        date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
      );
    }

    const dataPrenotazione = convertDate(prenotationDate);
    const oraPrenotazione = Rhours + ":" + Rminutes;
    console.log("utente al momento della richiesta: ", user);

    let newRequest = {
      nome: doctorRequest.name,
      age: doctorRequest.age,
      userType: user?.type,
      userName: user?.name,
      nationality: doctorRequest.nationality,
      email: user?.email,
      emailPrenotazione: doctorRequest.email,
      image: user?.image,
      userId: user?.id,
      message: doctorRequest.message,
      address: doctorRequest.address,
      language: doctorRequest.language,
      data: doctorRequest.timeStart,
      dataPrenotazione: dataPrenotazione,
      oraPrenotazione: oraPrenotazione,
      patientPhone: doctorRequest.phone,
      latitude: doctorRequest.latitude,
      longitude: doctorRequest.longitude,
      requestType: doctorRequest.requestType, // covid, normale, videocall
      testType: doctorRequest.testType,
      testNumber: doctorRequest.testNumber,
      fcmToken: typeof user?.fcmToken !== "undefined" ? user?.fcmToken : "",
      hourOfVisit: doctorRequest.oraPrenotazione,
      dateOfVisit: doctorRequest.dataPrenotazione,
      timestamp: Date.now(),
      prenotationDate: Date.now(),
      visitRequestDate: doctorRequest.timeStart,
      visitType: doctorRequest.doctorType, // tipologia di professionista
      ora: time,
      stato: "Nuova richiesta",
      stato_id: 1,
      color: "#FF7F00",
      active: true,
      businessID: user?.id,
    };

    setLoading(true);

    createNewRequestBusiness(newRequest)
      .then(() => {
        setLoading(false);
        setIsRequestEnded(true);
        // chiudo spinner
        let newState = Object.assign({}, doctorRequest);
        newState.spinner = false;
        setDoctorRequest(newState);

        // rimanda alla dashboard del profilo

        setRichieste([newRequest, ...richieste]);
        toast.success("Richiesta effettuata !", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        mixpanel.track("Nuova Richiesta", { ...newRequest });
        history.push("/");
      })
      .catch((error) => {
        setLoading(false);
        setIsRequestEnded(true);
        // chiudo spinner
        let newState = Object.assign({}, doctorRequest);
        newState.spinner = false;
        setDoctorRequest(newState);
        // mostro erroer
        console.log(error);
        toast.error("Errore nella richista" + error.message, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        mixpanel.track("Errore Nuova Richiesta", { ...newRequest });
      });
  };

  return (
    <div className="container">
      {isRequestEnded && (
        <div className="row">
          <div className="col-md-12 mt-4">
            <h4>Richiesta effettuata con successo. ✌</h4>
            <p>Abbiamo preso in carico la tua richiesta</p>
            <p>
              A breve riceverai delle proposte di assistenza da nostri
              professionisti sanitari all' email che hai indicato nel form.
            </p>
            <p>
              Per qualsiasi evenienza scrivici su{" "}
              <a href="mailto:info@caducea.it">info@caducea.it</a>
            </p>
          </div>
        </div>
      )}
      {!isRequestEnded && (
        <>
          <Stepper activeStep={activeStep} className="row">
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};

              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel
                    /* StepIconComponent={<PersonIcon></PersonIcon>} */
                    {...labelProps}
                  >
                    {label}
                  </StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <form ref={myForm}>
            {activeStep === steps.length ? (
              <div>
                <Typography>All the steps are completed</Typography>
                <Button onClick={handleReset}>Reset</Button>
              </div>
            ) : (
              <div>
                <div className="container mt-4 " style={{ marginBottom: 100 }}>
                  {getStepContent(activeStep)}
                </div>

                <footer className="footer-area footer-area-2 buttonBottom">
                  <LinearProgress
                    variant="determinate"
                    value={progress}
                  ></LinearProgress>
                  <div className="copyright-inner">
                    <div className="copyright-text">
                      <div className="row  ">
                        <div className="col-6 text-center  ">
                          <button
                            className="btn btn-border btn--with-shadow c-primary"
                            disabled={activeStep === 0}
                            onClick={handleBack}
                          >
                            Back
                          </button>
                          {isStepOptional(activeStep) && <div></div>}
                        </div>
                        <div className="col-6 text-center  ">
                          {activeStep === steps.length - 1 ? (
                            <button
                              className="btn btn-border btn-red c-primary"
                              variant="contained"
                              color="primary"
                              onClick={
                                activeStep === steps.length - 1
                                  ? sendRequest
                                  : handleNext
                              }
                            >
                              Submit Request
                              {loading && (
                                <CircularProgress size={20}></CircularProgress>
                              )}
                            </button>
                          ) : (
                            <button
                              className="btn btn-border btn-green btn--with-shadow c-primary"
                              variant="contained"
                              color="primary"
                              type="submit"
                              onClick={
                                activeStep === steps.length - 1
                                  ? sendRequest
                                  : handleNext
                              }
                            >
                              Next
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </footer>
              </div>
            )}
          </form>
        </>
      )}
    </div>
  );
}
