import { TextFields } from "@material-ui/icons";
import React, { useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { BusinessRegContext } from "../../../../../context/BusinessRegContext";
import { InputAdornment, TextField } from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import BusinessIcon from "@material-ui/icons/Business";
import PlaceIcon from "@material-ui/icons/Place";
import AlternateEmailIcon from "@material-ui/icons/AlternateEmail";
import {
  validCodiceFiscale,
  validPartitaIva,
} from "../../../../../utils/Utils";
import { useTranslation } from "react-i18next";

export default function StepTwoBusinessReg() {
  const { businessReg, setBusinessReg } = useContext(BusinessRegContext);
  const { t } = useTranslation();

  const handleChange = (e, target) => {
    let newState = Object.assign({}, businessReg);
    newState[target] = e.target.value;
    setBusinessReg(newState);
  };

  const handleCfError = (cf) => {
    if (cf == "") return false;
    if (validCodiceFiscale(cf) || validPartitaIva(cf)) {
      return false;
    }
    return true;
  };

  return (
    <Container>
      <Row>
        <Col>
          <TextField
            className="single-input"
            error={
              businessReg.registroDelleImprese == ""
                ? businessReg.error
                  ? true
                  : false
                : null
            }
            helperText={
              businessReg.registroDelleImprese == ""
                ? businessReg.error
                  ? "Required"
                  : null
                : null
            }
            variant="outlined"
            value={businessReg.registroDelleImprese}
            onChange={(e) => handleChange(e, "registroDelleImprese")}
            label={t("general_iscritto_al_registro_delle_imprese_di")}
            type="text"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PlaceIcon />
                </InputAdornment>
              ),
            }}
            required
          ></TextField>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <TextField
            className="single-input"
            error={businessReg.piva !== "" && businessReg.piva.length !== 11}
            helperText={
              businessReg.piva == ""
                ? businessReg.error
                  ? "Required"
                  : null
                : null
            }
            variant="outlined"
            value={businessReg.piva}
            onChange={(e) => handleChange(e, "piva")}
            label={t("general_piva")}
            type="number"
            minLength={11}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <BusinessIcon />
                </InputAdornment>
              ),
            }}
            required
          ></TextField>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <TextField
            className="single-input"
            error={handleCfError(businessReg.codiceFiscale)}
            helperText={
              businessReg.codiceFiscale == ""
                ? businessReg.error
                  ? "Required"
                  : null
                : null
            }
            variant="outlined"
            value={businessReg.codiceFiscale}
            onChange={(e) => handleChange(e, "codiceFiscale")}
            label={t("general_codice_fiscale")}
            type="text"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <BusinessIcon />
                </InputAdornment>
              ),
            }}
          ></TextField>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <TextField
            className="single-input"
            error={
              businessReg.pec == "" ? (businessReg.error ? true : false) : null
            }
            helperText={
              businessReg.pec == ""
                ? businessReg.error
                  ? "Required"
                  : null
                : null
            }
            variant="outlined"
            value={businessReg.pec}
            onChange={(e) => handleChange(e, "pec")}
            label={t("general_pec")}
            type="email"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AlternateEmailIcon></AlternateEmailIcon>
                </InputAdornment>
              ),
            }}
            required
          ></TextField>
        </Col>
      </Row>
    </Container>
  );
}
