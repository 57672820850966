import React, { useEffect, useState } from "react";
import { useTheme, useMessages } from "@quillforms/renderer-core";
import "react-datepicker/dist/react-datepicker.css";

const TimePicker = (props) => {
  const {
    id,
    attributes,
    setIsValid,
    setIsAnswered,
    setValidationErr,
    isActive,
    showNextBtn,
    val,
    setVal,
    next,
  } = props;

  const [privacyPolicy, setPrivacyPolicy] = useState(false);
  const [termAndCondition, setTermAndCondition] = useState(false);
  const messages = useMessages();

  const { required } = attributes;

  useEffect(() => {
    if (required) {
      if (privacyPolicy && termAndCondition) {
        setIsValid(true);
        setIsAnswered(true);
        setValidationErr(null);
        setVal(true);
      } else {
        setIsValid(false);
        setIsAnswered(false);
        setValidationErr(messages["label.errorAlert.required"]);
      }
    } else {
      setIsValid(true);
      setIsAnswered(true);
      setValidationErr(null);
      setVal(true);
    }
  }, [termAndCondition, privacyPolicy]);
  return (
    <>
      <div className="flex items-center mb-4">
        <div className="flex items-center">
          <input
            id="link-checkbox"
            type="checkbox"
            checked={termAndCondition}
            onClick={() => setTermAndCondition((val) => !val)}
            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          ></input>
          <label
            for="link-checkbox"
            className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            I agree with the{" "}
            <a
              href="/termini-condizioni"
              className="text-blue-600 dark:text-blue-500 hover:underline"
            >
              terms and conditions
            </a>
            .
          </label>
        </div>
      </div>

      <div className="flex items-center mb-4">
        <div className="flex items-center">
          <input
            id="link-checkbox"
            type="checkbox"
            checked={privacyPolicy}
            onClick={() => setPrivacyPolicy((val) => !val)}
            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          ></input>
          <label
            for="link-checkbox"
            className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            I agree with the{" "}
            <a
              href="/privacy-policy"
              className="text-blue-600 dark:text-blue-500 hover:underline"
            >
              privacy policy
            </a>
            .
          </label>
        </div>
      </div>
    </>
  );
};
export default TimePicker;
