import React, { useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import LocationHandlerV2 from "../../../../components/general/LocationHandlerV2";

export default function StepThree(props) {
  return (
    <Container>
      <Row>
        <Col className="text-center flex-column ">
          <LocationHandlerV2></LocationHandlerV2>
        </Col>
      </Row>
    </Container>
  );
}
