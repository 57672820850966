import React from "react";
import { ListGroup, ListGroupItem } from "react-bootstrap";

export default function CaFeatureList({ list }) {
  if (list.length == 0 || !list.length) return;
  return (
    <ListGroup variant="flush">
      {list.map((item, i) => (
        <>
          {item?.show && (
            <ListGroup.Item key={i} variant={item?.color}>
              <strong>
                <i className={item?.icon + " mr-2"}></i> {item?.label}:
              </strong>{" "}
              {item?.link && <a href={item?.link}>{item?.value}</a>}
              {!item?.link && item?.value}
            </ListGroup.Item>
          )}
        </>
      ))}
    </ListGroup>
  );
}
