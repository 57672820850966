import React, { useContext, useEffect, useState } from "react";

import CountUp from "react-countup";

import CaBadge from "./CaBadge";
import ObserveCredits from "../utils/ObserveCredits";
import { UserContext } from "../context/UserContext";
import { useTranslation } from "react-i18next";

const CreditsBadge = ({ crediti }) => {
  const [credits, setCredits] = useState(crediti);
  const { t } = useTranslation();

  const { user } = useContext(UserContext);
  // ascolto il channel credits ed eseguo la funzione handleCredits
  useEffect(() => {
    ObserveCredits.subscribe(handleCredits);
  }, []);

  const handleCredits = () => {
    setCredits((prev) => prev - 1);
  };

  if (!user) {
    return <></>;
  }

  if (user && user?.type !== "business") {
    return <></>;
  }
  if (user && user?.type === "business" && user?.requestsRemaining <= 0) {
    return <></>;
  }

  return (
    <CaBadge className="me-2 d-flex" variant="primary">
      <i className="fas fa-coins me-1 fs-5 center"></i>
      <b className="fw-bold ms-1 d-flex justify-center flex-column w-100">
        <small className="mb-1">{t("general_available_credits")}</small>
        <CountUp
          startOnMount={false}
          start={user?.requestsRemaining}
          end={credits}
          preserveValue
          duration={3}
          separator="."
          decimals={0}
          decimal=","
          style={{ fontSize: 14 }}
        ></CountUp>
      </b>
    </CaBadge>
  );
};

export default CreditsBadge;
