import axios from "axios";
// una lista di tutti i commenti

export default function useLanguages() {
  const baseURL = process.env.REACT_APP_BASE_URL;

  const getLanguages = (data) => {
    let config = {
      method: "get",
      url: baseURL + "/api/languages",
    };
    return axios(config);
  };

  return {
    getLanguages,
  };
}
