import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import CaButton from "../../../../../components/CaButton";
import CaInput from "../../../../../components/CaInput";

export default function StepCredentialsProfessionist({
  show,
  onSubmit,
  onGoBack,
  onChange,
  formData,
  disableNext,
  loading,
}) {
  const { t } = useTranslation();

  return (
    <Container style={{ display: !show && "none" }}>
      <Row className="mt-4">
        <Col>
          <CaInput
            className="single-input"
            variant="outlined"
            value={formData?.email}
            onChange={(e) => onChange(e, "email")}
            label={t("general_email")}
            type="email"
            required
          ></CaInput>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col>
          <CaInput
            className="single-input"
            variant="outlined"
            value={formData?.password}
            onChange={(e) => onChange(e, "password")}
            label={t("general_password")}
            type="password"
            required
          ></CaInput>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <CaInput
            className="single-input"
            variant="outlined"
            value={formData?.passwordConfirm}
            onChange={(e) => onChange(e, "passwordConfirm")}
            label={t("general_password_confirm")}
            type="password"
            error={formData?.password !== formData?.passwordConfirm}
            required
          ></CaInput>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <CaButton
            text={t("general_back")}
            variant={"outline"}
            onClick={(e) => {
              e.preventDefault();
              onGoBack();
            }}
          ></CaButton>
        </Col>
        <Col>
          <CaButton
            text={t("general_submit")}
            disabled={
              !formData?.passwordConfirm ||
              !formData?.password ||
              !formData?.email
            }
            onClick={(e) => {
              e.preventDefault();
              onSubmit();
            }}
            isLoading={loading}
          ></CaButton>
        </Col>
      </Row>
    </Container>
  );
}
