import mixpanel from "mixpanel-browser";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { RequestContext } from "../../../../context/RequestContext";
import { UserContext } from "../../../../context/UserContext";
import { useRequest } from "../../../../hooks/useRequest";
import QuillFormFreeRequest from "./components/QuillFormFreeRequest";

export default function PageRequestFree() {
  const [loading, setLoading] = useState(false);
  const { user } = useContext(UserContext);
  const { createNewRequestBusiness } = useRequest();
  const { richieste, setRichieste } = useContext(RequestContext);
  const { t } = useTranslation();
  const handleFreeRequest = (request) => {
    let newRequest = {
      nome: request?.nomeCliente.value + " " + request?.cognomeCliente.value,
      age: request?.anniCliente.value,
      userType: user?.type,
      userName: user?.name,
      nationality: "",
      email: user?.email,
      emailPrenotazione: request?.emailCliente.value,
      image: user?.image,
      userId: user?.id,
      message: "",
      address: user?.indirizzo,
      language: [request?.linguaCliente.value],
      data: new Date().getTime(),
      dataPrenotazione: request?.dataRichiesta.value,
      oraPrenotazione: request?.oraRichiesta.value,
      patientPhone: "",
      latitude: user?.latitude,
      longitude: user?.longitude,
      requestType: "normal", // covid, normal, video
      testType: "",
      testNumber: "",
      fcmToken: typeof user?.fcmToken !== "undefined" ? user?.fcmToken : "",
      hourOfVisit: request?.oraRichiesta?.value,
      dateOfVisit: request?.oraRichiesta?.value,
      timestamp: Date.now(),
      prenotationDate: Date.now(),
      visitRequestDate: new Date(),
      visitType: request?.servizioCliente?.value, // tipologia di professionista
      ora: request?.oraRichiesta.value,
      stato: "Nuova richiesta",
      stato_id: 1,
      color: "#FF7F00",
      active: true,
      businessID: user?.businessID,
    };
    setLoading(true);
    createNewRequestBusiness(newRequest)
      .then(() => {
        setLoading(false);

        setRichieste([newRequest, ...richieste]);
        toast.success(t("general_request_created_success"), {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        mixpanel.track("Nuova Richiesta", { ...newRequest });

        //history.push("/");
      })
      .catch((error) => {
        setLoading(false);

        // mostro erroer
        console.log(error);
        toast.error(t("general_request_created_error"), {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        mixpanel.track("Errore Nuova Richiesta", { ...newRequest });
      });
  };
  return (
    <div>
      <QuillFormFreeRequest onSubmit={handleFreeRequest}></QuillFormFreeRequest>
    </div>
  );
}
