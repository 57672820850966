import React, { useState } from "react";
import { Button, Card, Col, Image, ListGroup, Row } from "react-bootstrap";

import moment from "moment";
import AssistanceProposalModal from "./AssistanceProposalModal";
import { useTranslation } from "react-i18next";
import CaButton from "../CaButton";
import useProfessions from "../../hooks/useProfession";
import { toast } from "react-toastify";
import CaProposalCard from "../CaProposalCard";

export default function ProfessionistProposalList({
  request,
  proposals,
  onProposalSubmitted,
}) {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <Card className="mt-4">
        <Card.Body>
          <Row>
            <Col>
              <h6 className="mb-0">{t("assistence_proposal_title")}</h6>
            </Col>
            <Col className="d-flex justify-content-end">
              <CaButton
                variant="primary"
                onClick={() => {
                  setShowModal(true);
                }}
                text={t("general_send_proposal")}
              ></CaButton>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <CaProposalCard proposals={proposals}></CaProposalCard>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <AssistanceProposalModal
        showModal={showModal}
        handleClose={setShowModal}
        request={request}
        onProposalSubmitted={onProposalSubmitted}
      ></AssistanceProposalModal>
    </>
  );
}
