import React, { useContext, useEffect, useState } from "react";
import { Col, Dropdown, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import CaButton from "../../../components/CaButton";
import CaContainer from "../../../components/CaContainer";
import CaLoader from "../../../components/CaLoader";
import CardRequestProfessionist from "../../../components/professionist/CardRequestProfessionist";
import { UserContext } from "../../../context/UserContext";
import { useRequest } from "../../../hooks/useRequest";
import { useUser } from "../../../hooks/useUser";
import CaMissingRequest from "../../../components/shared/CaMissingRequest";

export default function PageProfessionistDashboard(props) {
  const { user, setUser } = useContext(UserContext);
  const [isTokenFound, setTokenFound] = useState(true);
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const { updateUserById } = useUser();

  const { getPersonRequestInZone } = useRequest();
  const [activeRequest, setActiveRequest] = useState([]);
  // State to store the selected stato_id
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [onlyMy, setOnlyMy] = useState(false);
  const { t } = useTranslation();

  //ottengo le richieste in zona
  useEffect(() => {
    setLoading(true);
    getPersonRequestInZone()
      .then((res) => {
        console.log("getPersonRequestInZone res", res);
        setActiveRequest(res?.data?.response?.visit_request_nella_zona);
      })
      .catch((e) => toast.error(t("general_error_get_request_in_zone")))
      .finally(() => setLoading(false));
  }, [user]);

  // Data for the dropdown options
  const statusOptions = [
    { id: 1, label: t("general_request_status_1") },
    { id: 2, label: t("general_request_status_2") },
    { id: 3, label: t("general_request_status_3") },
    { id: 4, label: t("general_request_status_4") },
    { id: 5, label: t("general_request_status_5") },
    // Add more status options as needed
  ];

  const handleSaveFcmToken = (token) => {
    // se c' è un codice fiscale lo salva
    let modifiedUser = { fcmToken: token };
    updateUserById(user, modifiedUser, "user")
      .then((response) => {
        setUser({ ...response.data });
        /* toast.success("Utente modificato con successo!"); */
      })
      .catch((e) => toast.error(t("error_while_updating_user")));
  };

  //TODO mostrare richiesta solo se utente Business e se è la prima richiesta che fa ( non ci sono altre richieste)

  const handleGoDetails = (request) => {
    if (!request) return;
    history.push("/manage-request/" + request?._id);
  };

  return (
    <CaContainer loading={loading}>
      <Row>
        <Col sm="12">
          {/*  {isTokenFound && <p> Le notifiche sono abilitate 👍🏻 </p>} */}
          {!isTokenFound && <p>{t("error_notification_are_disabled")} ❗️ </p>}
          {!isTokenFound && (
            <CaButton
              className="btn btn-primary"
              onClick={handleSaveFcmToken}
              text={t("enable_notification")}
            ></CaButton>
          )}
        </Col>
      </Row>

      <Row>
        {activeRequest.map((req) => (
          <Col sm={12} md={4}>
            <CardRequestProfessionist
              key={req._id}
              proposal_num={req.proposal_num}
              userId={user?._id}
              address={req.address}
              age={req.patient_age}
              email={req.request_email}
              image={req?.user_id?.image_url}
              language={req.language_id?.language_name}
              nationality={req.nationality}
              patientPhone={req.patient_phone}
              patientName={req.patient_name}
              service_name={req.service_id.service_name}
              patientSurname={req.patient_surname}
              stato_id={req.status_id.status_id}
              stripeId={null}
              visitRequestDate={req.requested_visit_date}
              visitRequestTime={req.requested_visit_time}
              visitType={req.request_type_id?.request_type}
              professionistStatus={user?.status_id?.status_id}
              disabled={false} //TODO user?.status_id?.status_id === 3}
              onViewMore={() => handleGoDetails(req)}
            ></CardRequestProfessionist>
          </Col>
        ))}

        {activeRequest && activeRequest.length === 0 && (
          <CaMissingRequest></CaMissingRequest>
        )}
      </Row>
    </CaContainer>
  );
}
