import React from "react";

import CaContainer from "../../../components/CaContainer";
import withRequestData from "../../../hoc/withRequestData";
import PageAppointment from "../manageAppointment/PageUserManageAppointment";
import ProposalPending from "./components/ProposalPending";
import { useParams } from "react-router-dom";

function PageUserManageRequest({
  request,
  proposal,
  proposalList,
  doctor,
  loading,
}) {
  return (
    <CaContainer backButton={"/"} loading={loading}>
      <ProposalPending
        richiesta={request}
        proposte={proposalList}
      ></ProposalPending>
    </CaContainer>
  );
}

export default withRequestData(PageUserManageRequest);
