import React, { useState } from "react";
import { Card } from "react-bootstrap";
import moment from "moment";
import { useTranslation } from "react-i18next";
import CaFeatureList from "../CaFeatureList";

const CardVisit = ({
  visit_date,
  visit_type,
  visit_time,
  visit_price,
  visit_address,
  practitioner_name,
  practitioner_surname,
  practitioner_invoice,
  currency_code,
  description,
  patient_surname,
  patient_name,
  patient_invoice,
}) => {
  const [showDetails, setShowDetails] = useState(false);
  const { t } = useTranslation();

  return (
    <Card>
      <Card.Body>
        <Card.Title>{visit_type}</Card.Title>
        <Card.Subtitle className="mb-2 text-muted">
          {visit_address}
        </Card.Subtitle>

        <CaFeatureList
          list={[
            {
              label: t("general_doctor_name"),
              value: practitioner_name + " " + practitioner_surname,
              show: practitioner_name,
            },
            {
              label: t("general_patient_name"),
              value: patient_name + " " + patient_surname,
              show: patient_name,
            },
            {
              label: t("general_visit_date"),
              value: moment(visit_date).format("DD/MM/YYYY") + " " + visit_time,
              show: visit_time,
            },
            {
              label: t("general_visit_price"),
              value: visit_price + "€",
              show: visit_price,
            },
            {
              label: t("general_invoice"),
              value: t("general_link_invoice"),
              link: practitioner_invoice,
              show: practitioner_invoice ? true : false,
              icon: "fa fa-file",
            },
          ]}
        ></CaFeatureList>
      </Card.Body>
    </Card>
  );
};

export default CardVisit;
