import React from "react";
import CaButton from "../../../../../components/CaButton";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export default function SubscriptionStep1({
  onChange,
  setType,
  subscriptions,
}) {
  const { t } = useTranslation();
  // scegli il pacchetto

  /*
    silver => 3 richieste al mese per 40 €
    gold => 6 richieste al mese per 70 €
    platinum=> 12 richieste al mese per 120 €
  */
  const handleClick = (scelta) => {
    onChange(2);
    setType(scelta);
  };

  return (
    <>
      <Modal.Body>
        <div className="row">
          {subscriptions &&
            subscriptions.map((sub) => (
              <div className="col-lg-4 col-sm-12">
                <div class="single-service-2 text-center">
                  <h3>{sub?.unit_amount / 100}€</h3>
                  <h6>{sub?.name}</h6>
                  <CaButton
                    text={t("general_choose")}
                    onClick={() => handleClick(sub)}
                  ></CaButton>
                  <h4 className="mt-3">{sub?.description}</h4>
                </div>
              </div>
            ))}
        </div>
      </Modal.Body>
    </>
  );
}
