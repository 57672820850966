import axios from "axios";
import React, { useContext, useState } from "react";
import { Card, ListGroup } from "react-bootstrap";
import CaButton from "../../../components/CaButton";
import ButtonReferral from "../../../components/profiloComponent/ButtonReferral";
import { UserContext } from "../../../context/UserContext";
import { DoctorRequestContext } from "../../../context/DoctorRequestContext";
import CaContainer from "../../../components/CaContainer";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useStripe } from "../../../hooks/useStripe";
import ButtonStripeAdmin from "../../../components/shared/ButtonStripeAdmin";

export default function PageBusinessProfile(props) {
  const { doctorRequest, setDoctorRequest } = useContext(DoctorRequestContext);
  const history = useHistory();
  const { t } = useTranslation();
  const { user } = useContext(UserContext);

  return (
    <CaContainer>
      <div className="layout-px-spacing" style={{ width: "100%" }}>
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <ButtonStripeAdmin
                stripe_id={user?.stripe_id}
              ></ButtonStripeAdmin>
            </div>
            <ButtonReferral
              text={t("general_invite_business")}
              type={"business"}
            ></ButtonReferral>
          </div>
          <div className="row mt-4">
            <div className="col-md-4">
              <Card>
                {user?.image && (
                  <Card.Img
                    variant="top"
                    className="img-fluid"
                    src={user?.image}
                    style={{ maxHeight: 200, objectFit: "cover" }}
                  />
                )}
                <CaButton
                  icon={"fa fa-edit"}
                  variant="primary"
                  style={{ position: "absolute", top: 5, right: 5 }}
                  onClick={() => history.push("/account-edit")}
                  text={t("general_update_profile")}
                ></CaButton>
                <Card.Body className="mt-4">
                  <Card.Title>{user?.name}</Card.Title>
                  <ListGroup>
                    {user?.about && (
                      <ListGroup.Item>
                        <Card.Text>{user?.about}</Card.Text>
                      </ListGroup.Item>
                    )}
                    {user?.email && (
                      <ListGroup.Item>
                        <Card.Text>{user?.email}</Card.Text>
                      </ListGroup.Item>
                    )}
                    {user?.professione && (
                      <ListGroup.Item>
                        <Card.Text>{user?.professione}</Card.Text>
                      </ListGroup.Item>
                    )}
                    {user?.indirizzo && (
                      <ListGroup.Item>
                        <Card.Text>{user?.indirizzo}</Card.Text>
                      </ListGroup.Item>
                    )}
                    {user?.telefono && (
                      <ListGroup.Item>
                        <Card.Text>{user?.telefono}</Card.Text>
                      </ListGroup.Item>
                    )}
                    {user?.sito && (
                      <ListGroup.Item>
                        <Card.Text>{user?.sito}</Card.Text>
                      </ListGroup.Item>
                    )}
                    {user?.instagram && (
                      <ListGroup.Item>
                        <Card.Text>{user?.instagram}</Card.Text>
                      </ListGroup.Item>
                    )}
                    {user?.facebook && (
                      <ListGroup.Item>
                        <Card.Text>{user?.facebook}</Card.Text>
                      </ListGroup.Item>
                    )}
                    {user?.linkedin && (
                      <ListGroup.Item>
                        <Card.Text>{user?.linkedin}</Card.Text>
                      </ListGroup.Item>
                    )}
                  </ListGroup>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </CaContainer>
  );
}
