import axios from "axios";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { CompanyContext } from "../context/CompanyContext";

export function useDoctor() {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const { t } = useTranslation();
  const { company } = useContext(CompanyContext);
  // ottieni il singolo utente
  const getDoctorById = (id) => {
    let token = localStorage.getItem("token");
    if (!token) return alert("Effettua il login per accedere");
    if (!id) return alert("Id dottore non presente");
    //

    var config = {
      method: "get",
      url: baseURL + "/api/doctor/public/" + id,
    };
    return axios(config);
  };

  const signUpDoctor = async (obj) => {
    console.log("practiotioner", obj);

    var data = JSON.stringify(obj);

    var config = {
      method: "post",
      url: process.env.REACT_APP_BASE_URL + "/api/auth/register/practitioner",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    return await axios(config);
  };

  return { getDoctorById, signUpDoctor };
}
