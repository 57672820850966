import { TextField } from "@material-ui/core";
import React, { useContext, useState } from "react";
import { Container, Row, Col, Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useAuthentication } from "../../hooks/useAuthentication";
import { UserContext } from "../../context/UserContext";
import CaInput from "../CaInput";
import CaButton from "../CaButton";
import { useUser } from "../../hooks/useUser";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

export default function ProfileDetail(props) {
  const [popup, setpopup] = useState(false);
  const [oldPwd, setOldPwd] = useState();
  const [newPwd, setNewPwd] = useState();
  const auth = useAuthentication();
  const { user } = useContext(UserContext);
  const history = useHistory();
  const { updatePassword } = useUser();
  const { t } = useTranslation();

  const logout = () => {
    history.push("/");
    auth.logout();
  };
  const handleClose = () => {
    setpopup(false);
  };
  const handleOpen = () => {
    setpopup(true);
  };

  const resetPassword = () => {
    if (oldPwd === newPwd && oldPwd !== "undefined") {
      updatePassword(user.id, newPwd)
        .then((res) => {
          toast.success(t("general_password_update_success"));
        })
        .catch((e) => {
          toast.error(t("general_password_update_error"));
        });
    }
  };
  return (
    <Container>
      <Row>
        <Col className="mt-4 mb-4 ">
          <button
            class="btn btn-border btn--with-shadow mt-4 "
            onClick={logout}
          >
            Logout
          </button>
          <button
            class="btn btn-border btn--with-shadow ml-4 mt-4"
            onClick={handleOpen}
          >
            {t("general_update_password")}
          </button>
        </Col>
      </Row>
      <Modal show={popup} onHide={handleClose} size="md" centered>
        <Modal.Header closeButton>Update Password</Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col>
                <CaInput
                  label={t("general_new_password")}
                  value={oldPwd}
                  type="password"
                  onChange={(e) => setOldPwd(e)}
                ></CaInput>
              </Col>
            </Row>
            <Row>
              <Col>
                <CaInput
                  label={t("general_confirm_new_password")}
                  value={newPwd}
                  type="password"
                  onChange={(e) => setNewPwd(e)}
                ></CaInput>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <CaButton
            className="btn btn-border btn-green c-primary"
            onClick={resetPassword}
            text={t("general_reset_password")}
            disabled={newPwd !== oldPwd || !newPwd || !oldPwd}
          ></CaButton>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}
