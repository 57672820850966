import React, { useEffect, useState } from "react";
import { Form } from "@quillforms/renderer-core";
import "@quillforms/renderer-core/build-style/style.css";

import "./customBlock";
import { t } from "i18next";

export default function QuillForm({ formId, blocks, onSubmit, logo }) {
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <Form
        formId={formId}
        formObj={{
          blocks: blocks,
          theme: {
            font: "Roboto",
            buttonsBgColor: "#01358d",
            questionsColor: "#000",
            answersColor: "#5b6880",
            buttonsFontColor: "#ffff",
            buttonsBorderRadius: 25,
            errorsFontColor: "#fff",
            errorsBgColor: "#f00",
            progressBarFillColor: "#ffff",
            progressBarBgColor: "#01358d",
          },
          messages: {
            "label.button.ok": t("general_ok"), // Default: 'Ok'
            "label.hintText.enter": t("general_enter"), // Default: "press <strong>Enter ↵</strong>",
            "label.hintText.multipleSelection": t(
              "general_choose_as_many_as_you_like"
            ), // Default: "Choose as many as you like"
            "block.dropdown.placeholder": t("general_type_select_option"), // Default: "Type or select an option"
            "block.dropdown.noSuggestions": t("general_no_suggestions"), // Default: "No Suggestions!"
            "block.shortText.placeholder": t("general_type_answer_here"), // Default: "Type your answer here"
            "block.longText.placeholder": t("general_type_answer_here"), // Default: "Type your answer here"
            "block.longText.hint": t("general_hint_space"), // Default: "<strong>Shift ⇧ + Enter ↵</strong> to make a line break"
            "block.number.placeholder": t("general_type_answer_here"), // Default: "Type your answer here"
            "block.email.placeholder": t("general_email_here"), // Default: "Type your email here"
            "block.defaultThankYouScreen.label": t("general_thanks_submitting"), // Default: "Thanks for filling this in.\n\n We will contact you soon"
            "label.hintText.key": t("general_key"), // Default: "Key"
            "label.progress.percent": t(
              "general_percent_competed",
              "progress:percent"
            ), // Default: "{{progress:percent}}% completed"
            "label.errorAlert.required": t("general_error_field_required"), // Default: "This field is required!"
            "label.errorAlert.date": t("general_error_invalid_date"), // Default: "Invalid date!"
            "label.errorAlert.number": t("general_error_numbers_only"), // Default: "Numbers only!"
            "label.errorAlert.selectionRequired": t(
              "general_error_one_selection"
            ), // Default: "Please make at least one selection!"
            "label.errorAlert.email": t("general_error_invalid_email"), // Default: "Invalid email!"
            "label.errorAlert.url": t("general_error_invalid_url"), // Default: "Invalid url!"
            "label.errorAlert.range": t("general_error_range", {
              min: "attribute:min",
              max: "attribute:max",
            }), // Default: "Please enter a number between {{attribute:min}} and {{attribute:max}}"
            "label.errorAlert.minNum": "minNum", // Default: "Please enter a number greater than {{attribute:min}}"
            "label.errorAlert.maxNum": "maxNum", // Default: "Please enter a number lower than {{attribute:max}}"
            "label.errorAlert.maxCharacters": t(
              "general_error_maximum_characted"
            ), // Default: "Maximum characters reached!"
            "label.submitBtn": t("general_submit"), // Default: "Submit"
          },
        }}
        onSubmit={(
          data,
          { completeForm, setIsSubmitting, goToBlock, setSubmissionErr }
        ) => {
          console.log("SUBMITTED", data);
          onSubmit(data);
          completeForm();
        }}
      />
    </div>
  );
}
