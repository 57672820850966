import { Avatar } from "@material-ui/core";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-number-input";
import { toast } from "react-toastify";
import CaButton from "../../../components/CaButton";
import CaContainer from "../../../components/CaContainer";
import CaInput from "../../../components/CaInput";
import CaSelect from "../../../components/CaSelect";
import CaSlider from "../../../components/CaSlider";
import CaGoogleAutocomplete from "../../../components/general/CaGoogleAutocomplete";
import ProfileDetail from "../../../components/profiloComponent/ProfileDetail";
import { UserContext } from "../../../context/UserContext";
import usePractiotioner from "../../../hooks/usePractitioner";
import useProfessions from "../../../hooks/useProfession";
import { useUser } from "../../../hooks/useUser";
import {
  isValidUrl,
  validCodiceFiscale,
  validPartitaIva,
} from "../../../utils/Utils";
import axios from "axios";

export default function PageEditProfessionistProfile() {
  const [name, setname] = useState("");
  const [surname, setSurname] = useState("");
  const [loading, setLoading] = useState(false);
  const [professione, setprofessione] = useState("");
  const [about, setabout] = useState("");
  const [paese, setpaese] = useState("");
  const [indirizzo, setindirizzo] = useState("");
  const [telefono, settelefono] = useState("");
  const [sito, setsito] = useState("");
  const [linkedin, setlinkedin] = useState("");
  const [image, setimage] = useState("");
  const [facebook, setfacebook] = useState("");
  const [twitter, settwitter] = useState("");
  const [instagram, setinstagram] = useState("");
  const [piva, setPiva] = useState("");
  const [codiceFiscale, setCodiceFiscale] = useState("");
  const [raggio, setRaggio] = useState(0);
  const [professionList, setProfessionList] = useState([]);
  const { user, setUser } = useContext(UserContext);
  const { updateUserById } = useUser();
  const { editPractitioner } = usePractiotioner();
  const { t } = useTranslation();
  const { getProfessions } = useProfessions();

  useEffect(() => {
    getProfessions()
      .then((res) => {
        if (res.status === 200) {
          console.log("professions", res.data);
          setProfessionList(res.data?.response);
        }
      })
      .catch((e) => {
        toast.error(t("error_unable_to_recover_professionists"));
      });
  }, []);

  useEffect(() => {
    if (typeof user !== "undefined") {
      console.log("Professionist", user);
      setname(user?.name);
      setSurname(user?.surname);

      setprofessione({
        profession: user?.practitioner_info?.profession_id?.profession,
        _id: user?.practitioner_info?.profession_id?.profession_id,
      });
      setabout(
        user?.practitioner_info?.practitioners_descriptions?.description
      );
      setpaese(user?.user_address?.country);
      setindirizzo(user?.user_address?.street);
      settelefono(user?.telefono);
      setsito(user?.sito);
      setlinkedin(user?.linkedin);
      setfacebook(user?.facebook);
      settwitter(user?.twitter);
      setinstagram(user?.instagram);
      setCodiceFiscale(user?.codiceFiscale);
      setPiva(user?.piva);
      setimage(user?.image);
      setRaggio(user?.user_address?.radius);
    }
  }, [user]);

  const handleSave = (e) => {
    e.preventDefault();
    if (codiceFiscale && !validCodiceFiscale(codiceFiscale))
      return toast.error(t("error_fiscal_code_not_valid"));
    if (piva && !validPartitaIva(piva))
      return toast.error(t("error_piva_not_valid"));
    if (sito && !isValidUrl(sito))
      return toast.error(t("error_website_not_valid"));
    let modifiedUser = {
      name: name,
      surname: surname,
      professione: professione?._id,
      telefono: telefono,
      codiceFiscale: codiceFiscale,

      piva: piva,
      website: sito,
      linkedin: linkedin,
      twitter: twitter,
      facebook: facebook,
      instagram: instagram,
      descrizione: about,

      indirizzo: indirizzo?.address,
      citta: indirizzo?.city,
      cittaShort: indirizzo?.cityShort,
      codicePostale: indirizzo?.postal_code,
      paese: indirizzo?.country,
      paeseShort: indirizzo?.countryShort,
      latitude: indirizzo?.lat,
      longitude: indirizzo?.lng,
      raggio: raggio,
    };
    setLoading(true);
    editPractitioner(user?.practitioner_info._id, modifiedUser)
      .then((res) => {
        console.log("editPractitioner", res);
        toast.success("general_practitioner_edited_success");
      })
      .catch((e) => {
        toast.error("general_error_edit_practitioner");
      })
      .finally(() => setLoading(false));
  };
  const fileInputRef = useRef();

  const handleClick = (e) => {
    e.preventDefault();
    fileInputRef.current.click();
  };

  const handleFileChange = async (event) => {
    event.preventDefault();
    const file = event.target.files[0];

    // Create a FormData object and append the file to it
    const formData = new FormData();
    formData.append("image", file);

    try {
      // Send the FormData object to the server using fetch
      const response = await axios.post(
        process.env.REACT_APP_BASE_URL + "/api/upload",
        formData
      );

      console.log("response upload", response);

      if (response.status !== 200) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      editPractitioner(user?.practitioner_info._id, {
        image_url: response.data.fileUrl,
      })
        .then((res) => {
          toast.success(t("general_practitioner_edited_success"));
        })
        .catch((e) => {
          toast.error(t("general_error_edit_practitioner"));
        })
        .finally(() => setLoading(false));
      // Do something with the response data, e.g. display the uploaded image
    } catch (error) {
      console.error(error);
      toast.error("Error uploading file");
    }
  };

  return (
    <CaContainer backButton={"/account"}>
      <Container fluid>
        <Row className="mt-4">
          <Col>
            <Form>
              <Row>
                <Col sm={12} md={6}>
                  <Form.Group className="mb-3 ">
                    <Form.Label>{t("general_image")}</Form.Label>
                    <Avatar className="mb-2" src={image}></Avatar>

                    <CaButton
                      variant="primary"
                      onClick={handleClick}
                      text={t("general_upload_image")}
                    ></CaButton>
                    <input
                      type="file"
                      accept="image/*"
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                    />
                  </Form.Group>
                </Col>
                <Col sm={12} md={6}>
                  <Form.Group className="mb-3 ">
                    <Form.Label>
                      {t("gerenal_raggio_azione")}: <b>{raggio} Km</b>
                    </Form.Label>

                    <CaSlider
                      onChange={(value) => setRaggio(value)}
                      initialValue={raggio}
                    ></CaSlider>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Form.Group className="mb-3 col-sm-12 col-md-4">
                  <Form.Label>{t("general_name")}</Form.Label>
                  <CaInput
                    value={name}
                    onChange={(val) => setname(val)}
                  ></CaInput>
                </Form.Group>
                <Form.Group className="mb-3 col-sm-12 col-md-4">
                  <Form.Label>{t("general_surname")}</Form.Label>
                  <CaInput
                    value={surname}
                    onChange={(val) => setSurname(val)}
                  ></CaInput>
                </Form.Group>
                <Form.Group className="mb-3 col-sm-12 col-md-4">
                  <Form.Label>{t("general_profession")}</Form.Label>
                  <CaSelect
                    options={professionList}
                    getOptionLabel={(option) =>
                      t("general_profession_" + option.profession)
                    }
                    selected={professione}
                    getOptionValue={(option) => option._id}
                    onChange={(v) => {
                      if (v) {
                        setprofessione(v.label);
                        return;
                      }
                      setprofessione(null);
                    }}
                  ></CaSelect>
                </Form.Group>
              </Row>

              <Row>
                <Form.Group className="mb-3 col-sm-12 col-md-6">
                  <Form.Label>{t("general_fiscal_code")}</Form.Label>
                  <CaInput
                    value={codiceFiscale}
                    onChange={(val) => setCodiceFiscale(val)}
                    error={!validCodiceFiscale(codiceFiscale)}
                  ></CaInput>
                </Form.Group>
                <Form.Group className="mb-3 col-sm-12 col-md-6">
                  <Form.Label>{t("general_piva")}</Form.Label>
                  <CaInput
                    value={piva}
                    onChange={(val) => setPiva(val)}
                    type={"number"}
                    error={!validPartitaIva(piva)}
                  ></CaInput>
                </Form.Group>
              </Row>

              <Row>
                <Form.Group className="mb-3 col-sm-12 col-md-6">
                  <Form.Label>{t("general_about_me")}</Form.Label>
                  <CaInput
                    value={about}
                    onChange={(val) => setabout(val)}
                  ></CaInput>
                </Form.Group>

                <Form.Group className="mb-3 col-sm-12 col-md-6">
                  <CaGoogleAutocomplete
                    label={t("general_address")}
                    value={indirizzo}
                    placeholder={indirizzo}
                    onSelectedPlace={(address) => setindirizzo(address)}
                    enabled={true}
                  ></CaGoogleAutocomplete>
                </Form.Group>
              </Row>

              <Row>
                <Form.Group className="mb-3 col-sm-12 col-md-6">
                  <Form.Label>{t("general_nation")}</Form.Label>
                  <CaSelect
                    options={countries}
                    selected={countries.filter(
                      (option) => option.label === paese
                    )}
                    onChange={(val) => {
                      if (val) {
                        setpaese(val?.label);
                      } else {
                        setpaese(null);
                      }
                    }}
                  ></CaSelect>
                </Form.Group>

                <Form.Group className="mb-3 col-sm-12 col-md-6">
                  <Form.Label>{t("general_phone")}</Form.Label>
                  <PhoneInput
                    style={{
                      width: "100%",
                      fontSize: "1rem",
                      fontWeight: 400,
                      lineHeight: 1.5,
                      padding: 10,
                      border: "1px solid lightgray",
                      borderRadius: 5,
                    }}
                    defaultCountry="IT"
                    value={telefono}
                    onChange={(val) => settelefono(val)}
                    rules={{ required: true }}
                  ></PhoneInput>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group className="mb-3 col-sm-12 col-md-6">
                  <Form.Label>{t("general_website")}</Form.Label>
                  <CaInput
                    value={sito}
                    onChange={(val) => setsito(val)}
                    type="text"
                  ></CaInput>
                </Form.Group>
                <Form.Group className="mb-3 col-sm-12 col-md-6">
                  <Form.Label>{t("general_email")}</Form.Label>
                  <CaInput value={user?.email} disabled type="text"></CaInput>
                </Form.Group>
              </Row>

              <Row>
                <Form.Group className="mb-3 col-sm-12 col-md-6">
                  <Form.Label>Linkedin </Form.Label>
                  <CaInput
                    value={linkedin}
                    onChange={(val) => setlinkedin(val)}
                    type="text"
                  ></CaInput>
                </Form.Group>
                <Form.Group className="mb-3 col-sm-12 col-md-6">
                  <Form.Label>Twitter </Form.Label>
                  <CaInput
                    value={twitter}
                    onChange={(val) => settwitter(val)}
                    type="text"
                  ></CaInput>
                </Form.Group>
                <Form.Group className="mb-3 col-sm-12 col-md-6">
                  <Form.Label>Facebook </Form.Label>
                  <CaInput
                    value={facebook}
                    onChange={(val) => setfacebook(val)}
                    type="text"
                  ></CaInput>
                </Form.Group>
              </Row>
            </Form>
          </Col>
        </Row>
        <CaButton
          isLoading={loading}
          text={t("general_save")}
          onClick={handleSave}
          className="btn btn-primary mt-4"
        ></CaButton>
      </Container>
      <ProfileDetail></ProfileDetail>
    </CaContainer>
  );
}

const countries = [
  { value: "US", label: "United States" },
  { value: "CA", label: "Canada" },
  { value: "MX", label: "Mexico" },
  { value: "GB", label: "United Kingdom" },
  { value: "FR", label: "France" },
  { value: "DE", label: "Germany" },
  { value: "JP", label: "Japan" },
  { value: "KR", label: "South Korea" },
  { value: "IT", label: "Italy" },
];
