import React from "react";
import { Card, Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import CaButton from "./CaButton";
import CaFeatureList from "./CaFeatureList";

const CaVisitCard = ({
  image,
  name,
  surname,
  role,
  latitude,
  longitude,
  descrizione,
  cell,
  email,
  list,
  practitionerID,
  userID,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleGoChat = () => {
    history.push("/chat/" + practitionerID);
  };
  return (
    <Card>
      <Card.Header className="d-flex align-items-center">
        <Image
          src={image || "https://via.placeholder.com/50"}
          roundedCircle
          fluid
          style={{ width: 70, height: 70, objectFit: "cover" }}
          alt="avatar"
          className="me-3"
        />
        <Card.Title>{name + " " + surname}</Card.Title>
        <Card.Subtitle className="mb-2 text-muted">{role}</Card.Subtitle>
      </Card.Header>

      {list && (
        <Card.Body>
          <CaFeatureList list={list}></CaFeatureList>
        </Card.Body>
      )}

      <Card.Footer>
        <CaButton
          variant="link"
          href={"tel:" + cell}
          icon="fa fa-phone"
          text={"Call"}
        ></CaButton>
        <CaButton
          variant="link"
          href={"mailto:" + email}
          icon="fa fa-envelope"
          text={"Email"}
        ></CaButton>
        <CaButton
          variant="link"
          icon="fa fa-comments"
          text={"Chat"}
          onClick={handleGoChat}
        ></CaButton>
      </Card.Footer>
    </Card>
  );
};

export default CaVisitCard;
