import axios from "axios";
// una lista di tutti i commenti

export function useCompany() {
  const baseURL = process.env.REACT_APP_BASE_URL;

  /**
   * Ottieni la lista dei business partner
   * @returns
   */
  const getCompanyConfig = (companySlag) => {
    var config = {
      method: "get",
      url: baseURL + "/api/user/companies/slag/" + companySlag,
    };
    console.log("config", config);
    return axios(config);
  };

  return { getCompanyConfig };
}
