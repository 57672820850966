import React, { useContext, useEffect } from "react";

import mixpanel from "mixpanel-browser";
import BusinessRegStepper from "./components/BusinessRegStepper";
import { CompanyContext } from "../../../context/CompanyContext";

const PageSignUpBusiness = (props) => {
  const { company } = useContext(CompanyContext);

  useEffect(() => {
    mixpanel.track("Page - SignUp");
  }, []);

  return (
    <div>
      <div className="form">
        <div className="form-container outer">
          <div className="form-form">
            <div className="form-form-wrap">
              <div className="form-container">
                <div className="form-content">
                  <img
                    alt="logo"
                    src={company?.configuration?.logo}
                    style={{ maxWidth: 200 }}
                  />

                  <BusinessRegStepper></BusinessRegStepper>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageSignUpBusiness;
