import React from "react";
import CaNavBar from "./CaNavBar";
import CaSideBar from "./CaSideBar";
import { Col, Container, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CaButton from "./CaButton";
import CaLoader from "./CaLoader";
import CaBottomNavigation from "./CaBottomNavigation";
import { isMobile, isDesktop } from "react-device-detect";

export default function CaContainer({ backButton, children, loading, title }) {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <div className="d-flex" style={{ overflow: "hidden" }}>
      <div style={{ display: "flex", minHeight: "100vh", height: "auto" }}>
        {!isMobile && <CaSideBar></CaSideBar>}
        {isMobile && <CaBottomNavigation></CaBottomNavigation>}
      </div>
      <div className="w-100">
        <CaNavBar></CaNavBar>

        <Container
          fluid
          style={{
            height: isMobile && "84vh",
            overflowY: "scroll",
            overflowX: "hidden",
          }}
          className="p-2"
        >
          {backButton && (
            <Row className="my-3 ">
              <Col>
                <CaButton
                  text={t("general_go_back")}
                  onClick={() => history.push(backButton)}
                  icon="fa fa-arrow-left"
                  variant="link"
                  className="btn btn-link"
                ></CaButton>
              </Col>
            </Row>
          )}
          <CaLoader loading={loading}></CaLoader>

          {!loading && title && (
            <Row className="my-4">
              <Col>
                <h5>{title}</h5>
              </Col>
            </Row>
          )}

          {children}
        </Container>
      </div>
    </div>
  );
}
