import axios from "axios";
import React, { createContext, useState, useEffect, useContext } from "react";
import { UserContext } from "./UserContext.js";
import ObserveNotification from "../utils/ObserveNotification.js";

export const RequestContext = createContext();

export const RequestProvider = (props) => {
  const [richieste, setRichieste] = useState([]);
  const [loadingRequest, setLoadingRequest] = useState(false);
  const { user, token } = useContext(UserContext);

  useEffect(() => {
    if (user) {
      apiCallRequestContext();
      ObserveNotification.subscribe(apiCallRequestContext);
    }
  }, [user]);

  const apiCallRequestContext = () => {
    let userID = localStorage.getItem("userID");
    var config = {
      method: "get",
      url:
        process.env.REACT_APP_BASE_URL +
        "/api/requests/" +
        user?.type +
        "/" +
        userID +
        "/user",
      headers: {
        Authorization: "Bearer",
      },
    };

    setLoadingRequest(true);

    axios(config)
      .then(function (response) {
        console.log("Request Context: ", response);
        setRichieste(response?.data);
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(() => setLoadingRequest(false));
  };

  return (
    <RequestContext.Provider
      value={{ richieste, setRichieste, apiCallRequestContext, loadingRequest }}
    >
      {props.children}
    </RequestContext.Provider>
  );
};
