import React, { useContext } from "react";
import { Spinner } from "react-bootstrap";
import loader from "../img/loader.gif";
import { DoctorRequestContext } from "./../context/DoctorRequestContext";

export default function Spin() {
  const { doctorRequest, setDoctorRequest } = useContext(DoctorRequestContext);

  return (
    <div>
      {doctorRequest.spinner && (
        <div className="loading">
          <div className=" center spinner">
            <h4 style={{ color: "white" }} className="mr-4">
              Loading...
            </h4>
            <img src={loader}></img>
          </div>
        </div>
      )}
    </div>
  );
}
