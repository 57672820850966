import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import CaButton from "../CaButton";
import ReferralFormBusiness from "../business/ReferralFormBusiness";
import ReferralFormUser from "../user/ReferralFormUser";
import { useTranslation } from "react-i18next";

export default function ButtonReferral({ text, type }) {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const handleClose = (e) => {
    setShow(false);
  };
  const handleShow = (e) => {
    e.preventDefault();
    setShow(true);
  };

  return <></>;

  return (
    <>
      <CaButton
        text={text}
        variant="link"
        onClick={handleShow}
        loading={loading}
      ></CaButton>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t("modal_invite_title")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row">
              <div className="col">
                {type === "business" && (
                  <ReferralFormBusiness onSubmit={() => setShow(false)} />
                )}
                {type === "person" && (
                  <ReferralFormUser onSubmit={() => setShow(false)} />
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
