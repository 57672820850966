import { TextField } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import React, { useContext, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import PhoneInput, { parsePhoneNumber } from "react-phone-number-input";
import { toast } from "react-toastify";
import CaButton from "../../../../../components/CaButton";
import { BusinessRegContext } from "../../../../../context/BusinessRegContext";
import { useAuthentication } from "../../../../../hooks/useAuthentication";

export default function StepOTPBusinessReg() {
  const { businessReg, setBusinessReg } = useContext(BusinessRegContext);
  const auth = useAuthentication();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const handleTermsCondition = (event) => {
    let newState = Object.assign({}, businessReg);
    newState.termsCondition = event.target.checked;
    setBusinessReg(newState);
  };

  const handlePrivacy = (event) => {
    let newState = Object.assign({}, businessReg);
    newState.privacy = event.target.checked;
    setBusinessReg(newState);
  };
  const handleOTP = (event) => {
    event.preventDefault();
    let newState = Object.assign({}, businessReg);
    newState.otp = event.target.value;
    setBusinessReg(newState);
  };
  const handleTelefono = (value) => {
    let newState = Object.assign({}, businessReg);
    newState.telefono = value;
    setBusinessReg(newState);
  };
  const handleSendOTP = (e) => {
    e.preventDefault();
    const phoneNumber = parsePhoneNumber(businessReg.telefono);
    setLoading(true);

    auth
      .sendOtp(phoneNumber.countryCallingCode, phoneNumber.nationalNumber)
      .then((response) => {
        console.log("response", response);
        setLoading(false);
        if (response.status === 200) {
          let newState = Object.assign({}, businessReg);
          newState.stepOtp = 2;
          setBusinessReg(newState);
          toast.success(t("otp_code_send"));
        }
      })
      .catch((error) => {
        toast.error(t("otp_code_send_error"));
        setLoading(false);
      });
  };
  return (
    <Row className="mt-4">
      {businessReg.stepOtp === 1 && (
        <>
          <Col
            sm={12}
            style={{ display: businessReg.stepOtp === 1 ? "block" : "none" }}
          >
            <div className="center">
              <a href={process.env.REACT_TERMS_CONDITION_LINK}>
                {t("general_accept_terms_conditions")}
              </a>

              <Checkbox
                checked={businessReg.termsCondition}
                onChange={handleTermsCondition}
                required
                color="primary"
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
            </div>

            <div className="center">
              <a href={process.env.REACT_PRIVACY_POLICY_LINK}>
                {t("general_accept_privacy_policy")}
              </a>

              <Checkbox
                checked={businessReg.privacy}
                onChange={handlePrivacy}
                required
                color="primary"
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
            </div>
          </Col>
          <Col sm={12} className="mt-3">
            <PhoneInput
              style={{
                width: "100%",
                fontSize: "1rem",
                fontWeight: 400,
                lineHeight: 1.5,
                padding: 10,
                border: "1px solid lightgray",
                borderRadius: 5,
                display: "flex",
              }}
              value={businessReg.telefono}
              onChange={(val) => handleTelefono(val)}
              rules={{ required: true }}
              defaultCountry="IT"
              disabled={!businessReg.termsCondition || !businessReg.privacy}
            ></PhoneInput>
            <CaButton
              text={t("general_send_otp")}
              onClick={handleSendOTP}
              isLoading={loading}
              disabled={!businessReg.telefono}
              className={"btn btn-primary mt-3"}
            ></CaButton>
          </Col>
        </>
      )}

      <div
        className="col-sm-12 mb-3"
        style={{ display: businessReg.stepOtp === 2 ? "block" : "none" }}
      >
        <TextField
          className="single-input"
          variant="outlined"
          /* value={email} */
          onChange={(e) => handleOTP(e)}
          text={t("general_otp")}
          type="number"
          value={businessReg.otp}
          required
        ></TextField>
        {/*  <CaButton
          className="btn btn-primary mt-3 c-white"
          isLoading={loading}
          text="Verifica OTP"
          disabled={!otp}
          onClick={handleSubmit}
        ></CaButton> */}
      </div>
    </Row>
  );
}
