import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import CaButton from "../../../components/CaButton";

import ButtonReferral from "../../../components/profiloComponent/ButtonReferral";
import CardRequest from "../../../components/profiloComponent/CardRequest";
import { RequestContext } from "../../../context/RequestContext";
import { useUser } from "../../../hooks/useUser";
import doc from "../../../img/svg/doctors.svg";
import { UserContext } from "../../../context/UserContext";
import CaContainer from "../../../components/CaContainer";
import ButtonRequestBusiness from "../../../components/business/ButtonRequestBusiness";
import { useTranslation } from "react-i18next";

export default function PageBusinessDashboard(props) {
  const { richieste, apiCallRequestContext } = useContext(RequestContext);
  const { user, setUser } = useContext(UserContext);
  const [isTokenFound, setTokenFound] = useState(true);
  const history = useHistory();
  const { t } = useTranslation();
  const { updateUserById } = useUser();

  useEffect(() => {
    if (user) {
      apiCallRequestContext();
    }
  }, [user]);

  useEffect(() => {
    // se c' è un utente e l' utente non ha il token
    if (user && !user?.fcmToken) {
      askForPermissioToReceiveNotifications();
    }
  }, [user]);

  const askForPermissioToReceiveNotifications = async () => {
    if (Notification.permission === "granted") return;
    if (Notification.permission === "denied") return;
    // altrimenti l' utente non ha ancora risposto
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        if ("serviceWorker" in navigator) {
          navigator.serviceWorker
            .register("./firebase-messaging-sw.js", {
              scope: "./firebase-messaging-sw.js",
            })
            .then((registration) => {
              handleSaveFcmToken();
            })
            .catch((err) => console.log("Service Worker Error", err));
        } else {
          alert("Push not supported.");
        }
      }
    });
  };

  const handleSaveFcmToken = (token) => {
    // se c' è un codice fiscale lo salva
    let modifiedUser = { fcmToken: token };
    updateUserById(user, modifiedUser, "business")
      .then((response) => {
        setUser({ ...response.data });
        /* toast.success("Utente modificato con successo!"); */
      })
      .catch((e) => toast.error(t("error_while_updating_user")));
  };

  let richiesteView = () => {
    if (richieste) {
      let sortedRequests = richieste.sort((a, b) => a.timestamp - b.timestamp);
      const filteredRequests = sortedRequests.filter(
        (obj) => obj.stato_id !== 4
      );

      return filteredRequests.map((req) => (
        <CardRequest request={req}></CardRequest>
      ));
    } else {
      return <></>;
    }
  };

  return (
    <CaContainer>
      <Container fluid>
        <Row>
          <div className="col-12  d-flex">
            <ButtonRequestBusiness></ButtonRequestBusiness>

            <CaButton
              className="btn btn-secondary ml-3"
              icon="fa fa-star"
              text={t("general_free_request")}
              onClick={() => history.push("/request-free")}
            ></CaButton>
          </div>
        </Row>
        <Row>
          <Col sm="12">
            {/*  {isTokenFound && <p> Le notifiche sono abilitate 👍🏻 </p>} */}
            {!isTokenFound && <p> {t("notifications_are_not_enabled")} ❗️ </p>}
            {!isTokenFound && (
              <button className="btn btn-primary" onClick={handleSaveFcmToken}>
                {t("enable_notification")}
              </button>
            )}
            <div className="mt-2">
              <ButtonReferral
                text={t("general_invite_business")}
                type={"business"}
              ></ButtonReferral>
            </div>
          </Col>
        </Row>
        <Row>
          {richiesteView()}
          {richieste && richieste.length === 0 && (
            <div className="container">
              <div className="row mt-4">
                <div className="col-12 center">
                  <h4 style={{ textAlign: "center" }}>
                    {t("no_available_request_now")}
                  </h4>
                </div>
                <div className="col-12 center">
                  <img
                    alt="img"
                    src={doc}
                    style={{ width: 200, height: 200 }}
                  ></img>
                </div>
              </div>
            </div>
          )}
        </Row>
      </Container>
    </CaContainer>
  );
}
