import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import CaButton from "../CaButton";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
export default function ProfessionistAcceptedDoctorActions({ request }) {
  const history = useHistory();
  const { t } = useTranslation();
  function callPhoneNumber(phoneNumber) {
    const telLink = `tel:${phoneNumber}`;
    window.open(telLink, "_blank");
  }

  const handleGoCheckOut = () => {
    history.push("/checkout-request", { request: request });
  };
  return (
    <Card className="mt-4">
      <Card.Body>
        <Row>
          <Col sm={12} md={4}>
            <h4>
              {t("general_visit_hour")}: {request?.hourOfVisit}
            </h4>
            <h4>
              {t("general_visit_date")}: {request?.dateOfVisit}
            </h4>
          </Col>
          <Col sm={12} md={8}>
            <Row>
              <Col sm={12} md={4} className="mt-2">
                <CaButton
                  variant="primary"
                  icon="fas fa-phone me-2"
                  text={t("general_call")}
                  onClick={() => callPhoneNumber(request.patientPhone)}
                ></CaButton>
              </Col>
              <Col sm={12} md={4} className="mt-2">
                <CaButton
                  variant="secondary"
                  icon="fas fa-comment me-2"
                  text={t("general_message")}
                ></CaButton>
              </Col>
              <Col sm={12} md={4} className="mt-2">
                <CaButton
                  variant="danger"
                  icon="fas fa-stop-circle me-2"
                  text={t("general_finish_visit")}
                  onClick={() => handleGoCheckOut()}
                ></CaButton>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}
