import AccessTime from "@material-ui/icons/AccessTime";
import ChatBubble from "@material-ui/icons/ChatBubble";
import Email from "@material-ui/icons/Email";
import LocationOn from "@material-ui/icons/LocationOn";
import Person from "@material-ui/icons/Person";
import Phone from "@material-ui/icons/Phone";
import moment from "moment";
import React, { useContext } from "react";
import { Badge, Card, Col, ListGroup, Row } from "react-bootstrap";
import { UserContext } from "../../context/UserContext";
import CaMapV2 from "../CaMapV2";
import ProfessionistAcceptedDoctorActions from "./ProfessionistAcceptedDoctorActions";
import ProfessionistProposalList from "./ProfessionistProposalList";
import { useTranslation } from "react-i18next";
import CaRequestStatusBadge from "../general/CaRequestStatusBadge";
import { Language } from "@material-ui/icons";

const ProfessionistRequestDetail = ({
  request,
  proposals,
  onProposalSubmitted,
}) => {
  const { user } = useContext(UserContext);
  const { t } = useTranslation();
  if (!request) return <>{t("error_request_not_valid")}</>;

  const handleOpenMaps = (latitude, longitude) => {
    const mapUrl = `https://www.google.com/maps?q=${latitude},${longitude}`;
    window.open(mapUrl, "_blank");
  };

  return (
    <div className="mt-4">
      <Card>
        <Card.Header>
          <CaRequestStatusBadge
            style={{ position: "absolute", top: 5, right: 5 }}
            status_id={request?.status_id?.status_id}
          ></CaRequestStatusBadge>
          <h5 className="ms-3">
            {t("general_service_name")}: {request?.service_id?.service_name}
          </h5>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col md={4} className="text-center noMobile">
              <CaMapV2
                center={[request?.latitude, request?.longitude]}
                zoom={13}
                marker={[
                  {
                    latitude: request?.latitude,
                    longitude: request?.longitude,
                  },
                ]}
                style={{ height: "100%" }}
              ></CaMapV2>
            </Col>
            <Col md={8} sm={12}>
              <ListGroup>
                <ListGroup.Item>
                  <AccessTime /> <strong>{t("general_visit_date")}:</strong>{" "}
                  {moment(request?.requested_visit_date).format("DD/MM/YYYY")}{" "}
                  at
                  {request?.requested_visit_time}
                </ListGroup.Item>
                <ListGroup.Item>
                  <LocationOn /> <strong>{t("general_address")}:</strong>{" "}
                  <a
                    style={{ cursor: "pointer", textDecoration: "underline" }}
                    onClick={() =>
                      handleOpenMaps(request?.latitude, request?.longitude)
                    }
                  >
                    {request?.address}
                  </a>
                  <br />
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>{t("general_request_type")}:</strong>{" "}
                  {t(
                    "general_request_type_" +
                      request.request_type_id.request_type
                  )}
                </ListGroup.Item>
                <ListGroup.Item>
                  <Person /> <strong>{t("general_patient_name")}:</strong>{" "}
                  {request?.patient_name + " " + request?.patient_surname}{" "}
                  <br />
                </ListGroup.Item>
                <ListGroup.Item>
                  <Language /> <strong>{t("general_patient_language")}:</strong>{" "}
                  {request?.language_id?.language_name}
                  <br />
                </ListGroup.Item>
                <ListGroup.Item>
                  <Person /> <strong>{t("general_patient_age")}:</strong>{" "}
                  {request?.patient_age} <br />
                </ListGroup.Item>
                <ListGroup.Item>
                  <ChatBubble /> <strong>{t("general_message")}:</strong>{" "}
                  {request?.message}
                  <br />
                </ListGroup.Item>

                <ListGroup.Item>
                  <Email /> <strong>{t("general_email")}:</strong>
                  <a
                    style={{ cursor: "pointer", textDecoration: "underline" }}
                    href={"mailto:" + request?.request_email + ""}
                  >
                    {request?.request_email}
                  </a>
                  <br />
                </ListGroup.Item>
                <ListGroup.Item>
                  <Phone /> <strong>{t("general_phone")}:</strong>
                  <a
                    style={{ cursor: "pointer", textDecoration: "underline" }}
                    href={"tel:" + request?.patient_phone + ""}
                  >
                    {request?.patient_phone}
                  </a>
                  <br />
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      {request?.acceptedDoctorId === user.id && (
        <ProfessionistAcceptedDoctorActions
          request={request}
        ></ProfessionistAcceptedDoctorActions>
      )}

      {/* Map to show patient's location */}

      {/* List of Assistance Proposals */}
      {request?.acceptedDoctorId !== user.id && (
        <ProfessionistProposalList
          request={request}
          proposals={proposals}
          onProposalSubmitted={onProposalSubmitted}
        ></ProfessionistProposalList>
      )}
    </div>
  );
};

export default ProfessionistRequestDetail;
