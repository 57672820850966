import axios from "axios";
// una lista di tutti i commenti

export default function usePractiotioner() {
  const baseURL = process.env.REACT_APP_BASE_URL;

  const editPractitioner = (practiotionerID, data) => {
    let config = {
      method: "put",
      url:
        baseURL +
        "/api/user/practitioners/edit-practitioner/" +
        practiotionerID,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    return axios(config);
  };

  return {
    editPractitioner,
  };
}
