import React, { useContext, useState } from "react";
import { DoctorRequestContext } from "../../../context/DoctorRequestContext";
import agreement from "../../../img/svg/agreement.svg";
import { default as deal, default as rocket } from "../../../img/svg/deal.svg";
import axios from "axios";
import { toast } from "react-toastify";
import CaButton from "../../../components/CaButton";
import CaContainer from "../../../components/CaContainer";
import CaModalSubscription from "./components/CaModalSubscription";
import { UserContext } from "../../../context/UserContext";
import { useTranslation } from "react-i18next";

export default function PageBecamePartner() {
  const { doctorRequest, setDoctorRequest } = useContext(DoctorRequestContext);
  const { user, setUser, token } = useContext(UserContext);
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation();

  const handleCancelSubscription = () => {
    // apre una modale che contiene un form dove inserire la carta  e di abbonarsi
    let newState = Object.assign({}, doctorRequest);
    newState.spinner = true;
    setDoctorRequest(newState);

    if (!token) return console.error("idToken non presente");

    var config = {
      method: "post",
      url:
        process.env.REACT_APP_BASE_URL +
        "/api/business/cancel-subscription-business",
      headers: {
        "Content-Type": "application/json",
        token: token && token,
      },
      data: {
        userId: user.id,
        stripeId: user.stripeId,
        userEmail: user.email,
      },
    };
    axios(config)
      .then(function (response) {
        console.log(response);
        let newState = Object.assign({}, doctorRequest);
        newState.spinner = false;
        setDoctorRequest(newState);
        let newStates = Object.assign({}, user);
        newStates.partner = false;
        setUser(newState);
        toast.success(t("general_subscription_removed"));
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
        toast.error(t("general_error_while_removing_subscription"));
        let newState = Object.assign({}, doctorRequest);
        newState.spinner = false;
        setDoctorRequest(newState);
      });
  };

  //create-subscription-business

  return (
    <CaContainer>
      {!user?.partner ? (
        <div className="row">
          <div className="col-lg-4 col-sm-12">
            <div class="single-service-2 text-center">
              <h4>{t("subscription_page_title")}</h4>
              <div className="mb-3">
                <img
                  src={agreement}
                  style={{ height: 200, width: 200 }}
                  alt="contact"
                ></img>
              </div>

              <CaButton
                icon={"fas fa-rocket"}
                text="Abbonati"
                onClick={() => setShowModal(true)}
                disabled={user?.partner}
                className={"btn btn-primary mt-3 mb-3"}
              ></CaButton>
              <h6>{t("what_you_get")}:</h6>

              <div class="riyaqas-check-list">
                <i className="fa fa-check"></i>
                <span>{t("subscription_page_feature_free_booking")}</span>
              </div>
              <div class="riyaqas-check-list">
                <i className="fa fa-check"></i>
                <span>{t("subscription_page_feature_commissions")}</span>
              </div>

              <div class="riyaqas-check-list">
                <i className="fa fa-check"></i>
                <span>{t("subscription_page_feature_promotions_events")}</span>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-12">
            <div class="single-service-2 text-center">
              <h4>{t("subscription_page_buy_other_requests")}</h4>
              <div className="mb-3">
                <img
                  src={rocket}
                  style={{ height: 200, width: 200 }}
                  alt="contact"
                ></img>
              </div>
              <a
                href="mailto:info@caducea.it?subject=Richiesta pacchetto richieste"
                className="btn btn-primary mt-3"
              >
                <i class="fas fa-envelope me-3"></i>
                {t("general_contact_us")}
              </a>
              <p className="mt-3">
                {t("subscription_page_buy_other_requests_message")}
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-sm-12">
            <div class="single-service-2 text-center">
              <h4>{t("subscription_page_credit_system_title")}</h4>
              <div className="mb-3">
                <img
                  src={deal}
                  style={{ height: 200, width: 200 }}
                  alt="contact"
                ></img>
              </div>
              <a
                href="mailto:info@caducea.it?subject=Richiesta Abbonamento a crediti"
                className="btn btn-primary mt-3"
              >
                <i class="fas fa-envelope me-3"></i>
                {t("general_contact_us")}
              </a>
              <p className="mt-3">
                {t("subscription_page_credit_system_message")}
              </p>
            </div>
          </div>
        </div>
      ) : (
        <>
          <CaButton
            className="btn btn-primary"
            onClick={handleCancelSubscription}
            text={t("general_remove_subscription")}
          ></CaButton>
        </>
      )}

      <CaModalSubscription
        setShowModal={setShowModal}
        showModal={showModal}
      ></CaModalSubscription>
    </CaContainer>
  );
}
