import React, { useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { DoctorRequestContext } from "../../../../context/DoctorRequestContext";
import { useTranslation } from "react-i18next";

export default function StepFour(props) {
  const { doctorRequest } = useContext(DoctorRequestContext);
  let prenotationDate = new Date(doctorRequest.timeStart);
  const hours = prenotationDate.getHours();
  const minutes = prenotationDate.getMinutes();
  const { t } = useTranslation();

  return (
    <Container>
      {/*   <LinearProgress variant="determinate" value={100} /> */}
      <Row>
        <Col xs="12" sm="12" lg="6">
          <h4 className="mt-4  mb-4 text-center">
            {t("request_summary_title")}
          </h4>
          <b style={{ marginTop: 40 }}>{t("request_summary_subtitle")}</b>
          <div className="mt-4">
            <i class="fa fa-user"></i>
            <span className="ms-2">
              {t("general_patient_name")}: {doctorRequest.name}
            </span>
          </div>
          {/*   <div className="mt-2">
            <i class="fa fa-flag"></i>
            <span className="ms-2">
              nationality: {doctorRequest.nationality}
            </span>
          </div> */}
          <div className="mt-2">
            <i class="fa fa-envelope"></i>
            <span className="ms-2">
              {t("general_email")}: {doctorRequest.email}
            </span>
          </div>
          <div className="mt-2">
            <i class="fa fa-phone"></i>
            <span className="ms-2">
              {t("general_patient_phone")}: {doctorRequest.phone}
            </span>
          </div>
          <div className="mt-2">
            <i class="fa fa-map-marker"></i>
            <span className="ms-2">
              {t("general_patient_address")}: {doctorRequest.address}
            </span>
          </div>
          <div className="mt-2">
            <i class="fa fa-commenting-o"></i>
            <span className="ms-2">
              {t("general_patient_message")}: {doctorRequest.message}
            </span>
          </div>

          {doctorRequest.language.map((lang) => (
            <div className="mt-2">
              <i class="fa fa-language"></i>
              <span className="ms-2">
                {t("general_patient_language")}: {lang}
              </span>
            </div>
          ))}

          <div className="mt-2">
            <i class="fa fa-clock-o"></i>
            {/* <span className="ms-2">
              Date of the visit: {day}/{month}/{year}
            </span> */}
            <span className="ms-2">
              {t("general_visit_hour")}: {hours}:{minutes}
            </span>
          </div>
          <div className="mt-2">
            <i class="fa fa-calendar"></i>
            {/* <span className="ms-2">
              Date of the visit: {day}/{month}/{year}
            </span> */}
            <span className="ms-2">
              {t("general_visit_date")}: {doctorRequest.dataPrenotazione}
            </span>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
