import React, { useContext } from "react";
import { Route } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import PageSpotRequest from "../modules/spot-request/PageSpotRequest";
import PageUserChat from "../pages/users/chat/PageUserChat";
import PageUserConversations from "../pages/users/chat/PageUserConversations";
import PageUserDashboard from "../pages/users/dashboard/PageUserDashboard";
import PageEditUserProfile from "../pages/users/editProfile/PageEditUserProfile";
import PageUserHistory from "../pages/users/history/PageUserHistory";
import PageUserAppointment from "../pages/users/manageAppointment/PageUserAppointment";
import PageAppointment from "../pages/users/manageAppointment/PageUserManageAppointment";
import PageUserManageRequest from "../pages/users/manageRequest/PageUserManageRequest";
import PageUserPayment from "../pages/users/payment/PageUserPayment";
import PageUserProfile from "../pages/users/profile/PageUserProfile";
import PageUserVisits from "../pages/users/visits/PageUserVisits";

const UserRoutes = (props) => {
  const { user } = useContext(UserContext);

  return (
    <>
      <Route
        exact
        path="/"
        render={() => <PageUserDashboard {...user}></PageUserDashboard>}
      />
      <Route
        exact
        path="/account"
        render={() => <PageUserProfile></PageUserProfile>}
      />
      <Route
        exact
        path="/account-edit"
        render={() => <PageEditUserProfile></PageEditUserProfile>}
      />
      <Route
        exact
        path="/history"
        render={() => <PageUserHistory></PageUserHistory>}
      />
      <Route
        exact
        path="/appointment"
        render={() => <PageUserAppointment></PageUserAppointment>}
      />
      <Route
        exact
        path="/visits"
        render={() => <PageUserVisits></PageUserVisits>}
      />

      <Route
        exact
        path="/new-request"
        render={() => <PageSpotRequest></PageSpotRequest>}
      />
      <Route
        exact
        path="/manage-request/:requestID"
        render={() => <PageUserManageRequest></PageUserManageRequest>}
      />
      <Route
        exact
        path="/manage-appointment/:requestID"
        render={() => <PageAppointment></PageAppointment>}
      />
      <Route
        exact
        path="/checkout/:proposalID"
        render={() => <PageUserPayment></PageUserPayment>}
      />
      <Route
        exact
        path="/chat/:practitionerID"
        render={() => <PageUserChat></PageUserChat>}
      />
      <Route
        exact
        path="/conversations"
        render={() => <PageUserConversations></PageUserConversations>}
      />
    </>
  );
};

export default UserRoutes;
