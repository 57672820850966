import axios from "axios";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
// una lista di tutti i commenti

export function useStripe() {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const { t } = useTranslation();

  const sendToStripePortal = async (stripe_id) => {
    if (!stripe_id) {
      return console.error("Fornire uno stripe ID");
    }
    var config = {
      method: "post",
      url: baseURL + "/api/stripe/create-portal-link",
      headers: {
        "Content-Type": "application/json",
      },
      data: { stripe_id },
    };

    return axios(config);
  };

  /**
   * Crea un intenzione di pagare
   * @param {*} proposalID
   * @param {*} paymentMethod
   * @returns success
   */
  const createPaymentIntent = async ({ proposalID, paymentMethod }) => {
    var config = {
      method: "post",
      url: baseURL + "/api/stripe/create-payment-intent",
      data: { proposal_id: proposalID, payment_method: paymentMethod },
    };

    return axios(config);
  };

  /**
   * Crea un intenzione di pagare
   * @param {*} proposalID
   * @param {*} paymentMethod
   * @returns success
   */

  const cancelPaymentIntent = async ({ paymentIntentID, proposal_id }) => {
    var config = {
      method: "post",
      url: baseURL + "/api/stripe/cancel-payment-intent",
      data: { payment_intent_id: paymentIntentID, proposal_id: proposal_id },
    };

    return axios(config);
  };

  /**
   * Conferma un intenzione di pagare
   * @param {*} paymentIntentID
   * @returns success
   */
  const confirmPaymentIntent = async ({ paymentIntentID }) => {
    var config = {
      method: "post",
      url: baseURL + "/api/stripe/confirm-payment-intent",
      data: { payment_intent_id: paymentIntentID },
    };

    return axios(config);
  };
  /**
   * Cattura pagamento
   * @param {*} paymentIntentID
   * @returns success
   */
  const capturePaymentIntent = async ({ paymentIntentID }) => {
    var config = {
      method: "post",
      url: baseURL + "/api/stripe/capture-payment-intent",
      data: { payment_intent_id: paymentIntentID },
    };

    return axios(config);
  };

  return {
    sendToStripePortal,
    createPaymentIntent,
    confirmPaymentIntent,
    capturePaymentIntent,
    cancelPaymentIntent,
  };
}
