import React, { useContext, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { parsePhoneNumber } from "react-phone-number-input";
import { toast } from "react-toastify";
import { DoctorRequestContext } from "../../../../context/DoctorRequestContext";
import { UserContext } from "../../../../context/UserContext";
import { useAuthentication } from "../../../../hooks/useAuthentication";
import StepPersonalInfoProfessionist from "./steps/StepPersonalInfoProfessionist";
import StepProfessionalInfoProfessionist from "./steps/StepProfessionalInfoProfessionist";
import StepOTPProfessionistReg from "./steps/StepOTPProfessionistReg";
import StepCredentialsProfessionist from "./steps/StepCredentialsProfessionist";
import { useDoctor } from "../../../../hooks/useDoctor";
import { CompanyContext } from "../../../../context/CompanyContext";
import { useHistory } from "react-router-dom";

export default function StepperSignupProfessionist() {
  const [formData, setFormData] = useState({
    nome: null,
    cognome: null,
    indirizzo: null,
    selectedPlace: null,
    professione: null,
    piva: null,
    codiceFiscale: null,
    telefono: null,
    termsCondition: null,
    privacyPolicy: null,
    email: null,
    password: null,
    passwordConfirm: null,
    otp: null,
  });
  const { user, setUser, setLoggedIn, setToken, setUserType } =
    useContext(UserContext);

  const { company } = useContext(CompanyContext);
  const history = useHistory();

  const auth = useAuthentication(setUser, setLoggedIn, setToken, setUserType);
  const { doctorRequest, setDoctorRequest } = useContext(DoctorRequestContext);
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const { signUpDoctor } = useDoctor();

  const handleSubmit = () => {
    console.log("handleSubmit formData,", formData);
    //TODO salva anche gli altri dati del professionsita
    if (!formData.nome) {
      toast.error(t("error_define_name"));
      return;
    }
    if (!formData.otp) {
      toast.error(t("error_define_otp"));
      return;
    }
    if (!formData.cognome) {
      toast.error(t("error_define_surname"));
      return;
    }
    if (!formData.email) {
      toast.error("error_define_email");
      return;
    }
    if (!formData.password) {
      toast.error(t("error_define_password"));
      return;
    }
    const phoneNumber = parsePhoneNumber(formData.telefono);
    setLoading(true);

    auth
      .verifyOtp(
        phoneNumber.countryCallingCode,
        phoneNumber.nationalNumber,
        formData?.otp,
        formData?.email
      )
      .then((response) => {
        if (response.status === 200) {
          if (response.data.response.status === "approved") {
            signUpDoctor({
              name: formData.nome,
              surname: formData.cognome,
              telefono: formData?.telefono,
              user_email: formData.email,
              password: formData?.password,
              professione: formData?.professione,
              privacy: formData?.privacyPolicy,
              termsCondition: formData?.termsCondition,
              indirizzo: formData?.indirizzo?.indirizzo,
              companyID: company?._id,
              practitioner_role: "admin",
              codiceFiscale: formData?.codiceFiscale,
              piva: formData?.piva,
              citta: formData?.indirizzo?.citta,
              cittaShort: formData?.indirizzo?.cittaShort,
              codicePostale: formData?.indirizzo?.codicePostale,
              paese: formData?.indirizzo?.paese,
              paeseShort: formData?.indirizzo?.paeseShort,
              latitude: formData?.indirizzo?.latitude,
              longitude: formData?.indirizzo?.longitude,
            })
              .then(() => {
                toast.success(t("general_practitioner_created"));
                history.push("/");
              })
              .catch((e) => {
                toast.error(t("error_practitioner_registration"));
              })
              .finally(() => setLoading(false));
          } else {
            toast.error(t("error_otp_not_approved"));
          }
        }
      })
      .catch((error) => {
        toast.error(t("error_otp_not_approved"));
        setLoading(false);
      });

    return;
  };

  const handleBack = () => {
    setStep((prev) => prev - 1);
  };
  const handleNext = () => {
    setStep((prev) => prev + 1);
  };

  const handleChange = (value, target) => {
    let newState = Object.assign({}, formData);
    newState[target] = value;
    setFormData(newState);
  };

  return (
    <Row>
      <Col>
        <h4>{t("professionist_subscription_form")}</h4>
        <StepPersonalInfoProfessionist
          show={step == 1}
          formData={formData}
          onSubmit={handleNext}
          onChange={handleChange}
          disableNext={
            !formData?.nome || !formData?.cognome || !formData?.indirizzo
          }
        ></StepPersonalInfoProfessionist>

        <StepProfessionalInfoProfessionist
          show={step == 2}
          formData={formData}
          onSubmit={handleNext}
          onGoBack={handleBack}
          onChange={handleChange}
          disableNext={
            !formData.professione || !formData.piva || !formData?.codiceFiscale
          }
        ></StepProfessionalInfoProfessionist>
        <StepOTPProfessionistReg
          show={step == 3}
          formData={formData}
          onSubmit={handleNext}
          onGoBack={handleBack}
          onChange={handleChange}
          disableNext={!formData.otp}
        ></StepOTPProfessionistReg>

        <StepCredentialsProfessionist
          show={step == 4}
          formData={formData}
          onGoBack={handleBack}
          onChange={handleChange}
          onSubmit={handleSubmit}
          loading={loading}
        ></StepCredentialsProfessionist>
      </Col>
    </Row>
  );
}
