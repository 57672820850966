import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";

import { TextField } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useAuthentication } from "../../../../hooks/useAuthentication";
import { isValidEmail } from "../../../../utils/Utils";
import CaButton from "../../../../components/CaButton";
import { useTranslation } from "react-i18next";
import CaInput from "../../../../components/CaInput";

export default function StepResetPwd() {
  const [email, setEmail] = useState("");
  const { resetPasswordEmail } = useAuthentication();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const { t } = useTranslation();
  let history = useHistory();
  const handleSendResetEmail = () => {
    if (!isValidEmail(email)) return toast.error(t("email_is_not_valid"));
    setLoading(true);
    if (email) {
      //TODO send email to reset pwd
      resetPasswordEmail(email)
        .then((res) => {
          setLoading(false);
          if (res.status === 200) {
            setSuccess(true);
          }
          if (res.status !== 200) {
            toast.error(t("error_while_sending_email"));
          }

          console.log(res);
        })
        .catch((err) => {
          setLoading(false);
          toast.error(t("error_while_sending_email"));
        });
    } else {
      toast.warn("Inserisci un email valida");
    }
  };
  return (
    <Container>
      <Row>
        {success && <Col>{t("reset_email_send_message_success")} ✌</Col>}
        {!success && (
          <Col>
            <p>{t("reset_email_send_message_explainer")}</p>
            <CaInput
              className="single-input"
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e)}
              label={t("general_email")}
              type="email"
              required
            ></CaInput>
            <div className="center mt-4">
              <CaButton
                variant="link"
                onClick={() => history.push("/login")}
                text={t("general_go_login")}
                className={"me-4"}
              ></CaButton>
              <CaButton
                variant="primary"
                onClick={handleSendResetEmail}
                disabled={!email}
                isLoading={loading}
                text={t("general_send_reset_link")}
              ></CaButton>
            </div>
          </Col>
        )}
      </Row>
    </Container>
  );
}
